// import { Box, Divider, FormControl, IconButton, Menu, MenuItem, Select, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { PROJECT_PHASE_STATUS, PROJECT_TASK_PRIORITY, TASK_STATUS_LIST, TASK_TYPE_LIST } from '../../../../constants/default-values'
import { convertToBase64, formatDuration, formatTimeAgo, generateUniqueId, getPriorotyIcon, getTaskStatus, getTaskTypeIcon } from '../../../../utils'
import Button from '../../../../components/Button'

import {
    Box,
    Divider,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Stack,
    styled,
    Tab,
    Tabs,
    Tooltip,
    tooltipClasses,
    Typography,
} from "@mui/material";
import axiosInstance from "../../../../axios";
import API from "../../../../axios/api";
import { getInputId, sortArrayByKey, titleCase } from "../../../../utils";
import { useAlert } from "../../../../hook/useAlert";
// import { getKeyByValue, PROJECT_STATUS, PROJECT_STATUS_LIST } from "../../../../constants/default-values";
// import useQuery from "../../../../hook/useQuery";
import BackgroundLetterAvatars from "../../../../components/BackgroundLetterAvatars";
import CircularLoader from "../../../../components/CircularLoader";
import CloseIcon from '@mui/icons-material/Close';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ModeStandbyOutlinedIcon from '@mui/icons-material/ModeStandbyOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import TextEditor from "../../../../components/TextEditor";
import SubjectIcon from '@mui/icons-material/Subject';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Input from '../../../../components/Input';
import TimeInput from '../../../../components/CustomManualTimePicker';
import CustomFileDropZone from '../../../../components/CustomFileDropZone';
import TaskComment from './taskComment';
import TaskActivity from './taskActivity';
import TaskWorklog from './taskWorklog';
import { saveAs } from 'file-saver';
import AttachmentLists from './attachmentLists';
import ICONS from '../../../../constants/icons';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#444444',
        color: '#ffffff',
        maxWidth: 'inherit',
        padding: 16,
    },
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const CreateTask = ({ isEditTask, handleCloseTaskDrawer,
    taskData, setTaskData, projectPhases, initialTaskData,
    taskErrors, setTaskErrors, currentUser, handleSubmitTaskData, isLoading,
    handleSubmitUpdateTaskData, setOptionalTaskData, activeMembersData }) => {
    const showAlert = useAlert();
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState({
        userLoading: true,
        taskDataLoading: false
    })
    const [usersList, setUsersList] = useState([]);
    const [isTitleExpanded, setIsTitleExpanded] = useState(false);

    let maxTextLenth = 100;
    const maxFileSize = 5 * 1000000;
    const maxTotalSize = 10 * 1000000;
    const maxFiles = 5;

    useEffect(() => {
        allUserDataList();
        if (isEditTask)
            getUserTaskData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const allUserDataList = async () => {
        try {
            setLoading(prev => ({ ...prev, userLoading: true }))
            const response = await axiosInstance.get(API.organisationUserSetting(currentUser?.organization_id));
            let sortedData = sortArrayByKey(response?.data?.data, 'asc', 'name');
            if (response?.status === 200) {
                setUsersList(sortedData?.map((value) => ({
                    id: value?.id,
                    name: value?.name,
                    email: value?.email,
                    profile_img: value?.profile_img,
                    organization_id: value?.organization_id,
                })))
            }
            setLoading(prev => ({ ...prev, userLoading: false }))
        } catch (error) {
            console.error(error);
            setLoading(prev => ({ ...prev, userLoading: false }))
        }
    }

    const getUserTaskData = async () => {
        try {
            setLoading(prev => ({ ...prev, taskDataLoading: true }))

            const response = await axiosInstance.get(API.getTaskDetailsById(taskData?.id));
            if (response?.status === 200) {
                let taskResponse = response?.data?.data?.[0];
                let attachmentFiles = [];
                if (taskResponse?.attachments?.length) {
                    const convertToFile = taskResponse?.attachments?.filter(value => !!value)?.map((url) => ({
                        name: url?.substring(url.lastIndexOf("/") + 1),
                        preview: url,
                        unique_id: generateUniqueId(),
                        isExist: true
                    }));
                    attachmentFiles = convertToFile;
                }
                setTaskData({
                    ...taskResponse,
                    attachments: attachmentFiles,
                    assignee: taskResponse?.User?.id,
                    estimation: taskResponse?.estimation ? formatDuration(taskResponse?.estimation ?? 0) : "",
                    originalEstimation: taskResponse?.estimation ?? 0,
                    spent: taskResponse?.spent ?? 0,
                    attachment_references: taskResponse?.attachments,
                })
                setOptionalTaskData({
                    ...taskResponse,
                    attachments: attachmentFiles,
                    assignee: taskResponse?.User?.id,
                    estimation: taskResponse?.estimation ? formatDuration(taskResponse?.estimation ?? 0) : "",
                    originalEstimation: taskResponse?.estimation ?? 0,
                    spent: taskResponse?.spent ?? 0,
                    attachment_references: taskResponse?.attachments,
                })
            }
            setLoading(prev => ({ ...prev, taskDataLoading: false }))
        } catch (error) {
            console.error(error);
            setLoading(prev => ({ ...prev, taskDataLoading: false }))
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getFilterdProjectPhase = () => {
        return projectPhases?.filter(phaseData => [PROJECT_PHASE_STATUS['In-Progress'], PROJECT_PHASE_STATUS['Not started']]?.includes(phaseData?.status))
    }

    const handleChangeTaskData = (e) => {
        let { name, value } = e.target;
        setTaskData(prev => ({
            ...prev,
            [name]: value
        }))
        setTaskErrors((prev) => ({
            ...prev,
            [name]: value?.toString()?.length ? null : "Required"
        }))
    }

    const handleChangeDescription = (value) => {
        setTaskData(prev => ({
            ...prev,
            description: value,
        }))
    }

    const handleTimeChange = (newTime) => {
        setTaskData(prev => ({
            ...prev,
            estimation: newTime
        }))
    };

    const fileSizeValidator = (file, type = "file") => {
        const allowedFormats = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
        if (type === "validator") {
            if (!allowedFormats.includes(file.type)) {
                showAlert("Invalid file format. Allowed formats are JPEG, JPG, PNG, and PDF.", "error");
                return {
                    code: "invalid-format",
                    message: "Invalid file format.",
                };
            }
        } else {
            const allowedFormats = [".jpeg", ".jpg", ".png", ".pdf"];
            const isValidFormat = allowedFormats.some(format => file.name?.toLowerCase()?.endsWith(format));
            if (!isValidFormat) {
                showAlert("Invalid file format. Allowed formats are JPEG, JPG, PNG, and PDF.", "error");
                return {
                    code: "invalid-format",
                    message: "Invalid file format.",
                };
            }
            if (file?.size > maxFileSize) {
                showAlert("File size exceeds 5MB limit.", "error");
                return {
                    code: "size-too-large",
                    message: "File size exceeds 5MB limit.",
                };
            }
        }
        return null;
    };

    const totalSizeValidator = (newFiles) => {
        const newTotalSize = newFiles?.reduce((acc, file) => acc + file.size, 0);
        return newTotalSize > maxTotalSize;
    };

    const handleChangeImage = async (event) => {
        const allfiles = event.target.files;
        const files = Array.from(allfiles);
        if (files) {
            const invalidFiles = files
                .map(file => fileSizeValidator(file))
                .filter(error => error !== null);

            if (invalidFiles.length > 0) return;

            if (totalSizeValidator(files)) {
                showAlert("Total size exceeds the limit. Max 10MB allowed.", "error");
                return;
            }

            const updatedFiles = [];

            for (const file of files) {
                let base64 = null;
                if (file instanceof Blob || file instanceof File) {
                    base64 = await convertToBase64(file);
                } else {
                    base64 = file?.base64;
                }
                let fileObject = {
                    name: file?.name,
                    size: file?.size,
                    type: file?.type,
                    preview: URL.createObjectURL(file),
                    unique_id: generateUniqueId(),
                    base64,
                };
                updatedFiles.push(fileObject);
            }
            const limitedFiles = [...updatedFiles]?.filter((item) => item && (item !== null || item !== "null"))?.slice(0, maxFiles);
            setTaskData(prev => ({ ...prev, attachments: [...prev?.attachments, ...limitedFiles]?.slice(0, maxFiles) }));
        }
    };

    const isValidateTaskData = () => {
        let error = {}
        if (taskData?.title?.length) {
            error['title'] = ""
        } else {
            error['title'] = "Required"
        }
        if (taskData?.status?.length) {
            error['status'] = ""
        } else {
            error['status'] = "Required"
        }
        if (taskData?.priority?.length) {
            error['priority'] = ""
        } else {
            error['priority'] = "Required"
        }
        if (taskData?.project_phase_id?.toString()?.length) {
            error['project_phase_id'] = ""
        } else {
            error['project_phase_id'] = "Required"
        }
        if (taskData?.type?.length) {
            error['type'] = ""
        } else {
            error['type'] = "Required"
        }
        if (taskData?.assignee?.toString()?.length) {
            error['assignee'] = ""
        } else {
            error['assignee'] = "Required"
        }
        setTaskErrors(error)
        return Object.values(error)?.some(value => value?.length)
    }

    const handleSubmitData = () => {
        if (!isValidateTaskData()) {
            if (isEditTask) {
                handleSubmitUpdateTaskData();
            } else {
                handleSubmitTaskData()
            }
        }
    }

    const toggleTitleExpansion = () => {
        // setIsExpanded(!isExpanded);
        setIsTitleExpanded(!isTitleExpanded)
    };

    const getHoursWithPercentage = () => {
        let spentHours = taskData?.spent ?? 0;
        let estimationHours = taskData?.originalEstimation ?? 0;
        // let spentHours = 540;
        // let estimationHours = 600;
        let overSpentHours = estimationHours > 0 ? (spentHours - estimationHours) : 0;
        let remainingHours = estimationHours - spentHours;

        let totalHours = overSpentHours < 0 ? estimationHours : spentHours

        let spentHoursPercentage = (spentHours / totalHours) * 100;
        let overSpentHoursPercentage = overSpentHours > 0 ? ((overSpentHours / totalHours) * 100) : 0;
        let remainingHoursPercentage = (remainingHours / totalHours) * 100;
        let estimationPercentageHours = (estimationHours / totalHours) * 100;

        return {
            spentHours: spentHours,
            estimationHours: estimationHours,
            overSpentHours: overSpentHours,
            remainingHours: remainingHours,
            spentHoursPercentage: spentHoursPercentage,
            overSpentHoursPercentage: overSpentHoursPercentage,
            remainingHoursPercentage: remainingHoursPercentage,
            estimationPercentageHours: estimationPercentageHours,
        }
    }

    const downloadSingleImage = async (url, fileName) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            saveAs(blob, fileName);
        } catch (error) {
            console.error('Error downloading image:', error);
        }
    };

    const downloadAllFiles = () => {
        taskData?.attachments.forEach((file) => {
            if (file.isExist) {
                if (file.preview.endsWith('.png') || file.preview.endsWith('.jpg') || file.preview.endsWith('.jpeg')) {
                    downloadSingleImage(file.preview, file.name);
                } else {
                    saveAs(file.preview, file.name);
                }
            }
        });
    };

    return (
        <Box width={590} display={"flex"} flexDirection={"column"} flex={1} height={(loading?.userLoading || loading?.taskDataLoading) ? "100vh" : "auto"} justifyContent={(loading?.userLoading || loading?.taskDataLoading) ? "center" : "flex-start"} alignItems={loading?.userLoading ? "center" : "stretch"}>
            {
                (loading?.userLoading || loading?.taskDataLoading) ? <CircularLoader height={'100%'} minHeight={'500px'} /> :
                    <>
                        <Box p={2}>
                            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                <Box display={"flex"} alignItems={"center"} gap={1}>
                                    {!isEditTask ? <Typography variant="body1" color={"dark.800"} fontSize={16} fontWeight={600} lineHeight={"25px"} letterSpacing={"0.15px"}>New Task</Typography> : null}

                                    {/* After Created Task */}
                                    {
                                        isEditTask ?
                                            <>
                                                <Box minWidth={24} width={24} height={24} display={"flex"} alignItems={"center"} justifyContent={"center"} border={"1px solid rgba(63, 92, 118, 0.2)"} borderRadius={"2px"}>
                                                    {/* <AddRoundedIcon sx={{ fontSize: 16, color: "#3F5C76" }} /> */}
                                                    {getTaskTypeIcon(taskData?.type)}
                                                </Box>
                                                <Typography variant="body1" color={"text"} fontSize={14} fontWeight={700} lineHeight={"17px"}>
                                                    {taskData?.Project?.short_code}-{taskData?.task_no ?? 1}
                                                </Typography>
                                            </> : null
                                    }
                                    {/* After Created Task */}
                                </Box>
                                <IconButton
                                    aria-label="Close"
                                    size="small"
                                    onClick={() => handleCloseTaskDrawer()}
                                    sx={{ width: 24, minWidth: 24, height: 24, padding: '2px' }}
                                >
                                    <CloseIcon fontSize="medium" />
                                </IconButton>
                            </Box>
                            {isEditTask ? <Box p={1} position={"relative"}>
                                <Typography variant="h2" color={"dark.800"} fontSize={20} fontWeight={600} lineHeight={"30px"}>
                                    {(isTitleExpanded || taskData?.title?.length <= maxTextLenth) ? taskData?.title : `${taskData?.title?.slice(0, maxTextLenth)}...`}
                                </Typography>
                                {taskData?.title?.length > maxTextLenth ?
                                    <Button
                                        variant='text'
                                        color='primary'
                                        size="small"
                                        onClick={() => toggleTitleExpansion()}
                                        sx={{
                                            textTransform: 'none', p: 0,
                                            '&:hover': {
                                                background: 'transparent',
                                                opacity: 0.75,
                                            },
                                        }}
                                    >
                                        {isTitleExpanded ? "See Less" : "See More"}
                                    </Button> : null}
                            </Box> : null}
                        </Box>
                        <Divider />
                        <Box flex={1} overflow={"auto"}>
                            <Box p={2} pb={0}>
                                {!isEditTask ? <Input
                                    id="title"
                                    label="Task Title"
                                    variant="standard"
                                    type="text"
                                    name={"title"}
                                    fullWidth
                                    required={false}
                                    onChange={(e) => handleChangeTaskData(e)}
                                    value={taskData?.title}
                                    error={taskErrors?.title?.length && Boolean(taskErrors.title)}
                                    helperText={taskErrors?.title && taskErrors?.title}
                                    sx={{ mb: 3 }}
                                /> : null}

                                <Box mb={3} px={1} width={414}>
                                    <Stack spacing={1}>
                                        <Box display={"flex"} alignItems={"flex-start"} gap={2.5}>
                                            <Box minWidth={124} maxWidth={124} width={124} display={"flex"} alignItems={"center"} gap={"6px"} pt={1}>
                                                <TaskAltOutlinedIcon fontSize="18px" />
                                                <Typography color={"text"} fontSize={14} fontWeight={400} lineHeight={"21px"}>Type</Typography>
                                            </Box>
                                            <Box flex={1} minHeight={36}
                                                sx={{
                                                    '.MuiInputBase-formControl': {
                                                        '&:before': {
                                                            opacity: 0,
                                                        },
                                                        '.MuiSelect-icon': {
                                                            opacity: 0,
                                                        },
                                                        '&:hover': {
                                                            '.MuiSelect-icon': {
                                                                opacity: 1,
                                                            },
                                                        }
                                                    },
                                                    '.Mui-focused': {
                                                        '.MuiSelect-icon': {
                                                            opacity: 1,
                                                        },
                                                    },
                                                    ".MuiSelect-select": {
                                                        borderRadius: '6px',
                                                        padding: '6px 28px 6px 6px',
                                                        '&:hover': {
                                                            bgcolor: '#F4F4F4',
                                                        },
                                                        'p': {
                                                            color: 'rgba(0, 0, 0, 0.87)',
                                                            fontSize: 14,
                                                            lineHeight: '24px',
                                                            fontWeight: 600,
                                                            letterSpacing: '0.15px',
                                                        },
                                                    },
                                                }}
                                            >
                                                <FormControl size="small" variant="standard" fullWidth>
                                                    <Select
                                                        labelId="select-user"
                                                        id="select-user"
                                                        label="Task"
                                                        name='type'
                                                        value={taskData?.type || ''}
                                                        onChange={(e) => handleChangeTaskData(e)}
                                                        inputProps={{ id: getInputId("type", 0) }}
                                                    >
                                                        {Object.keys(TASK_TYPE_LIST)?.map((key, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={key}
                                                                sx={{
                                                                    padding: 1,
                                                                }}
                                                            >
                                                                <Box display={'flex'}>
                                                                    <Box bgcolor={"white"} display={"flex"} alignItems={"center"} justifyContent={"center"} color={"secondary.main"} minWidth={24} maxWidth={24} width={24} height={24} mr={1} borderRadius={"2px"} border={"1px solid rgba(63, 92, 118, 0.2)"}
                                                                        sx={{ 'svg': { width: 20, height: 20, minWidth: 20 } }}
                                                                    >
                                                                        {getTaskTypeIcon(key)}
                                                                    </Box>
                                                                    <Typography color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.15px"} maxWidth={"100%"} overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"} flex={1}>
                                                                        {TASK_TYPE_LIST[key]}
                                                                    </Typography>
                                                                </Box>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {!!taskErrors?.type?.length && (
                                                    <Typography fontSize="12px" sx={{ color: "red.main", marginTop: 0.5 }}>
                                                        {taskErrors?.type}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                        <Box display={"flex"} alignItems={"flex-start"} gap={2.5}>
                                            <Box minWidth={124} maxWidth={124} width={124} display={"flex"} alignItems={"center"} gap={"6px"} pt={1}>
                                                <ModeStandbyOutlinedIcon fontSize="small" />
                                                <Typography color={"text"} fontSize={14} fontWeight={400} lineHeight={"21px"}>Status</Typography>
                                            </Box>
                                            <Box flex={1} minHeight={36}
                                                sx={{
                                                    '.MuiInputBase-formControl': {
                                                        '&:before': {
                                                            opacity: 0,
                                                        },
                                                        '.MuiSelect-icon': {
                                                            opacity: taskData?.status?.length ? 0 : 1,
                                                        },
                                                        '&:hover': {
                                                            '.MuiSelect-icon': {
                                                                opacity: 1,
                                                            },
                                                        }
                                                    },
                                                    '.Mui-focused': {
                                                        '.MuiSelect-icon': {
                                                            opacity: 1,
                                                        },
                                                    },
                                                    ".MuiSelect-select": {
                                                        borderRadius: '6px',
                                                        padding: '4px 28px 4px 6px',
                                                        '&:hover': {
                                                            bgcolor: '#F4F4F4',
                                                        },
                                                        'p': {
                                                            color: 'rgba(0, 0, 0, 0.87)',
                                                            fontSize: 14,
                                                            lineHeight: '24px',
                                                            fontWeight: taskData?.status?.length ? 600 : 400,
                                                            letterSpacing: '0.15px',
                                                        },
                                                    },
                                                }}
                                            >
                                                <FormControl variant="standard" fullWidth
                                                    sx={{
                                                        '.MuiChip-root': {
                                                            height: 'auto',
                                                            overflow: "hidden",
                                                        },
                                                        '.MuiChip-label': {
                                                            padding: '1px 12px',
                                                            background: '#ffffff',
                                                        },
                                                    }}
                                                >
                                                    <Select
                                                        labelId="status-label"
                                                        id="status"
                                                        name="status"
                                                        value={taskData?.status || ""}
                                                        onChange={(e) => handleChangeTaskData(e)}
                                                        inputProps={{ id: getInputId("status", 0) }}
                                                        displayEmpty
                                                        renderValue={(selected) => {
                                                            if (selected?.toString()?.length === 0) {
                                                                return <>Select</>;
                                                            }
                                                            return getTaskStatus(TASK_STATUS_LIST[selected], "chip")
                                                        }}
                                                    >
                                                        {Object.keys(TASK_STATUS_LIST)?.map((key, index) => (
                                                            <MenuItem sx={{
                                                                padding: 1,
                                                                '.MuiChip-root': {
                                                                    height: 'auto',
                                                                    overflow: "hidden",
                                                                },
                                                                '.MuiChip-label': {
                                                                    padding: '3px 12px',
                                                                    background: '#ffffff',
                                                                },
                                                            }}
                                                                key={index}
                                                                value={key}>
                                                                {getTaskStatus(TASK_STATUS_LIST[key], "chip")}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {!!taskErrors?.status?.length && (
                                                    <Typography fontSize="12px" sx={{ color: "red.main", marginTop: 0.5 }}>
                                                        {taskErrors?.status}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                        <Box display={"flex"} alignItems={"flex-start"} gap={2.5}>
                                            <Box minWidth={124} maxWidth={124} width={124} display={"flex"} alignItems={"center"} gap={"6px"} pt={1}>
                                                <Box width={18} height={18} minWidth={18} sx={{ 'svg': { width: 18, height: 18 } }}>
                                                    {ICONS.PriorityIcon}
                                                </Box>
                                                <Typography color={"text"} fontSize={14} fontWeight={400} lineHeight={"21px"}>Priority</Typography>
                                            </Box>
                                            <Box flex={1} minHeight={36}
                                                sx={{
                                                    '.MuiInputBase-formControl': {
                                                        '&:before': {
                                                            opacity: 0,
                                                        },
                                                        '.MuiSelect-icon': {
                                                            opacity: taskData?.priority?.length ? 0 : 1,
                                                        },
                                                        '&:hover': {
                                                            '.MuiSelect-icon': {
                                                                opacity: 1,
                                                            },
                                                        }
                                                    },
                                                    '.Mui-focused': {
                                                        '.MuiSelect-icon': {
                                                            opacity: 1,
                                                        },
                                                    },
                                                    ".MuiSelect-select": {
                                                        borderRadius: '6px',
                                                        padding: '6px 28px 6px 6px',
                                                        '&:hover': {
                                                            bgcolor: '#F4F4F4',
                                                        },
                                                        'p': {
                                                            color: 'rgba(0, 0, 0, 0.87)',
                                                            fontSize: 14,
                                                            lineHeight: '24px',
                                                            fontWeight: taskData?.priority?.length ? 600 : 400,
                                                            letterSpacing: '0.15px',
                                                        },
                                                    },
                                                }}
                                            >
                                                <FormControl size="small" variant="standard" fullWidth>
                                                    <Select
                                                        labelId="select-user"
                                                        id="select-user"
                                                        label="Task"
                                                        name='priority'
                                                        value={taskData?.priority || ''}
                                                        onChange={(e) => handleChangeTaskData(e)}
                                                        inputProps={{ id: getInputId("priority", 0) }}
                                                        displayEmpty
                                                        renderValue={(selected) => {
                                                            if (selected?.toString()?.length === 0) {
                                                                return <>Select</>;
                                                            }
                                                            return (
                                                                <Box display={'flex'}>
                                                                    <Box bgcolor={"white"} display={"flex"} alignItems={"center"} justifyContent={"center"} color={"secondary.main"} minWidth={24} maxWidth={24} width={24} height={24} mr={1} borderRadius={"2px"} border={"1px solid rgba(63, 92, 118, 0.2)"}>
                                                                        {getPriorotyIcon(selected)}
                                                                    </Box>
                                                                    <Typography color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.15px"} maxWidth={"100%"} overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"} flex={1}>
                                                                        {PROJECT_TASK_PRIORITY[selected]}
                                                                    </Typography>
                                                                </Box>
                                                            );
                                                        }}
                                                    >
                                                        {Object.keys(PROJECT_TASK_PRIORITY)?.map((key, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={key}
                                                                sx={{
                                                                    padding: 1,
                                                                }}
                                                            >
                                                                <Box display={'flex'}>
                                                                    <Box bgcolor={"white"} display={"flex"} alignItems={"center"} justifyContent={"center"} color={"secondary.main"} minWidth={24} maxWidth={24} width={24} height={24} mr={1} borderRadius={"2px"} border={"1px solid rgba(63, 92, 118, 0.2)"}>
                                                                        {getPriorotyIcon(key)}
                                                                    </Box>
                                                                    <Typography color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.15px"} maxWidth={"100%"} overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"} flex={1}>
                                                                        {PROJECT_TASK_PRIORITY[key]}
                                                                    </Typography>
                                                                </Box>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {!!taskErrors?.priority?.length && (
                                                    <Typography fontSize="12px" sx={{ color: "red.main", marginTop: 0.5 }}>
                                                        {taskErrors?.priority}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                        <Box display={"flex"} alignItems={"flex-start"} gap={2.5}>
                                            <Box minWidth={124} maxWidth={124} width={124} display={"flex"} alignItems={"center"} gap={"6px"} pt={1}>
                                                <Box width={18} height={18} minWidth={18} sx={{ 'svg': { width: 18, height: 18 } }}>
                                                    {ICONS.PhaseIcon}
                                                </Box>
                                                <Typography color={"text"} fontSize={14} fontWeight={400} lineHeight={"21px"}>Phase</Typography>
                                            </Box>
                                            <Box flex={1} minHeight={36}
                                                sx={{
                                                    '.MuiInputBase-formControl': {
                                                        '&:before': {
                                                            opacity: 0,
                                                        },
                                                        '.MuiSelect-icon': {
                                                            opacity: taskData?.project_phase_id?.toString()?.length ? 0 : 1,
                                                        },
                                                        '&:hover': {
                                                            '.MuiSelect-icon': {
                                                                opacity: 1,
                                                            },
                                                        }
                                                    },
                                                    '.Mui-focused': {
                                                        '.MuiSelect-icon': {
                                                            opacity: 1,
                                                        },
                                                    },
                                                    ".MuiSelect-select": {
                                                        borderRadius: '6px',
                                                        padding: '6px 28px 6px 6px',
                                                        '&:hover': {
                                                            bgcolor: '#F4F4F4',
                                                        },
                                                        'p': {
                                                            color: 'rgba(0, 0, 0, 0.87)',
                                                            fontSize: 14,
                                                            lineHeight: '24px',
                                                            fontWeight: taskData?.project_phase_id?.toString()?.length ? 600 : 400,
                                                            letterSpacing: '0.15px',
                                                        },
                                                    },
                                                }}
                                            >
                                                <FormControl variant="standard" fullWidth>
                                                    <Select
                                                        labelId="phase-label"
                                                        id="project_phase_id"
                                                        name="project_phase_id"
                                                        value={taskData?.project_phase_id || ''}
                                                        onChange={(e) => handleChangeTaskData(e)}
                                                        displayEmpty
                                                        renderValue={(selected) => {
                                                            if (selected?.toString()?.length === 0) {
                                                                return <>Select</>;
                                                            }
                                                            return titleCase(projectPhases?.find(phaseData => phaseData?.id === selected)?.title)
                                                        }}
                                                    >
                                                        {getFilterdProjectPhase()?.map((phaseData, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={phaseData?.id}
                                                                sx={{ padding: 1, fontSize: 14, fontWeight: 500, lineHeight: "24px", letterSpacing: '0.15px', color: 'rgba(0,0,0,0.87)' }}
                                                            >
                                                                {titleCase(phaseData?.title)}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {!!taskErrors?.project_phase_id?.length && (
                                                    <Typography fontSize="12px" sx={{ color: "red.main", marginTop: 0.5 }}>
                                                        {taskErrors?.project_phase_id}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                        <Box display={"flex"} alignItems={"flex-start"} gap={2.5}>
                                            <Box minWidth={124} maxWidth={124} width={124} display={"flex"} alignItems={"center"} gap={"6px"} pt={1}>
                                                <PeopleAltOutlinedIcon fontSize="small" />
                                                <Typography color={"text"} fontSize={14} fontWeight={400} lineHeight={"21px"}>Assignee</Typography>
                                            </Box>
                                            <Box flex={1} minHeight={36}
                                                sx={{
                                                    '.MuiInputBase-formControl': {
                                                        '&:before': {
                                                            opacity: 0,
                                                        },
                                                        '.MuiSelect-icon': {
                                                            opacity: taskData?.assignee?.toString()?.length ? 0 : 1,
                                                        },
                                                        '&:hover': {
                                                            '.MuiSelect-icon': {
                                                                opacity: 1,
                                                            },
                                                        }
                                                    },
                                                    '.Mui-focused': {
                                                        '.MuiSelect-icon': {
                                                            opacity: 1,
                                                        },
                                                    },
                                                    ".MuiSelect-select": {
                                                        borderRadius: '6px',
                                                        padding: '6px 28px 6px 6px',
                                                        '&:hover': {
                                                            bgcolor: '#F4F4F4',
                                                        },
                                                        'p': {
                                                            color: 'rgba(0, 0, 0, 0.87)',
                                                            fontSize: 14,
                                                            lineHeight: '24px',
                                                            fontWeight: taskData?.assignee?.toString()?.length ? 600 : 400,
                                                            letterSpacing: '0.15px',
                                                        },
                                                    },
                                                }}
                                            >
                                                <FormControl variant="standard" fullWidth>
                                                    <Select
                                                        labelId="assignee-label"
                                                        id="assignee"
                                                        name="assignee"
                                                        value={taskData?.assignee || ''}
                                                        onChange={(e) => handleChangeTaskData(e)}
                                                        displayEmpty
                                                        renderValue={(selected) => {
                                                            if (selected?.toString()?.length === 0) {
                                                                return <>Select</>;
                                                            }
                                                            return (
                                                                <Box display={"flex"} alignItems={"center"} width={"100%"}>
                                                                    <Box minWidth={25} width={25} height={25}>
                                                                        <BackgroundLetterAvatars
                                                                            user={activeMembersData?.find(userDetails => userDetails?.user_id?.toString() === selected?.toString())?.User}
                                                                            sx={{
                                                                                width: "25px",
                                                                                height: "25px",
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                    <Box flex={1} overflow={"hidden"} pl={1}>
                                                                        <Typography color={"dark.800"} fontSize={15} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.17px"} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"}>
                                                                            {titleCase(activeMembersData?.find(userDetails => userDetails?.user_id?.toString() === selected?.toString())?.User?.name)}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            )
                                                        }}
                                                    >
                                                        {activeMembersData?.map((userData, index) => (
                                                            <MenuItem sx={{ padding: 1, }} key={index} value={userData?.user_id}>
                                                                <Box display={"flex"} alignItems={"center"} width={"100%"}>
                                                                    <Box minWidth={32} width={32} height={32}>
                                                                        <BackgroundLetterAvatars
                                                                            user={userData?.User}
                                                                            sx={{
                                                                                width: "32px",
                                                                                height: "32px",
                                                                                // marginRight: "8px",
                                                                                // fontSize: "80%",
                                                                                // display: "flex",
                                                                                // alignItems: "center",
                                                                                // justifyContent: "center",
                                                                                // background: "white",
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                    <Box flex={1} overflow={"hidden"} pl={1}>
                                                                        <Typography color={"dark.800"} fontSize={15} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.17px"} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"}>
                                                                            {titleCase(userData?.User?.name)}
                                                                        </Typography>
                                                                        <Typography color={"rgba(63, 92, 118, 0.9)"} fontSize={12} fontWeight={400} lineHeight={"14px"} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"}>
                                                                            {userData?.User?.email}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {!!taskErrors?.assignee?.length && (
                                                    <Typography fontSize="12px" sx={{ color: "red.main", marginTop: 0.5 }}>
                                                        {taskErrors?.assignee}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                        <Box display={"flex"} alignItems={"flex-start"} gap={2.5}>
                                            <Box minWidth={124} maxWidth={124} width={124} display={"flex"} alignItems={"center"} gap={"6px"} pt={1}>
                                                <WatchLaterOutlinedIcon fontSize="small" />
                                                <Typography color={"text"} fontSize={14} fontWeight={400} lineHeight={"21px"}>Estimate</Typography>
                                            </Box>
                                            <Box flex={1} minHeight={36} sx={{ '> div': { margin: 0, }, 'div': { padding: 0 }, 'input': { height: 27, pl: '6px' }, '.error-box': { position: 'relative', } }}>
                                                {/* Edit Estimate Field */}
                                                <TimeInput value={taskData?.estimation} onChange={handleTimeChange} />
                                                {/* Edit Estimate Field */}
                                            </Box>
                                        </Box>
                                        {isEditTask ?
                                            <Box display={"flex"} alignItems={"flex-start"} gap={2.5}>
                                                <Box minWidth={124} maxWidth={124} width={124} display={"flex"} alignItems={"center"} gap={"6px"} pt={1}>
                                                    <TrackChangesIcon fontSize="small" />
                                                    <Typography color={"text"} fontSize={14} fontWeight={400} lineHeight={"21px"}>Time Tracking</Typography>
                                                </Box>
                                                <Box display={"flex"} alignItems={"center"} flex={1} minHeight={36}>
                                                    <Box flex={1} mr={"10px"}>
                                                        {getHoursWithPercentage()?.spentHours > 0 ? <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} mb={"5px"}>
                                                            <Typography color={"dark.800"} fontSize={14} fontWeight={600} lineHeight={"17px"} letterSpacing={"0.15px"}>{formatDuration(getHoursWithPercentage()?.spentHours)}</Typography>
                                                            {getHoursWithPercentage()?.remainingHours > 0 ? <Typography color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"17px"} letterSpacing={"0.15px"}>{formatDuration(getHoursWithPercentage()?.remainingHours)} Remaining</Typography>
                                                                :
                                                                getHoursWithPercentage()?.overSpentHours > 0 ?
                                                                    <Typography color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"17px"} letterSpacing={"0.15px"}>{formatDuration(getHoursWithPercentage()?.overSpentHours)} Over</Typography> : null}
                                                        </Box> : null}
                                                        <Box width={"100%"} bgcolor={"#F0F0F0"} height={3} display={"flex"} mt={0.5}>
                                                            {/* {getHoursWithPercentage()?.spentHours > 0 ?
                                                                <Box
                                                                    height={3}
                                                                    width={`${getHoursWithPercentage()?.overSpentHours > 0 ? getHoursWithPercentage()?.estimationPercentageHours : getHoursWithPercentage()?.spentHoursPercentage}%`}
                                                                    minWidth={`${getHoursWithPercentage()?.overSpentHours > 0 ? getHoursWithPercentage()?.estimationPercentageHours : getHoursWithPercentage()?.spentHoursPercentage}%`}
                                                                    bgcolor={"#047FE0"}></Box>
                                                                : null} */}
                                                            {/* {getHoursWithPercentage()?.manualHours > 0 ? <Box height={3} width={`${getHoursWithPercentage()?.manualHoursPercentage}%`} minWidth={`${getHoursWithPercentage()?.manualHoursPercentage}%`} bgcolor={"#F0891B"}></Box> : null} */}
                                                            {getHoursWithPercentage()?.spentHours > 0 ?
                                                                <Box
                                                                    height={3}
                                                                    width={`${getHoursWithPercentage()?.overSpentHours > 0 ? getHoursWithPercentage()?.estimationPercentageHours : getHoursWithPercentage()?.spentHoursPercentage}%`}
                                                                    minWidth={`${getHoursWithPercentage()?.overSpentHours > 0 ? getHoursWithPercentage()?.estimationPercentageHours : getHoursWithPercentage()?.spentHoursPercentage}%`}
                                                                    bgcolor={"#F0891B"}></Box>
                                                                : null}
                                                            {getHoursWithPercentage()?.manualHours > 0 ? <Box height={3} width={`${getHoursWithPercentage()?.manualHoursPercentage}%`} minWidth={`${getHoursWithPercentage()?.manualHoursPercentage}%`} bgcolor={"#047FE0"}></Box> : null}
                                                            {getHoursWithPercentage()?.remainingHours > 0 ? <Box height={3} width={`${getHoursWithPercentage()?.remainingHoursPercentage}%`} minWidth={`${getHoursWithPercentage()?.remainingHoursPercentage}%`} bgcolor={"#BDBDBD"}></Box> : null}
                                                            {getHoursWithPercentage()?.overSpentHours > 0 ? <Box height={3} width={`${getHoursWithPercentage()?.overSpentHoursPercentage}%`} minWidth={`${getHoursWithPercentage()?.overSpentHoursPercentage}%`} bgcolor={"#ffffff"}>
                                                                <Box ml={0.5} height={3} bgcolor={"#D32F2F"} width={"100%"}></Box>
                                                            </Box> : null}
                                                        </Box>
                                                    </Box>
                                                    <HtmlTooltip arrow
                                                        title={
                                                            <React.Fragment>
                                                                <Stack gap={"6px"} >
                                                                    <Box display={"flex"} alignItems={"center"} gap={1.5}>
                                                                        <Box minWidth={10} width={10} height={10} bgcolor={"#047FE0"}></Box>
                                                                        <Typography color={"white"} fontSize={14} fontWeight={500} lineHeight={"21px"}>Auto Tracking</Typography>
                                                                    </Box>
                                                                    <Box display={"flex"} alignItems={"center"} gap={1.5}>
                                                                        <Box minWidth={10} width={10} height={10} bgcolor={"#F0891B"}></Box>
                                                                        <Typography color={"white"} fontSize={14} fontWeight={500} lineHeight={"21px"}>Manual Tracking</Typography>
                                                                    </Box>
                                                                    <Box display={"flex"} alignItems={"center"} gap={1.5}>
                                                                        <Box minWidth={10} width={10} height={10} bgcolor={"#D32F2F"}></Box>
                                                                        <Typography color={"white"} fontSize={14} fontWeight={500} lineHeight={"21px"}>Time Exceeds</Typography>
                                                                    </Box>
                                                                </Stack>
                                                            </React.Fragment>
                                                        }
                                                    >
                                                        <HelpOutlineIcon fontSize="small" sx={{ color: '#3F5C76', mt: 0.5 }} />
                                                    </HtmlTooltip>
                                                </Box>
                                            </Box> : null}
                                    </Stack>
                                </Box>
                                <Divider />
                                <Box my={"18px"} className="text-editor">
                                    <Box pl={1} display={"flex"} alignItems={"center"} gap={"6px"} mb={"10px"}>
                                        <SubjectIcon fontSize="small" sx={{ color: 'dark.main' }} />
                                        <Typography
                                            variant="body2"
                                            color={"dark.main"}
                                            fontSize={14}
                                            fontWeight={600}
                                            sx={{ lineHeight: "21px", letterSpacing: "0.15" }}
                                        >
                                            Description
                                        </Typography>
                                    </Box>
                                    {isEditTask ? <TextEditor
                                        value={taskData.description}
                                        onChange={(value) => { }}
                                        readOnly={true}
                                        isShowReadMore={true}
                                    /> : null}

                                    {!isEditTask ? <TextEditor
                                        value={taskData.description}
                                        onChange={(value) =>
                                            handleChangeDescription(value)
                                        }
                                    /> : null}
                                </Box>
                                <Box mb={"18px"}>
                                    <Box px={1} display={"flex"} justifyContent={"space-between"} mb={1}>
                                        <Box display={"flex"} alignItems={"center"} gap={"6px"} >
                                            <AttachFileIcon fontSize="small" sx={{ color: 'dark.main' }} />
                                            <Typography color={"text.main"}
                                                fontSize={14}
                                                fontWeight={600}
                                                sx={{ lineHeight: "21px", letterSpacing: "0.15" }}>Attachments</Typography>
                                            <Box py={"2px"} px={0.5} h={16} minWidth={24} textAlign={"center"} borderRadius={"50px"} bgcolor={"rgba(211, 215, 222, 0.5)"}>
                                                <Typography color={"text"} fontSize={10} fontWeight={400} lineHeight={"12px"}>{taskData?.attachments?.length}</Typography>
                                            </Box>
                                        </Box>
                                        <Box display={"flex"} alignItems={"center"} gap={2}>
                                            {isEditTask ? <Button
                                                variant="text"
                                                color="primary"
                                                size="small"
                                                sx={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}
                                                startIcon={<FileDownloadIcon fontSize="small" />}
                                                onClick={() => downloadAllFiles()}
                                            >
                                                Download All
                                            </Button> : null}
                                            <Button
                                                component="label"
                                                variant="text"
                                                color="primary"
                                                sx={{ textTransform: 'none', display: 'flex', alignItems: 'center' }}
                                                startIcon={<AddIcon fontSize="small" />}
                                                width={'300px'}
                                            >
                                                Upload
                                                <VisuallyHiddenInput
                                                    name="attachments"
                                                    type="file"
                                                    onChange={handleChangeImage}
                                                    accept="image/*, application/pdf"
                                                    multiple
                                                />
                                            </Button>
                                        </Box>
                                    </Box>
                                    {!taskData?.attachments?.length ?
                                        <CustomFileDropZone
                                            onDrop={(files) =>
                                                setTaskData(prev => ({
                                                    ...prev,
                                                    attachments: files,
                                                }))
                                            }
                                            initialFiles={taskData?.attachments}
                                            isConvertToBase64={true}
                                            isDownloadShow={isEditTask}
                                        /> : null}

                                    <Box>
                                        {taskData?.attachments?.length ? <Typography color={"dark.600"} fontSize={11} fontWeight={400} lineHeight={"16px"} letterSpacing={"0.17px"} pl={1} mb={1}>PDF, PNG, JPG (max. 10MB per file)</Typography> : null}
                                        {/* <Box display={"flex"} gap={"10px"}>
                                            <Box border={"1px solid #D3D7DE"} width={170} minWidth={170} gap={1} p={"6px"} borderRadius={"5px"} display={"flex"} alignItems={"center"} overflow={"hidden"}>
                                                <Box minWidth={30} width={30} height={30}>
                                                    <Avatar alt="Remy Sharp" src={userImg} sx={{ width: 30, height: 30 }} />
                                                </Box>
                                                <Box flex={1} overflow={"hidden"}>
                                                    <Typography color={"dark.800"} fontSize={12} fontWeight={500} lineHeight={"16px"} letterSpacing={"0.17px"} mb={"1px"} textOverflow={"ellipsis"} overflow={"hidden"}>Firstattachm...jpg</Typography>
                                                    <Typography color={"dark.600"} fontSize={10} fontWeight={400} lineHeight={"12px"} letterSpacing={"0.17px"}>100KB</Typography>
                                                </Box>
                                            </Box>
                                        </Box> */}
                                        {taskData?.attachments?.length ?
                                            <Box display={"flex"} gap={"10px"} flexWrap={'wrap'}>
                                                <AttachmentLists attachments={taskData?.attachments} />
                                            </Box> : null}

                                    </Box>
                                </Box>
                                <Divider />
                                {isEditTask ? <Box py={2} gap={0.5}>
                                    <Typography color={"dark.600"} fontSize={13} fontWeight={500} lineHeight={"20px"} letterSpacing={"0.17px"} display={"block"}><b>Updated</b> {formatTimeAgo(taskData?.updatedAt, true)}</Typography>
                                    <Typography color={"dark.600"} fontSize={13} fontWeight={500} lineHeight={"20px"} letterSpacing={"0.17px"} display={"block"}><b>Created</b> {formatTimeAgo(taskData?.createdAt, true)}</Typography>
                                </Box> : null}

                            </Box>
                            {isEditTask ? <Box sx={{ width: '100%' }}>
                                <Box sx={{
                                    borderBottom: 1, borderColor: 'divider', padding: '0px 16px',
                                    'button': {
                                        padding: '8px 12px',
                                        fontWeight: 600,
                                        fontSize: 14,
                                        lineHeight: '24px',
                                        color: '#3F5C76',
                                        textTransform: 'none',
                                        '&.Mui-selected': {
                                            color: '#047FE0',
                                        }
                                    },
                                }}>
                                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                        <Tab label="Comments" {...a11yProps(0)} />
                                        <Tab label="WorkLogs" {...a11yProps(1)} />
                                        <Tab label="Activities" {...a11yProps(2)} />
                                    </Tabs>
                                </Box>
                                <Box bgcolor="secondary"
                                    flex={1}
                                    overflow="hidden"
                                    sx={{
                                        "> div": {
                                            height: '100%',
                                        },

                                        ".tabspanel > .MuiBox-root": {
                                            padding: "20px",
                                            height: '100%',
                                            "> p": {
                                                height: '100%',
                                            }
                                        }
                                    }}
                                >
                                    <CustomTabPanel value={value} index={0} className="tabspanel">
                                        <TaskComment commentData={taskData?.TaskComments ?? []} usersList={usersList} taskData={taskData} showAlert={showAlert} setTaskData={setTaskData} currentUser={currentUser} />
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={1} className="tabspanel">
                                        <TaskWorklog worklogData={taskData?.WorkLogs ?? []} usersList={usersList} taskData={taskData} />
                                    </CustomTabPanel>
                                    <CustomTabPanel value={value} index={2} className="tabspanel">
                                        <TaskActivity activityData={taskData?.TaskActivities ?? []} usersList={usersList} taskData={taskData} />
                                    </CustomTabPanel>
                                </Box>
                            </Box> : null}

                        </Box>
                        <Box p={2.5} display={"flex"} gap={2.5} bgcolor={"white"} position={"sticky"} bottom={"0"} borderTop={"1px solid rgba(0, 0, 0, 0.12)"}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                type="button"
                                sx={{ textTransform: "none", boxShadow: 'none' }}
                                onClick={() => handleSubmitData()}
                                disabled={isLoading?.taskSubmitLoading}
                                isButtonLoading={isLoading.taskSubmitLoading}
                            >
                                {isEditTask ? "Submit" : "Create"}
                            </Button>
                            <Button
                                variant="text"
                                color="secondary"
                                size="medium"
                                sx={{ textTransform: "none", boxShadow: 'none' }}
                                disabled={isLoading?.taskSubmitLoading}
                                onClick={() => handleCloseTaskDrawer()}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </>
            }
        </Box>
    )
}

export default CreateTask