import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isActivityLoading: true,
    activityCountData: [],
    allActivityData: [],
};

const activityActionDataSlice = createSlice({
    name: 'actionData',
    initialState,
    reducers: {
        setIsActionData: (state, action) => {
            state.activityCountData = action.payload;
        },
        setAllActivityData: (state, action) => {
            state.allActivityData = action.payload;
        },
        setActivityLoaderData: (state, action) => {
            state.isActivityLoading = action.payload;
        },
        clearActionData: (state) => {
            state.activityCountData = [];
            state.allActivityData = [];
        }
    },
});

export const { setIsActionData, setAllActivityData, clearActionData, setActivityLoaderData } = activityActionDataSlice.actions;
export default activityActionDataSlice.reducer;
