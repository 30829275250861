import { Avatar, Box, Button, Divider, Grid, IconButton, Tooltip, tooltipClasses, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SearchBoxInput from '../../../../components/SearchBoxInput'
import { TASK_SELECT_TAB } from '../../../../constants/default-values'
import ProjectNameChip from '../../../../components/ProjectNameChip'
// import { ReactComponent as TimeProgressIcon } from "../../../../assets/images/time-icon.svg";
import { ReactComponent as CalenderIcon } from "../../../../assets/images/calender-icon.svg";
import BackgroundLetterAvatars from '../../../../components/BackgroundLetterAvatars';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { styled } from '@mui/styles'
import taskLoader from '../../../../assets/images/project-task-loader.png';
import { getApplyDateTime, getPriorotyIcon, getTaskStatus, getTaskTypeIcon, secondsToTime, sortArrayByKey, titleCase } from '../../../../utils';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import KeyboardReturnRoundedIcon from '@mui/icons-material/KeyboardReturnRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        padding: "8px 12px",
        borderRadius: "5px",
        background: "#444444",
    },
}));

const TasksModal = ({ modalDetails,
    handleTaskModalClose,
    currentUser,
    searchString,
    handleSearch,
    selectedProjectTask,
    handleAddNewTask,
    isLoading,
    handleAddExistTask,
    allProjectPhases,
    LastDayLogoutData,
    isLastDayTaskRemaining,
    getTaskDate }) => {
    const [selectedTab, setSelectedTab] = useState(Object.keys(TASK_SELECT_TAB)[0]);
    const [filteredData, setFilteredData] = useState({});

    const [focusedIndex, setFocusedIndex] = useState({ taskIndex: 0, childIndex: 0 });

    useEffect(() => {
        if (selectedProjectTask?.length) {
            let sortedData = sortArrayByKey(selectedProjectTask, "desc", "createdAt");
            getDataAccordinFilter(sortedData);
            setFocusedIndex({ taskIndex: 0, childIndex: 0 });
        } else {
            setFilteredData({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProjectTask, selectedTab])

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowDown') {
                navigateDown();
            } else if (event.key === 'ArrowUp') {
                navigateUp();
            } else if (event.key === 'Enter') {
                selectFocusedTask();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusedIndex]);

    const navigateDown = () => {
        const totalTasks = Object.entries(filteredData).length;
        const { taskIndex, childIndex } = focusedIndex;

        if (taskIndex < totalTasks) {
            let currentTaskTotalChild = Object.entries(filteredData)?.[taskIndex]?.[1]

            if (childIndex < currentTaskTotalChild?.length - 1) {
                setFocusedIndex(prev => ({
                    taskIndex: taskIndex,
                    childIndex: prev?.childIndex + 1
                }));
            } else {
                if (taskIndex === totalTasks - 1) {
                    setFocusedIndex({ taskIndex: 0, childIndex: 0 });
                } else {
                    setFocusedIndex({
                        taskIndex: taskIndex + 1,
                        childIndex: 0
                    });
                }
            }
        } else {
            setFocusedIndex({ taskIndex: 0, childIndex: 0 });
        }
    };

    const navigateUp = () => {
        const { taskIndex, childIndex } = focusedIndex;

        let currentTaskTotalChild = Object.entries(filteredData)?.[taskIndex]?.[1]

        if (childIndex > 0 && currentTaskTotalChild?.length > 1) {
            setFocusedIndex(prev => ({
                taskIndex: taskIndex,
                childIndex: prev?.childIndex - 1
            }));
        } else {
            if (taskIndex === 0) {
                setFocusedIndex({ taskIndex: 0, childIndex: 0 });
            } else {
                let currentTaskTotalChild = Object.entries(filteredData)?.[(taskIndex - 1)]?.[1];
                setFocusedIndex({
                    taskIndex: taskIndex - 1,
                    childIndex: currentTaskTotalChild?.length - 1
                });
            }
        }
    };

    const selectFocusedTask = () => {
        const { taskIndex, childIndex } = focusedIndex;
        const selectedTask = filteredData[Object.keys(filteredData)[taskIndex]][childIndex];
        handleAddExistTask(selectedTask);
    };

    const getProjectPhaseShortCode = (phaseData) => {
        if (phaseData?.title?.length) return phaseData?.title?.split(" ")?.map((word) => word.charAt(0).toUpperCase()).join("");
        else return "P1"
    }

    const getDataAccordinFilter = (sortedData) => {
        if (selectedTab === Object.keys(TASK_SELECT_TAB)[0]) {
            const now = new Date();
            const last7Days = [];
            const last30Days = [];

            sortedData.forEach(item => {
                const createdAt = new Date(item?.createdAt);
                const diffDays = Math.floor((now - createdAt) / (1000 * 3600 * 24));
                if (diffDays <= 7) {
                    last7Days.push(item);
                }

                if (diffDays > 7 && diffDays <= 30) {
                    last30Days.push(item);
                }
            });
            let updatedGroupByData = {};
            if (last7Days?.length) {
                updatedGroupByData['My Last 7 days tasks'] = last7Days;
            }
            if (last30Days?.length) {
                updatedGroupByData['My Last 30 days tasks'] = last30Days;
            }
            setFilteredData(updatedGroupByData);
        } else if (selectedTab === Object.keys(TASK_SELECT_TAB)[1]) {
            let filteredDataWithPhase = sortedData?.filter(taskData => !!taskData?.ProjectPhase)
            let keyToFilter = "ProjectPhase?.title"
            let phaseWiseTask = filteredDataWithPhase?.reduce((result, item) => {
                let _key = keyToFilter.split("?.").reduce((acc, part) => acc?.[part], item);
                if (!result[`My tasks in ${_key}`]) {
                    result[`My tasks in ${_key}`] = [];
                }
                result[`My tasks in ${_key}`].push(item);
                return result;
            }, {});

            setFilteredData(phaseWiseTask);
        } else {
            let filteredDataWithoutPhase = sortedData?.filter(taskData => !taskData?.ProjectPhase)
            let phaseWiseTask = filteredDataWithoutPhase?.reduce((result, item) => {
                if (!result[`My tasks in Anonymous phase`]) {
                    result[`My tasks in Anonymous phase`] = [];
                }
                result[`My tasks in Anonymous phase`].push(item);
                return result;
            }, {});
            setFilteredData(phaseWiseTask);
        }
    }

    const getTaskCreationTime = (date) => {
        const createdDate = new Date(date);
        const currentDate = new Date();
        const timeDifference = currentDate - createdDate;
        const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        if (daysAgo > 0) {
            return `${daysAgo} days ago`;
        } else {
            return "Today";
        }
    }

    const currentFilterProjectTask = () => {
        if (selectedTab === Object.keys(TASK_SELECT_TAB)[1]) {
            return selectedProjectTask?.filter(taskData => !!taskData?.ProjectPhase)
        } else if (selectedTab === Object.keys(TASK_SELECT_TAB)[2]) {
            return selectedProjectTask?.filter(taskData => !taskData?.ProjectPhase)
        } else {
            return selectedProjectTask
        }
    }

    return (
        <Box width={'100%'} display={"flex"} flexDirection={"column"} flex={1}>
            <Box pt={"40px"} px={"30px"} pb={2.5}>
                {(LastDayLogoutData?.length && LastDayLogoutData?.[0]?.date) ?
                    <Box display={'flex'} gap={1} mb={3}>
                        <Typography color={"dark.800"} fontSize={20} fontWeight={600} lineHeight={"30px"}>Select Tasks for</Typography>
                        <Typography color={"error"} fontSize={20} fontWeight={600} lineHeight={"30px"}>({getTaskDate()})</Typography>
                    </Box>
                    : null}
                <Grid width={'100%'}>
                    <Box position={"relative"} sx={{ 'input': { px: 4 } }}>
                        <SearchRoundedIcon sx={{ position: 'absolute', fontSize: 24, color: 'rgba(0, 0, 0, 0.54)', top: '3px', left: '0px', zIndex: 1 }} />
                        <SearchBoxInput
                            label=""
                            variant="standard"
                            placeholder="Type and search tasks"
                            handleSearch={handleSearch}
                            value={searchString}
                            isUseDebounce={false}
                            isOverwritePlaceholder={true}
                            sx={{ width: '100%' }}
                        />
                        <IconButton sx={{ position: 'absolute', top: '3px', right: '0px', zIndex: 1, p: 0 }}>
                            <CloseRoundedIcon sx={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.54)', }} />
                        </IconButton>
                    </Box>
                </Grid>
                <Box display={'flex'} justifyContent={'space-between'} pt={1.5}>
                    <Box display={"flex"} alignItems={"center"} gap={"6px"}>
                        <Typography color={"dark.600"} fontSize={16} fontWeight={500} lineHeight={"19px"}>Navigate</Typography>
                        <IconButton
                            size="small"
                            color="dark.600"
                            width={24}
                            height={24}
                            minWidth={24}
                            sx={{ boxShadow: '0px 1px 3px rgba(0,0,0,0.2)', borderRadius: '2px' }}
                        >
                            <ArrowDownwardIcon sx={{ fontSize: 18, }} />
                        </IconButton>
                        <IconButton
                            size="small"
                            color="dark.600"
                            width={24}
                            height={24}
                            minWidth={24}
                            sx={{ boxShadow: '0px 1px 3px rgba(0,0,0,0.2)', borderRadius: '2px' }}
                        >
                            <ArrowUpwardIcon sx={{ fontSize: 18, }} />
                        </IconButton>
                    </Box>
                    <Box display={'flex'} alignItems={"center"}>
                        <Typography fontSize={16} color={"dark.600"} fontWeight={500} lineHeight={"19px"} mr={"6px"}>Close</Typography>
                        <Typography p={"5px"} fontSize={14} fontWeight={500} color={"dark.600"} lineHeight={"14px"}
                            sx={{ boxShadow: '0px 1px 3px rgba(0,0,0,0.2)', borderRadius: '2px' }}
                        >esc</Typography>
                    </Box>
                </Box>
            </Box>
            <Divider py={2} />
            <Box pt={2.5} px={"30px"} pb={"40px"} flex={1} overflow={"auto"}>
                <Box display={'flex'} justifyContent={'space-between'} mb={3} alignItems={"center"}>
                    <Box display={"inline-flex"} gap={1}>
                        {Object.entries(TASK_SELECT_TAB)?.map((tasktab, index) => (
                            <Button
                                key={index}
                                variant={tasktab[0] === selectedTab ? "outlined" : "text"}
                                size="small"
                                color={"secondary"}
                                name={selectedTab}
                                value={selectedTab}
                                disabled={isLoading?.projectTaskLoading}
                                sx={{
                                    textTransform: "none",
                                    background: `${tasktab[0] === selectedTab ? "rgba(4, 127, 224, 0.1)" : "#F7F7F7"
                                        }`,
                                    borderColor: `${tasktab[0] === selectedTab ? "primary !important" : "none"
                                        }`,
                                    position: "relative",
                                    zIndex: `${tasktab[0] === selectedTab ? "1" : "none"}`,
                                    paddingLeft: { xs: '10px', md: '15px' },
                                    paddingRight: { xs: '10px', md: '15px' },
                                    borderRadius: "3px"
                                }}
                                onClick={() => setSelectedTab(tasktab[0])}
                            >
                                {tasktab[1]}
                            </Button>
                        ))}
                    </Box>
                    <Box>
                        <ProjectNameChip label={modalDetails?.projectDetail?.title} code={modalDetails?.projectDetail?.short_code} size="medium" />
                    </Box>
                </Box>
                {isLoading?.projectTaskLoading ?
                    <Box>
                        <img alt='404 page' src={taskLoader} style={{ width: '100%' }} />
                    </Box>
                    :
                    <>
                        {
                            !currentFilterProjectTask()?.length ?
                                <Box mb={2}>
                                    <Typography fontSize={14} fontWeight={700} lineHeight={'16.94px'}>
                                        No Tasks Found
                                    </Typography>
                                </Box>
                                :
                                <>
                                    {
                                        Object.entries(filteredData)?.map((taskData, taskIndex) => (
                                            <Box key={taskIndex}>
                                                <Typography color={"dark.800"} fontSize={14} fontWeight={700} lineHeight={'16.94px'} mb={1.5}>
                                                    {taskData[0]}
                                                </Typography>
                                                <Box border={"1px solid #eeeeee"} borderRadius={"3px"}>
                                                    {
                                                        taskData[1]?.map((taskListDetail, childIndex) => {
                                                            const isFocused = focusedIndex?.taskIndex === taskIndex && focusedIndex?.childIndex === childIndex;
                                                            return (
                                                                <Box
                                                                    key={`${taskIndex}-${childIndex}`}
                                                                    onClick={() => handleAddExistTask(taskListDetail)}
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                        backgroundColor: isFocused ? 'rgba(4, 127, 224, 0.07) !important' : 'transparent',
                                                                        padding: '10px',
                                                                        borderBottom: '1px solid #eeeeee',
                                                                        transition: 'all 0.25s ease-in-out',
                                                                        '&:last-child': {
                                                                            border: 'none',
                                                                        },
                                                                        '&:hover': {
                                                                            background: '#f7f7f7',
                                                                        },
                                                                    }}
                                                                >
                                                                    {/* Render Task Details */}
                                                                    <Box display={'flex'}>
                                                                        <Box display={"flex"} flex={1} overflow={"hidden"}>
                                                                            <Box width={48} minWidth={48} height={48} sx={{ background: 'white', border: '1px solid #3F5C7633' }} display={'flex'} justifyContent={'center'} alignItems={'center'} mr={1}>
                                                                                {getTaskTypeIcon(taskListDetail?.type)}
                                                                            </Box>
                                                                            <Box flex={1} pr={2} overflow={"hidden"}>
                                                                                <Box display={'flex'} gap={"6px"} mb={"9px"}>
                                                                                    <Typography color={'secondary'} fontSize={14} fontWeight={700} lineHeight={'16.94px'} whiteSpace={"nowrap"} sx={{ textDecoration: 'underline' }}>
                                                                                        {`${taskListDetail?.Project?.short_code?.toUpperCase()}-${taskListDetail?.task_no}`}
                                                                                    </Typography>
                                                                                    <Typography color={'dark'} fontSize={14} fontWeight={600} lineHeight={'16.94px'} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"} maxWidth={"100%"}>
                                                                                        {taskListDetail?.title}
                                                                                    </Typography>
                                                                                </Box>
                                                                                <Box display={'flex'} gap={2} alignItems={"center"}>
                                                                                    <BackgroundLetterAvatars
                                                                                        user={taskListDetail?.User}
                                                                                        sx={{ width: "22px", height: "22px", fontSize: '60%', lineHeight: "22px" }}
                                                                                    />
                                                                                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={0.5} alignSelf={'center'}>
                                                                                        <CalenderIcon
                                                                                            sx={{
                                                                                                fontSize: 16,
                                                                                                color: '#3F5C76',
                                                                                            }}
                                                                                        />
                                                                                        <HtmlTooltip
                                                                                            title={
                                                                                                <>
                                                                                                    <Typography fontSize={{ xs: 14 }} fontWeight={500} lineHeight={"21px"}>{'Last work on:'}</Typography>
                                                                                                    <Typography fontSize={{ xs: 16 }} fontWeight={500} lineHeight={"24px"}>{getApplyDateTime(taskListDetail?.createdAt, "DD/MM/YYYY, dddd")}</Typography>
                                                                                                </>
                                                                                            }>
                                                                                            <Typography color={'dark'} fontSize={13} fontWeight={500} lineHeight={'16.94px'} borderBottom={'1px dotted #3F5C76'}>
                                                                                                {getTaskCreationTime(taskListDetail?.createdAt)}
                                                                                            </Typography>
                                                                                        </HtmlTooltip>
                                                                                    </Box>

                                                                                    <HtmlTooltip
                                                                                        title={
                                                                                            <Box>
                                                                                                <Typography fontSize={12} fontWeight={500} lineHeight={"18px"}>Time spent</Typography>
                                                                                                <Typography fontSize={26} fontWeight={500} lineHeight={"33px"}>{secondsToTime(taskListDetail?.spent ?? 0)}</Typography>
                                                                                                <Box display={"flex"} alignItems={"center"} mt={0.5}>
                                                                                                    <Typography fontSize={{ xs: 14 }} fontWeight={500} lineHeight={"21px"} mr={0.5}>Estimated Hours: </Typography>
                                                                                                    <Typography fontSize={{ xs: 14 }} fontWeight={600} lineHeight={"21px"}>{secondsToTime(taskListDetail?.estimation ?? 0)}</Typography>
                                                                                                </Box>
                                                                                            </Box>
                                                                                        }
                                                                                    >
                                                                                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={0.5} alignSelf={'center'}>
                                                                                            <ScheduleIcon
                                                                                                sx={{
                                                                                                    fontSize: 16,
                                                                                                    color: '#3F5C76',
                                                                                                }}
                                                                                            />
                                                                                            <Typography color={'dark'} fontSize={13} fontWeight={400} lineHeight={'16.94px'}>
                                                                                                {secondsToTime(taskListDetail?.spent ?? 0)}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </HtmlTooltip>
                                                                                    {/* <TimeProgressIcon /> */}
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                        <Box>
                                                                            <Box display={'flex'} gap={"6px"}
                                                                                sx={{ '.MuiChip-outlined': { margin: '0px' } }}
                                                                            >
                                                                                {taskListDetail?.ProjectPhase ? <HtmlTooltip
                                                                                    title={
                                                                                        <Typography fontSize={{ xs: 14 }} fontWeight={500} lineHeight={"21px"}>{titleCase(taskListDetail?.ProjectPhase?.title)}</Typography>
                                                                                    }>
                                                                                    <Avatar backgroundColor={'primary'} sx={{ width: "24px", height: "24px", fontSize: '70%', lineHeight: '24px', background: '#3F5C76', color: '#ffffff' }}>{getProjectPhaseShortCode(taskListDetail?.ProjectPhase)}</Avatar>
                                                                                </HtmlTooltip> : null}
                                                                                <HtmlTooltip
                                                                                    title={
                                                                                        <Typography fontSize={{ xs: 14 }} fontWeight={500} lineHeight={"21px"}>{`${titleCase(taskListDetail?.priority)} Priority`}</Typography>
                                                                                    }>
                                                                                    <Box width={24} minWidth={24} height={24} sx={{ background: 'white', border: '1px solid rgba(63, 92, 118, 0.2)', borderRadius: '2px' }} display={'flex'} justifyContent={'center'} alignItems={'center'} justifyItems={'center'}>
                                                                                        {getPriorotyIcon(taskListDetail?.priority)}
                                                                                    </Box>
                                                                                </HtmlTooltip>
                                                                                {getTaskStatus(taskListDetail?.status, "chip")}
                                                                            </Box>
                                                                            {isFocused ? <Box width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"flex-end"} gap={"6px"} mt={"6px"}>
                                                                                <Typography color={'dark.600'} fontSize={12} fontWeight={500} lineHeight={'14.52px'}>
                                                                                    Select
                                                                                </Typography>
                                                                                <Box maxWidth={18} minWidth={18} width={18} height={18} display={"flex"} alignItems={"center"} bgcolor={"#D7D7D7"} sx={{ boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)' }}>
                                                                                    <KeyboardReturnRoundedIcon sx={{ fontSize: '16px', color: '#ffffff' }} />
                                                                                </Box>
                                                                            </Box> : null}
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            );
                                                        })
                                                    }
                                                </Box>
                                            </Box>
                                        ))
                                    }
                                </>
                        }
                    </>
                }
                {(!currentFilterProjectTask()?.length && searchString?.trim()?.length) ? <Box sx={{ background: '#047FE01A', cursor: 'pointer' }} py={1.5} px={"14px"} onClick={() => handleAddNewTask()}>
                    <Typography color={'primary'} fontSize={13} fontWeight={500} lineHeight={'16px'}>
                        Create New Task <b>"{searchString?.trim()}"</b>
                    </Typography>
                </Box> : null}
            </Box>
        </Box>
    )
}

export default TasksModal