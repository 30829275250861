import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./texteditor.css";
import Button from "../Button";

const TextEditor = ({ value, onChange, readOnly = false, isShowReadMore = false, ...rest }) => {
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "align", { list: "ordered" }, { list: "bullet" }],
    ],
  };

  const formats = ["bold", "italic", "underline", "list", "bullet"];

  const [code, setCode] = useState(value || "");
  const [isExpanded, setIsExpanded] = useState(false);
  const [truncatedHtml, setTruncatedHtml] = useState("");

  useEffect(() => {
    const updatedContent = value
      ? value?.replace(/(\S)\s{2,}(\S)/g, "$1&nbsp;&nbsp;$2")
      : "";
    setCode(updatedContent || "");

    // Generate truncated HTML content for "See More"
    if (value) {
      setTruncatedHtml(truncateHtml(value, 100));
    }
  }, [value]);

  const handleContentChange = (content) => {
    const updatedContent = content?.replace(/(\S)\s{2,}(\S)/g, "$1&nbsp;&nbsp;$2");
    setCode(updatedContent);
    onChange(updatedContent);
  };

  const handleToggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const truncateHtml = (html, maxLength) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    let charCount = 0;
    let truncated = false;

    const truncateNode = (node) => {
      if (truncated) return;

      if (node.nodeType === Node.TEXT_NODE) {
        const remainingChars = maxLength - charCount;
        if (node.nodeValue.length > remainingChars) {
          node.nodeValue = node.nodeValue.substring(0, remainingChars) + "...";
          charCount = maxLength;
          truncated = true;
        } else {
          charCount += node.nodeValue.length;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const children = Array.from(node.childNodes);

        for (let i = 0; i < children.length; i++) {
          if (truncated) {
            // Remove extra children after truncation
            while (node.lastChild && i < node.childNodes.length) {
              node.removeChild(node.lastChild);
            }
            break;
          }
          truncateNode(children[i]);
        }
      }
    };

    truncateNode(tempDiv);
    return tempDiv.innerHTML;
  };

  return (
    <div>
      {isShowReadMore ? (
        <div>
          {isExpanded ? (
            // Show full content when expanded
            <div className="ql-editor" dangerouslySetInnerHTML={{ __html: code }}></div>
          ) : (
            // Show truncated content when collapsed
            <div className="ql-editor" dangerouslySetInnerHTML={{ __html: truncatedHtml }}></div>
          )}
          {code.length > 100 && (
            <Button
              variant="text"
              color="primary"
              size="small"
              onClick={handleToggleReadMore}
              disableRipple={true}
              sx={{ textTransform: 'none', p :0,
                '&:hover': {
                  background: 'transparent',
                  opacity: 0.75,
                },
              }}>
              {isExpanded ? "See Less" : "See More"}
            </Button>
          )}
        </div>
      ) : (
        <ReactQuill
          id="editor-0"
          name="editor-0"
          placeholder="Type Here"
          theme="snow"
          modules={readOnly ? { toolbar: false } : modules}
          formats={formats}
          value={code}
          onChange={handleContentChange}
          readOnly={readOnly}
          InputProps={{ id: "editor" }}
          {...rest}
        />
      )}
    </div>
  );
};

export default TextEditor;

// import React, { useState, useEffect } from "react";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
// import "./texteditor.css";

// const TextEditor = ({ value, onChange, readOnly = false, ...rest }) => {

//   const modules = {
//     toolbar: [
//       [
//         "bold",
//         "italic",
//         "underline",
//         "align",
//         { list: "ordered" },
//         { list: "bullet" },
//       ],
//       // [{ header: [1, 2, 3, 4, 5, 6, false] }],
//       // ["bold", "italic", "underline", "strike", "blockquote"],
//       // [{ align: ["right", "center", "justify"]}],
//       // [{ list: "ordered" }, { list: "bullet" }],
//       // ["link", "image"],
//       // [{ color: myColors }],
//       // [{ background: myColors }],
//     ],
//   };

//   const formats = [
//     // "header",
//     "bold",
//     "italic",
//     "underline",
//     // "strike",
//     // "blockquote",
//     "list",
//     "bullet",
//     // "link",
//     // "color",
//     // "image",
//     // "background",
//     // "align",
//   ];

//   const [code, setCode] = useState(value || "");

//   useEffect(() => {
//     const updatedContent = value ? value?.replace(/(\S)\s{2,}(\S)/g, '$1&nbsp;&nbsp;$2') : "";

//     setCode(updatedContent || "");
//   }, [value]);

//   const handleProcedureContentChange = (content) => {
//     const updatedContent = content?.replace(/(\S)\s{2,}(\S)/g, '$1&nbsp;&nbsp;$2');
//     setCode(updatedContent);
//     onChange(updatedContent);
//   };

//   const handleProcedureContentBlur = (content) => {
//     const trimmedContent = code?.replace(/(<[^>]+>)(\s*&nbsp;)+|(&nbsp;\s*)+(<\/[^>]+>)/g, '$1$4');
//     setCode(trimmedContent);
//   }

//   return (
//     <ReactQuill
//       id="editor-0"
//       name="editor-0"
//       placeholder="Type Here"
//       theme="snow"
//       modules={readOnly ? { toolbar: false } : modules}
//       formats={formats}
//       value={code}
//       onChange={handleProcedureContentChange}
//       onChangeSelection={(range, source, editor) => {
//         if (!range && source === 'user') {
//           handleProcedureContentBlur();
//         }
//       }}
//       readOnly={readOnly}
//       InputProps={{ id : "editor" }}
//       {...rest}
//     />
//   );
// };

// export default TextEditor;