import React, { useEffect, useState } from "react";
import { usePageTitle } from "../../../hook/useTitle";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import URLS from "../../../routes/urls";
import ICONS from "../../../constants/icons";
import useAuthentication from "../../../hook/useAuthentication";
import axiosInstance from "../../../axios";
import API from "../../../axios/api";
import { getFormattedDate, getInputId, isProjectEditAccess, sortArrayByKey, titleCase } from "../../../utils";
import { useAlert } from "../../../hook/useAlert";
import { getKeyByValue, PROJECT_STATUS, PROJECT_STATUS_LIST } from "../../../constants/default-values";
import useQuery from "../../../hook/useQuery";
import useDebounce from "../../../hook/useDebounce";
import SearchBoxInput from "../../../components/SearchBoxInput";
import BackgroundLetterAvatars from "../../../components/BackgroundLetterAvatars";
import CircularLoader from "../../../components/CircularLoader";
import TablePagination from "../../../components/TablePagination";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#444444',
    color: '#ffffff',
    maxWidth: 'inherit',
    padding: 16,
  },
}));


const Projects = () => {
  const { setPageTitle } = usePageTitle();
  const { getCurrentUser } = useAuthentication();
  const { getQueryParams, setQueryParams } = useQuery();
  const showAlert = useAlert();
  const currentUser = getCurrentUser();
  let status =
    getQueryParams("status") &&
      Object.values(PROJECT_STATUS_LIST)?.includes(getQueryParams("status"))
      ? getQueryParams("status")
      : Object.values(PROJECT_STATUS_LIST)[0];

  setPageTitle("Projects");
  const navigate = useNavigate();

  const [projectList, setProjectList] = useState([]);
  const [projectDataCount, setProjectDataCount] = useState(0);
  const [pageBeenRendered, setPageBeenRendered] = useState(false);

  const [searchString, setSearchString] = useState("");
  const debouncedSearchTerm = useDebounce(searchString);

  const [isLoading, setIsLoading] = useState({
    pageLoading: true,
  });
  const [params, setParams] = useState({
    orgId: null,
    page: 1,
    limit: 25,
    status: status,
    search: null,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openElem, setOpenElem] = useState(null);

  useEffect(() => {
    getAllProjectList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (pageBeenRendered) {
      setParams((prev) => ({
        ...prev,
        search: debouncedSearchTerm,
      }));
    }
    setPageBeenRendered(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const getAllProjectList = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, pageLoading: true }));

      let queryParams = {
        ...params,
        orgId: currentUser?.organization_id,
      };
      if (params?.status === "all") {
        delete queryParams?.status;
      }
      const response = await axiosInstance.post(
        API.getAllProjectsListWithMembers,
        queryParams
      );

      if (response?.status === 200) {
        setProjectList(response?.data?.data?.rows);
        setProjectDataCount(response?.data?.data?.count);
      } else {
        setProjectList([]);
      }
      setIsLoading((prev) => ({ ...prev, pageLoading: false }));
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, pageLoading: false }));
    }
  };

  const handleClick = (elem) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenElem(elem);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenElem(null);
  };

  const handleClickEdit = (editabelProjectData, tabValue) => {
    handleClose();
    navigate(
      `${URLS.UpdateProject}/${editabelProjectData?.id}?tabValue=${tabValue}`
    );
  };

  const handleMoveProject = async (editabelProjectData, moveStatus) => {
    try {
      setIsLoading((prev) => ({ ...prev, pageLoading: true }));
      handleClose();
      let payload = {
        ...editabelProjectData,
        title: editabelProjectData?.title,
        type: editabelProjectData?.type,
        status: moveStatus,
        short_code: editabelProjectData?.short_code,
        is_private: editabelProjectData?.is_private,
        description: editabelProjectData?.description,
        org_id: currentUser?.organization_id,
        start_date: editabelProjectData?.start_date,
      };
      if (editabelProjectData?.type !== "fixed") delete payload["total_hours"];
      const response = await axiosInstance.put(
        API.updateProject(editabelProjectData?.id),
        payload
      );
      if (response?.status === 200) {
        showAlert(response.data?.message);
        getAllProjectList();
      }
      setIsLoading((prev) => ({ ...prev, pageLoading: false }));
    } catch (error) {
      setIsLoading((prev) => ({ ...prev, pageLoading: false }));
      console.error(error);
    }
  };

  const handleFilterChange = (event) => {
    setQueryParams(event?.target?.name, event?.target?.value);
    setParams((prev) => ({
      ...prev,
      [event?.target?.name]: event?.target?.value,
      page: 1,
      limit: 10,
    }));
  };

  const handleChangePage = (event, newPage) => {
    setParams((prev) => ({
      ...prev,
      page: newPage + 1,
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setParams((prev) => ({
      ...prev,
      limit: event.target.value,
      page: 1,
    }));
  };

  const handleSearch = (value) => {
    setSearchString(value);
  };

  const handleFavProject = async (project) => {
    try {
      const response = await axiosInstance.put(
        API.staredProject(project?.id, currentUser?.id, project?.starred),
      );
      if (response?.status === 200) {
        showAlert(response.data?.message);
        getAllProjectList();
      } else {
        showAlert(response.data?.message);
        getAllProjectList();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Box width="100%" bgcolor="white" display={"flex"} flexDirection="column">
        <Box
          px={2}
          py={1}
          borderBottom="1px solid #E0E0E0"
          bgcolor="white"
          position="sticky"
          top={0}
          zIndex={1}
        >
          <Typography
            variant="h2"
            color="secondary"
            fontSize={18}
            fontWeight={600}
            sx={{ lineHeight: "27px", letterSpacing: "0.15px" }}
          >
            Projects
          </Typography>
        </Box>
        <form style={{ overflow: 'auto', flex: '1', display: 'flex', }}>
          <Box
            p={{ xs: 2, lg: 3, xl: 4 }}
            py={{ xs: 3, lg: 3, xl: 4 }}
            sx={{ background: "#ffffff" }}
            flex={1}
            overflow="auto"
            display={"flex"}
            flexDirection={"column"}
          >
            <Box
              display={"flex"}
              alignItems={"flex-end"}
              justifyContent={"space-between"}
              mb={2}
            >
              <Box display={"flex"} gap={2}>
                <Box maxWidth={"230px"} sx={{ flex: "0 0 230px", '> div': { width: '100%' }, }}>
                  <SearchBoxInput
                    label="Search"
                    id="search-project"
                    variant="standard"
                    placeholder="Project Name"
                    handleSearch={handleSearch}
                    value={searchString}
                    isUseDebounce={false}
                  />
                </Box>
                <Box maxWidth={"180px"} sx={{ flex: "0 0 180px", paddingTop: '3px' }}>
                  <FormControl size="small" variant="standard" fullWidth>
                    <InputLabel htmlFor={getInputId("status", 0)}>Status</InputLabel>
                    <Select
                      id="status"
                      value={status || ""}
                      name="status"
                      onChange={(e) => handleFilterChange(e)}
                      disabled={isLoading?.pageLoading}
                      inputProps={{ id: getInputId("status", 0) }}
                    >
                      {Object.entries(PROJECT_STATUS_LIST)?.map((option) => (
                        <MenuItem key={option[0]} value={option[1]}>
                          {option[0]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

              </Box>
              {isProjectEditAccess(currentUser) ? <Button
                variant="contained"
                onClick={() => navigate(URLS.CreateProject)}
                color="primary"
                size="medium"
                sx={{ textTransform: "none", boxShadow: 'none' }}
              >
                Add Project
              </Button> : null}
            </Box>
            <Paper
              sx={{
                border: "1px solid #E0E0E0",
                borderRadius: "3px",
                boxShadow: "none",
                // maxHeight: "100%", 
              }}
            >
              <TableContainer>
                <Table>
                  <TableHead
                    sx={{
                      'th': {
                        color: "dark.800",
                        fontWeight: 500,
                        fontSize: 14,
                        lineHeight: "24px",
                        letterSpacing: "0.17px",
                        padding: "5px 16px",
                        textAlign: "left",
                        background: "rgba(245, 248, 250, 1)",
                      },
                    }}
                  >
                    <TableRow>
                      <TableCell sx={{ minWidth: "300px" }}>Project</TableCell>
                      <TableCell sx={{ minWidth: "130px" }}>Type</TableCell>
                      <TableCell sx={{ minWidth: "140px" }}>Team</TableCell>
                      <TableCell sx={{ minWidth: "120px" }}>Start date</TableCell>
                      <TableCell
                        sx={{
                          width: "150px",
                          maxWidth: "150px",
                          minWidth: "150px",
                        }}
                      >
                        Status
                      </TableCell>
                      {/* <TableCell
                      sx={{
                        width: "160px",
                        maxWidth: "160px",
                        minWidth: "160px",
                      }}
                    >
                      Spent Hours
                    </TableCell> */}
                      <TableCell
                        sx={{ width: "50px", maxWidth: "50px", minWidth: "50px" }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      "tr:hover .fav-project": {
                        opacity: 1,
                      },
                    }}
                  >
                    {isLoading?.pageLoading ? (
                      <CircularLoader variant="table" cols={6} rows={10} />
                    ) : projectList?.length ? (
                      projectList?.map((listData, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            td: {
                              color: "dark.800",
                              fontWeight: 400,
                              fontSize: 14,
                              lineHeight: "20px",
                              letterSpacing: "0.17px",
                              paddingTop: "12px",
                              paddingBottom: "12px",
                              textAlign: "left",
                              "&:first-of-type": {
                                paddingLeft: "6px",
                              },
                              "&:last-child": {
                                paddingLeft: "0px",
                                paddingRight: "0px",
                              },
                              "&:hover": {
                                cursor: 'pointer'
                              }
                            },
                          }}
                        >
                          <TableCell onClick={() => navigate(`${URLS.ProjectDetails}/${listData?.id}`)}>
                            <Box display={"flex"} alignItems={"center"} gap={1}>
                              <IconButton
                                color="secondary"
                                size="small"
                                className="fav-project"
                                sx={{
                                  padding: "4px !important",
                                  transition: "all 0.25s ease-in-out",
                                  opacity: listData?.starred ? 1 : 0,
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleFavProject(listData);
                                }}
                              >
                                {listData?.starred ? ICONS.StarFillIcon : ICONS.StarLineIcon}
                              </IconButton>
                              <Box
                                bgcolor={"#0063BF"}
                                height={36}
                                minWidth={36}
                                borderRadius={"6px"}
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                sx={{ padding: "6px 8px" }}
                              >
                                <Typography
                                  color={"white"}
                                  fontSize={13}
                                  lineHeight={"20px"}
                                  fontWeight={600}
                                >
                                  {listData?.short_code}
                                </Typography>
                              </Box>
                              <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                                <Typography
                                  variant="body1"
                                  color="dark.800"
                                  fontSize={14}
                                  lineHeight={"20px"}
                                  fontWeight={500}
                                  letterSpacing={"0.17px"}
                                >
                                  {listData?.title}
                                </Typography>
                                {!listData?.is_private ?
                                  <Chip icon={ICONS.Users} label="Public" color="secondary" variant="outlined" size="small"
                                    sx={{ minWidth: '74px', fontSize: 11, 'svg': { marginLeft: '5px !important', color: '#243D53', opacity: '0.7' } }}
                                  /> : null}
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Chip
                              label={titleCase(listData?.type)}
                              color="secondary"
                              size="small"
                              variant="outlined"
                            />
                          </TableCell>
                          <TableCell>
                            <AvatarGroup
                              sx={{
                                flexDirection: "row",
                                "> div": {
                                  width: "28px",
                                  height: "28px",
                                  fontSize: "11px",
                                  fontWeight: 600,
                                  "&:last-child": {
                                    marginLeft: '-10px !important'
                                  },
                                  "&:nth-of-type(5)": {
                                    marginLeft: "-32px !important",
                                  },
                                  "&:first-of-type": {
                                    marginLeft: "0px !important",
                                  },
                                },
                              }}
                            >
                              {listData?.project_members?.length > 4 ? (
                                <HtmlTooltip arrow
                                  title={
                                    <React.Fragment>
                                      <List
                                        sx={{
                                          padding: '0px',
                                          '.css-tlelie-MuiListItemText-root': {
                                            margin: '0',
                                          },
                                          'span': {
                                            color: 'white',
                                            fontWeight: '500',
                                            fontSize: '14px',
                                            lineHeight: '21px'
                                          },
                                          'li': {
                                            padding: '0px',
                                            marginBottom: '10px',
                                            '&:last-child': {
                                              marginBottom: '0px',
                                            }
                                          }
                                        }}>
                                        {
                                          sortArrayByKey(listData?.project_members, "asc", "id")
                                            ?.slice(3)
                                            ?.map((memberData, index) => (
                                              <ListItem key={index}>
                                                <BackgroundLetterAvatars
                                                  isShowTooltip={true}
                                                  key={memberData?.User?.id}
                                                  user={memberData?.User}
                                                  sx={{
                                                    width: "24px",
                                                    height: "24px",
                                                    fontSize: "100%",
                                                    marginRight: '9px',
                                                  }}
                                                />
                                                <ListItemText primary={titleCase(memberData?.User?.name)} />
                                              </ListItem>
                                            ))
                                        }
                                      </List>
                                    </React.Fragment>
                                  }
                                >
                                  <Avatar id={getInputId('userAvtar')} sx={{ marginLeft: "-32px !important", background: "transparent" }}>
                                    <Typography
                                      height={"100%"}
                                      width={"100%"}
                                      fontSize={11}
                                      fontWeight={600}
                                      display={"flex"}
                                      alignItems={"center"}
                                      justifyContent={"center"}
                                      sx={{ background: "rgba(0,0,0,0.55)" }}
                                    >
                                      +{listData?.project_members?.length - 4}
                                    </Typography>
                                  </Avatar>
                                </HtmlTooltip>
                              ) : null}
                              {listData?.project_members?.length ? (listData?.project_members?.length > 4
                                ? sortArrayByKey(listData?.project_members, "asc", "id")
                                  ?.slice(0, 4)?.reverse()
                                  ?.map((memberData, index) => (
                                    <BackgroundLetterAvatars
                                      id={getInputId('userAvtar')}
                                      isShowTooltip={true}
                                      key={`${memberData?.User?.id}-${index}-index`}
                                      user={memberData?.User}
                                      sx={{
                                        width: "32px",
                                        height: "32px",
                                        fontSize: "90%",
                                      }}
                                    />
                                  ))
                                : sortArrayByKey(listData?.project_members, "asc", "id")?.map(
                                  (memberData) => (
                                    <BackgroundLetterAvatars
                                      id={getInputId('userAvtar')}
                                      isShowTooltip={true}
                                      key={`${memberData?.User?.id}-${index}`}
                                      user={memberData?.User}
                                      sx={{
                                        width: "32px",
                                        height: "32px",
                                        fontSize: "90%",
                                      }}
                                    />
                                  )
                                )
                              ) : (
                                <Typography
                                  height={"100%"}
                                  width={"100%"}
                                  fontSize={12}
                                  fontWeight={700}
                                  display={"flex"}
                                // alignItems={"center"}
                                // justifyContent={"center"}
                                >
                                  -
                                </Typography>
                              )}
                            </AvatarGroup>
                          </TableCell>
                          <TableCell>
                            {listData?.start_date ? getFormattedDate(listData?.start_date) : '-'}
                          </TableCell>
                          <TableCell>
                            {/* {listData?.is_private ? "Private" : "Public"} */}
                            {titleCase(getKeyByValue(listData?.status, PROJECT_STATUS))}
                          </TableCell>
                          {/* <TableCell>
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              gap={"10px"}
                            >
                              <Box
                                sx={{
                                  position: "relative",
                                  display: "inline-flex",
                                }}
                              >
                                <CircularProgress
                                  variant="determinate"
                                  color="success"
                                  value={70}
                                />
                                <Box
                                  sx={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: "absolute",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    fontSize={11}
                                    fontWeight={500}
                                    sx={{ color: "text.primary" }}
                                  >{`${70}%`}</Typography>
                                </Box>
                              </Box>
                              <Typography
                                fontWeight={400}
                                fontSize={11}
                                lineHeight={"16px"}
                                letterSpacing={"0.17px"}
                              >
                                <Typography
                                  fontWeight={700}
                                  fontSize={13}
                                  lineHeight={"16px"}
                                  letterSpacing={"0.17px"}
                                  display={"inline-block"}
                                >
                                  150
                                </Typography>
                                /200
                              </Typography>
                            </Box>
                          </TableCell> */}
                          <TableCell>
                            <div>
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? "long-menu" : undefined}
                                aria-expanded={open ? "true" : undefined}
                                aria-haspopup="true"
                                onClick={handleClick(listData)}
                              >
                                {ICONS.DotsHorizontal}
                              </IconButton>
                              <Menu
                                id="long-menu"
                                MenuListProps={{
                                  "aria-labelledby": "long-button",
                                }}
                                anchorEl={anchorEl}
                                open={open && openElem?.id === listData?.id}
                                onClose={handleClose}
                                slotProps={{
                                  paper: {
                                    style: {
                                      width: "20ch",
                                    },
                                  },
                                }}
                              >
                                <MenuItem
                                  onClick={() =>
                                    handleClickEdit(listData, "details")
                                  }
                                >
                                  {isProjectEditAccess(currentUser) ? "Edit" : 'View'}
                                </MenuItem>
                                {isProjectEditAccess(currentUser) ?
                                  <>
                                    <MenuItem
                                      onClick={() =>
                                        handleClickEdit(listData, "members")
                                      }
                                    >
                                      Assign Member
                                    </MenuItem>
                                    <Divider component={"div"} />
                                    <MenuItem
                                      onClick={() =>
                                        handleMoveProject(
                                          listData,
                                          PROJECT_STATUS_LIST["On Hold"]
                                        )
                                      }
                                    >
                                      Move to On Hold
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() =>
                                        handleMoveProject(
                                          listData,
                                          PROJECT_STATUS_LIST["Stopped"]
                                        )
                                      }
                                    >
                                      Move to Stop
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() =>
                                        handleMoveProject(
                                          listData,
                                          PROJECT_STATUS_LIST["Completed"]
                                        )
                                      }
                                    >
                                      Move to Completed
                                    </MenuItem>
                                  </>
                                  : null}
                              </Menu>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={6}
                          align="center"
                          sx={{ padding: "12px 16px;" }}
                        >
                          <Typography
                            color={"secondary"}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            fontSize={13}
                            sx={{ opacity: "0.5", height: "200px" }}
                          >
                            No project found.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                count={projectDataCount || 0}
                rowsPerPage={params?.limit}
                page={params?.page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </form>
        {/* <EmptyPage
                  onClick={() => navigate(URLS.CreateProject)}
                  buttontext="Create Project"
                  title="No projects found."
                  buttonColor="secondary"
              /> */}
      </Box>

    </>
  );
};

export default Projects;