import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'
import ICONS from '../../../../constants/icons'
import { base64ToBlob, titleCase } from '../../../../utils';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { saveAs } from 'file-saver';



const AttachmentLists = ({ attachments }) => {
    const thumbsContainer = {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: 16,
        padding: "16px 16px 6px",
        backgroundColor: "#F7F7F7",
        borderRadius: 3,
        width: '100%',
    };

    const img = {
        display: "block",
        width: "auto",
        height: "100%",
        "WebkitUserDrag": "none",
    };

    const thumbInner = {
        display: "flex",
        minWidth: 0,
        overflow: "hidden",
        flex: "0 0 55px",
        maxWidth: 55,
        height: 55,
        margin: " 0 auto 10px",
    };

    const downloadSingleImage = async (url, fileName) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            saveAs(blob, fileName);
        } catch (error) {
            console.error('Error downloading image:', error);
        }
    };

    const thumbs = attachments?.map((file, index) => (
        <Box
            key={index}
            sx={{
                display: "inline-flex",
                flexDirection: "column",
                borderRadius: 2,
                backgroundColor: "#ffffff",
                marginBottom: "10px",
                marginRight: "10px",
                width: "112px",
                padding: "15px 15px !important",
                position: "relative",
                "&:hover": {
                    ".MuiIconButton-colorError": {
                        opacity: "1",
                    },
                    ".download-btn":{
                        opacity: "1",
                    }
                },
            }}
        >
            <div style={thumbInner}>
                {file?.name?.toLowerCase()?.includes(".pdf") ?
                    <Box
                        style={{
                            ...img, cursor: null,
                        }}
                    >
                        {ICONS.PDFIcon}
                    </Box>
                    :
                    <img
                        alt="attachment"
                        src={file?.base64 ? URL.createObjectURL(base64ToBlob(file?.base64, file?.type)) : file?.preview}
                        style={{
                            ...img, cursor: null,
                        }}
                    />}
            </div>
            {file?.isExist ?
                <Box flex={1}>
                    <IconButton
                        aria-label="download"
                        color="primary"
                        className='download-btn'
                        p={0}
                        sx={{
                            borderRadius: "0px",
                            position: "absolute",
                            top: 1,
                            right: 1,
                            width: "20px",
                            height: "20px",
                            opacity: { xs: 0 },
                            padding: "0px !important",
                        }}
                        onClick={() => downloadSingleImage(file?.preview, file?.name)}
                    >
                        <FileDownloadIcon fontSize="20px" />
                    </IconButton>
                </Box> : null}
            <Typography
                color="dark.800"
                fontSize={12}
                fontWeight={500}
                sx={{
                    lineHeight: "16px",
                    letterSpacing: "0.17",
                    width: "100%",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                }}
            >
                {titleCase(file?.name)}
            </Typography>
        </Box>
    ));

    return (
        thumbs?.length ? <aside style={thumbsContainer}>{thumbs}</aside> : null
    )
}

export default AttachmentLists;












// import { Box, IconButton, Typography } from '@mui/material'
// import React from 'react'
// import ICONS from '../../../../constants/icons'
// import { base64ToBlob, formatFileSize } from '../../../../utils';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import { saveAs } from 'file-saver';

// const img = {
//     display: "block",
//     width: "auto",
//     height: "100%",
//     "WebkitUserDrag": "none",
// };

// const AttachmentLists = ({ attachments }) => {
//     const downloadSingleImage = async (url, fileName) => {
//         try {
//             const response = await fetch(url);
//             const blob = await response.blob();
//             saveAs(blob, fileName);
//         } catch (error) {
//             console.error('Error downloading image:', error);
//         }
//     };

//     return (
//         attachments?.map((file, index) => (
//             <Box key={index} border={"1px solid #D3D7DE"} width={170} minWidth={170} gap={1} p={"6px"} borderRadius={"5px"} display={"flex"} alignItems={"center"} overflow={"hidden"}>
//                 <Box minWidth={30} width={30} height={30}>
//                     {file?.name?.toLowerCase()?.includes(".pdf") ?
//                         <Box
//                             style={{
//                                 ...img, cursor: null,
//                             }}
//                         >
//                             {ICONS.PDFIcon}
//                         </Box>
//                         :
//                         <img
//                             alt="attachment"
//                             src={file?.base64 ? URL.createObjectURL(base64ToBlob(file?.base64, file?.type)) : file?.preview}
//                             style={{
//                                 ...img, cursor: null,
//                             }}
//                         />}
//                 </Box>
//                 <Box flex={1} overflow={"hidden"}>
//                     <Typography color={"dark.800"} fontSize={12} fontWeight={500} lineHeight={"16px"} letterSpacing={"0.17px"} mb={"1px"} textOverflow={"ellipsis"} overflow={"hidden"}>
//                         {file?.name?.length > 16 ? `${file?.name?.split('.')?.[0]?.slice(0, 12)}...${file?.name?.split('.')?.[1]}` : file?.name}
//                     </Typography>
//                     {/* <Typography color={"dark.600"} fontSize={10} fontWeight={400} lineHeight={"12px"} letterSpacing={"0.17px"}>{formatFileSize(file?.size)}</Typography> */}
//                 </Box>
//                 {file?.isExist ? <Box flex={1}>
//                     <IconButton
//                         aria-label="download"
//                         color="primary"
//                         p={0}
//                         sx={{
//                             borderRadius: "0px",
//                             // position: "absolute",
//                             top: 1,
//                             right: 1,
//                             width: "20px",
//                             height: "20px",
//                             opacity: { xs: 1, lg: '0' },
//                             padding: "0px !important",
//                         }}
//                         onClick={() => downloadSingleImage(file?.preview, file?.name)}
//                     >
//                         <FileDownloadIcon fontSize="20px" />
//                     </IconButton>
//                 </Box> : null}
//             </Box>
//         ))
//     )
// }

// export default AttachmentLists
