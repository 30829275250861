/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Box, Divider, Drawer, FormControlLabel, Grid, AppBar as MuiAppBar, Tab, Tabs } from "@mui/material";
import { useNavigate } from "react-router-dom";
import URLS from "../../routes/urls";
import { clearAllCookies, getFullName } from "../../utils";
import ICONS from "../../constants/icons";
import { ADMIN_MENU, CLIENT_MENU, keysWithActualValues, ON_BODING_MENU } from "../../constants/default-values";
import SideBar from "../SideBar";
import API from "../../axios/api";
import axiosInstance from "../../axios";
import { useAlert } from "../../hook/useAlert";
import LogoutModal from "../LogoutModal";
import moment from "moment";
import BackgroundLetterAvatars from "../BackgroundLetterAvatars";
import { useDispatch, useSelector } from "react-redux";
import NotificationPopup from "../NotificationPopup";
// import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Switch from "../Switch";
import { styled } from "@mui/styles";
import secureLocalStorage from "react-secure-storage";
import { createDefaultObject, validateLogoutModelData } from "../LogoutModal/utils-functions";
import { setNightModeDetails } from "../../redux/slices/nightModeSlice";
import QuickActions from "../../pages/Client/Dashboard/QuickActions";
import { clearActionData } from "../../redux/slices/activityActionDataSlice";
import { clearStorage } from "../../redux/slices/userSlice";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "16px",
    borderRadius: "5px",
    background: "#444444",
  },
}));

const AppBar = (props) => {
  const { appBarType, handleDrawerToggle, isOpen, setIsApply, isHideSideBar, isShowSideBar } = props;
  const isSuperAdminAppBar = appBarType === "super-admin";
  const isAdminAppBar = appBarType === "administrator";
  const isOnboardingStart = ["on-borading", "incomplete-onboard-process"].filter(element => window.location.pathname.split("/").includes(element)).length;
  const showAlert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user: currentUser } = useSelector((state) => state.user);
  const isReportee = secureLocalStorage.getItem("isReportee");
  const nightMode = useSelector((state) => state.nightMode);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const initialModalDetails = {
    isShow: false,
    isButtonLoading: false,
    loadingLogoutTime: false,
    totalWorking: {
      show: false,
      working_hrs: "00:00:00",
    },
  };
  const [modalDetails, setModalDetails] = useState(initialModalDetails);
  let initialModalValues = {
    isShow: false,
    isButtonLoading: false,
    date: null,
    msg: null,
    time: null,
    email: null,
  }
  const [logoutModelDetails, setLogoutModelDetails] = useState(initialModalValues);

  const [errors, setErrors] = useState({});
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  let isLastDayTaskRemaining = !!secureLocalStorage.getItem('isLastDayTaskRemain');

  const getLogoutTime = async () => {
    try {
      setModalDetails((prev) => ({ ...prev, loadingLogoutTime: true, }));
      const response = await axiosInstance.get(API.getNightMode);
      if (response.status === 200) {
        let totalWorking = response?.data?.totalWorking;
        let ask_login_details = totalWorking?.ask_login_details;
        if (response?.data?.data?.eod && currentUser?.eod) {
          if (ask_login_details?.hasOwnProperty("last_day") || ask_login_details?.hasOwnProperty("checkout") || ask_login_details?.hasOwnProperty("current_day")) {
            const defaultObject = {
              ...createDefaultObject(ask_login_details, keysWithActualValues),
              date: ask_login_details?.date,
              time: moment(ask_login_details?.date).format("hh:mm a"),
            };
            setLogoutModelDetails({
              isShow: true,
              ...defaultObject,
              email: currentUser?.email,
              last_activity: defaultObject?.msg?.toString()?.split("from")[1]?.replace("b", "")?.replace("<>", "") || null
            });
          } else {
            navigate(URLS.ProjectTask)
          }
        } else {
          if (currentUser?.user_type !== "super_admin") {
            setModalDetails({
              loadingLogoutTime: true,
            });
            setModalDetails((prev) => ({
              ...prev,
              isShow: true,
              loadingLogoutTime: false,
              totalWorking: {
                show: totalWorking?.show || false,
                working_hrs: totalWorking?.working_hrs || "00:00:00",
              },
            }));
          }
        }
      }
      else {
        setModalDetails((prev) => ({ ...prev, loadingLogoutTime: false, }));
        showAlert(response.data.message, "error");
      }
    } catch (error) {
      setModalDetails((prev) => ({ ...prev, loadingLogoutTime: false, }));
      console.error(error);
    }
  }

  const handleCloseUserMenu = async (option) => {
    if (option.label === "Logout") {
      if (currentUser?.user_type === "super_admin") {
        handleOnApprove();
      } else {
        // setModalDetails({
        //   isShow: true,
        //   loadingLogoutTime: true,
        // });
        // if (currentUser?.user_type !== "super_admin") {
        //   getLogoutTime();
        // }
        getLogoutTime()
      }
    } else {
      if (!isOnboardingStart && option?.href) {
        navigate(option.href);
      }
    }
    setAnchorElUser(null);
  };

  const toggleDrawer = (newOpen) => () => {
    setMenuOpen(newOpen);
  };

  const DrawerList = (
    <SideBar
      currentUser={currentUser}
      setMenuOpen={setMenuOpen}
      isToggle={true}
      setIsApply={setIsApply}
      isHideSideBar={isHideSideBar}
    />
  );

  const handleOnCancel = () => {
    setModalDetails(initialModalDetails)
  }

  const handleChangeNightMode = async (value) => {
    try {
      let response = await axiosInstance.put(API.updateNightMode(nightMode?.id, value));
      if (response.status === 200) {
        showAlert(response.data.message, "success");
        let data = {
          maintain_session: value,
          id: nightMode?.id,
        };
        dispatch(setNightModeDetails(data));
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleOnApprove = async () => {
    try {
      setModalDetails((prev) => ({
        ...prev,
        isButtonLoading: true,
      }));
      const response = await axiosInstance.post(API.logout(currentUser?.id));
      if (response.status === 200) {
        setModalDetails((prev) => ({
          ...prev,
          isButtonLoading: true,
        }));
        let ask_login_details = response.data?.ask_login_details;
        if (ask_login_details?.hasOwnProperty("last_day") || ask_login_details?.hasOwnProperty("checkout") || ask_login_details?.hasOwnProperty("current_day")) {
          const defaultObject = {
            ...createDefaultObject(response.data?.ask_login_details, keysWithActualValues),
            date: response.data?.ask_login_details?.date,
            time: moment(response.data?.ask_login_details?.date).format("hh:mm a"),
          };
          setLogoutModelDetails({
            isShow: true,
            ...defaultObject,
            email: currentUser?.email,
            last_activity: defaultObject?.msg?.toString()?.split("from")[1]?.replace("b", "")?.replace("<>", "") || null
          });
        } else {
          showAlert(response?.data?.message);
          handleOnCancel();
          dispatch(clearStorage());
          clearAllCookies();
          dispatch(clearActionData())
          navigate(URLS.Login, {
            replace: true,
          });
        }
      } else {
        showAlert(response?.data?.message);
      }
    } catch (error) {
      console.error(error)
      handleOnCancel();
    }
  }

  const handleOnSubmit = async () => {
    try {
      setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: true }))
      let payload = {
        email: logoutModelDetails?.email,
        ask_login_details: { ...logoutModelDetails },
      };
      delete payload.ask_login_details.email;
      delete payload.ask_login_details.msg;
      delete payload.ask_login_details.isShow;
      delete payload.ask_login_details.isButtonLoading;
      delete payload.ask_login_details.time;

      let errors = validateLogoutModelData(payload);
      setErrors(errors?.ask_login_details);
      if (!Object.values(errors)?.filter((value) => !!value)?.length > 0) {
        const response = await axiosInstance.post(API.addOverTime, payload);
        if (response.status === 200) {
          secureLocalStorage.clear();
          clearAllCookies();
          navigate(URLS.Login, {
            replace: true,
          });
          showAlert(response?.data?.message);
          handleOnCancel();
          setLogoutModelDetails(prev => ({
            ...prev,
            isShow: false,
            isButtonLoading: false
          }));
          dispatch(clearActionData())
        } else {
          secureLocalStorage.clear();
          clearAllCookies();
          navigate(URLS.Login, {
            replace: true,
          });
          handleOnCancel();
          dispatch(clearActionData())
        }
        setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: false }))
      } else {
        setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: false }))
      }
    }
    catch (error) {
      console.error(error)
      setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: false }))
    }
  }

  const handleTabChange = (event, newValue) => {
    navigate(newValue)
  };

  const handleGetSelectedPathForTab = (currentPath) => {
    let orgPages = [URLS.AdminDashboard, URLS.AddOrganisation, URLS.EditOrganisation];
    let modulesPages = [URLS.AdminDashboardModule, URLS.AddModule, URLS.EditModule];

    if (currentPath && orgPages.some((data) => currentPath?.includes(data))) {
      return URLS.AdminDashboard
    } else if (currentPath) {
      if (modulesPages.some((data) => currentPath?.includes(data))) {
        return URLS.AdminDashboardModule
      }
    }
  }

  return (
    <>
      <MuiAppBar
        position="fixed"
        top="0"
        sx={
          isSuperAdminAppBar
            ? {
              borderBottom: "1px solid rgba(220, 225, 234, 0.1)",
              boxShadow: "0",
              padding: "0px 0px 0px 15px",
              bgcolor: "white",
              height: "62px",
            }
            : {
              borderBottom: "1px solid #DCE1EA",
              boxShadow: "0",
              padding: "0px 0px 0px 15px",
              bgcolor: "white",
              height: "62px",
            }
        }
      >
        <Toolbar disableGutters sx={{ minHeight: "62px !important" }}>
          {isAdminAppBar ? (
            <>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{
                  mr: 0.5,
                  width: "40px",
                  height: "40px",
                  p: 0.5,
                  marginLeft: "-6px !important",
                  display: { xs: "none", lg: "flex" },
                  'svg': { display: "block" },
                  'i': {
                    transform: (isOpen ? 'rotate(0deg)' : 'rotate(180deg)'),
                  }
                }}
                disabled={!isShowSideBar}
                onClick={handleDrawerToggle}
              >
                <i
                  style={{
                    height: "20px",
                    width: "20px",
                    transition: 'all 0.25s ease-in-out',
                  }}>{ICONS.MenuIcon}</i>
              </IconButton>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{
                  mr: 0.5,
                  width: "40px",
                  height: "40px",
                  p: 0.5,
                  marginLeft: "-6px !important",
                  display: { xs: "flex", lg: "none" },
                  'svg': { display: "block" },
                  'i': {
                    transform: (isOpen ? 'rotate(0deg)' : 'rotate(180deg)'),
                  }
                }}
                disabled={!isShowSideBar}
                onClick={toggleDrawer(true)}
              >
                <i
                  style={{
                    height: "20px",
                    width: "20px",
                    transition: 'all 0.25s ease-in-out',
                  }}
                >
                  {ICONS.MenuIcon}
                </i>
              </IconButton>
              <Drawer open={menuOpen} anchor="left" onClose={toggleDrawer(false)}>
                {DrawerList}
              </Drawer>
            </>
          ) : null}
          <Box display={"flex"} alignItems={"center"} sx={{ flexGrow: 1 }}>
            <Box sx={{ display: "inline-block", marginRight: '60px', width: "135px", cursor: isOnboardingStart ? "default" : "pointer" }} onClick={() => (isOnboardingStart || isLastDayTaskRemaining) ? {} :
              navigate(isSuperAdminAppBar ? URLS.AdminDashboard : URLS.Dashboard)}>
              <i style={{ display: "inline-block", width: "100%" }}>
                {ICONS.Logo}
              </i>
            </Box>
            {isSuperAdminAppBar ?
              <Box sx={{ display: "inline-block", cursor: isOnboardingStart ? "default" : "pointer" }} >
                <Tabs
                  value={handleGetSelectedPathForTab(window.location.pathname)}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                  sx={{
                    'button': {
                      minHeight: "62px !important",
                    },
                  }}
                >
                  <Tab icon={ICONS.RemoteIcon} iconPosition="start" label={"Organisations"} value={URLS.AdminDashboard} key={0} sx={{ textTransform: "none" }} />
                  <Tab icon={ICONS.Projects} iconPosition="start" label={"Modules"} value={URLS.AdminDashboardModule} key={1} sx={{ textTransform: "none" }} />
                </Tabs>
              </Box>
              : null}
            {/* {isOnboardingStart ? (
              <Box sx={{ display: "inline-block", width: "135px" }}>
                <i style={{ display: "inline-block", width: "100%" }}>
                  {isSuperAdminAppBar ? ICONS.WhiteLogo : ICONS.Logo}
                </i>
              </Box>
            ) : (
              <Link
                style={{ display: "inline-block", width: "135px" }}
                to={isSuperAdminAppBar ? URLS.AdminDashboard : URLS.Dashboard}
              >
                <i style={{ display: "inline-block", width: "100%" }}>
                  {isSuperAdminAppBar ? ICONS.WhiteLogo : ICONS.Logo}
                </i>
              </Link>
            )} */}
          </Box>
          {(isSuperAdminAppBar || isOnboardingStart) ? null : <QuickActions isHeaderActivity={true} />}
          {isAdminAppBar ?
            <Box display="flex" alignItems="center" mr={1} borderRadius={0.5}
              sx={{
                padding: '10px 10px 10px 20px',
                '&:hover': {
                  background: 'rgba(63, 92, 118, 0.1)',
                }
              }}
            >
              <FormControlLabel control={<Switch
                color="primary"
                size="small"
                checked={nightMode?.maintain_session}
                onChange={(e) => isLastDayTaskRemaining ? {} : handleChangeNightMode(e.target.checked)}
                id={"night-mode"}
                name={"night-mode"}
              />}
                sx={{
                  mr: 0,
                  '.MuiFormControlLabel-label': {
                    lineHeight: "24px",
                    letterSpacing: "0.17px",
                    color: "dark.800",
                    fontSize: 14,
                    fontWeight: 500,
                    ml: 1
                  }
                }}
                label="Working tonight?" />
              <HtmlTooltip
                title={
                  <Typography fontSize={{ xs: 12, lg: 14 }}>Turn it ON to prevent your login session from automatically terminating at 12 midnight(IST).</Typography>
                }>
                <i
                  style={{
                    width: "18px",
                    height: "18px",
                    display: "inline-block",
                    verticalAlign: "middle",
                    marginLeft: "3px",
                    color: "#3F5C76",
                  }}
                >
                  {ICONS.Info}
                </i>
              </HtmlTooltip>
            </Box>
            : null}
          {isAdminAppBar ?
            <NotificationPopup />
            : null}


          <Box
            sx={{
              flexGrow: 0,
              height: "100%",
              marginLeft: "10px",
            }}
          >
            <Tooltip arrow title="Open settings">
              <IconButton
                onClick={(e) => isLastDayTaskRemaining ? {} : handleOpenUserMenu(e)}
                sx={{
                  borderRadius: "0px",
                  backgroundColor: isSuperAdminAppBar ? "transparent" : "",
                  height: "100%",
                  padding: '8px 15px',
                }}
              >
                <BackgroundLetterAvatars sx={{ fontSize: '70%', }} user={currentUser} />
                <Typography
                  variant="body2"
                  ml={1}
                  // color={isSuperAdminAppBar ? "white" : "text.primary"}
                  color={"text.primary"}
                >
                  {getFullName(currentUser)}
                </Typography>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Grid
                display={"flex"}
                alignItems={"center"}
                sx={{ p: 2.5, pt: 1.5, maxWidth: "300px", minWidth: "300px" }}
              >
                <BackgroundLetterAvatars
                  user={currentUser}
                  sx={{
                    maxWidth: "32px",
                    height: "32px",
                    mr: 1.5,
                    fontSize: '90%',
                  }} />
                <Box
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "100%",
                  }}
                >
                  <Typography color="secondary" fontSize={15}>
                    {getFullName(currentUser)}
                  </Typography>
                  <Typography
                    color="secondary"
                    fontSize={12}
                    sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    {currentUser?.email}
                  </Typography>
                </Box>
              </Grid>
              <Divider component={"div"} />
              {(isOnboardingStart ? ON_BODING_MENU : (isSuperAdminAppBar ? ADMIN_MENU : CLIENT_MENU?.filter(item => item.label === "Repotees" ? isReportee : true))).map(
                (setting, index) => (
                  <MenuItem
                    key={index}
                    // onClick={() => handleCloseUserMenu(setting)}
                    onClick={() => handleCloseUserMenu(setting)}
                    display="flex"
                    alignitems="center"
                    sx={{
                      py: 1.5, px: 2.5, color: "secondary.main",
                      '&:last-child': {
                        borderTop: '1px solid rgba(0, 0, 0, 0.12)'
                      }
                    }}
                  >
                    <i
                      style={{
                        flex: "0 0 18px",
                        maxWidth: "18px",
                        height: "18px",
                        marginRight: "10px",
                      }}
                    >
                      {setting.icon}
                    </i>
                    <Typography fontSize={14} color={"secondary"} fontWeight={500} lineHeight={"22px"}>{setting.label}</Typography>
                  </MenuItem>
                )
              )}
            </Menu>
          </Box>
        </Toolbar>
      </MuiAppBar>
      <LogoutModal
        actionType="logout"
        onApprove={handleOnApprove}
        onCancel={handleOnCancel}
        isShow={modalDetails?.isShow}
        title="Logout for the day!"
        isButtonLoading={modalDetails?.isButtonLoading}
        isShowCancelButton={true}
        loadingLogoutTime={modalDetails?.loadingLogoutTime}
        totalWorking={modalDetails?.totalWorking}
      />
      <LogoutModal
        actionType={"ask_login_details"}
        onApprove={handleOnSubmit}
        isShow={logoutModelDetails.isShow}
        isButtonLoading={logoutModelDetails.isButtonLoading}
        title={`Logout time for ${moment(logoutModelDetails?.date).format("DD/MM/YYYY (dddd)")}`}
        isShowCancelButton={false}
        errors={errors}
        setErrors={setErrors}
        setLogoutModelDetails={setLogoutModelDetails}
        logoutModelDetails={logoutModelDetails}
      />
    </>
  )
};

export default AppBar;