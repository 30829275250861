/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { HOLIDAYS } from "../../../../constants/default-values";
import useAuthentication from "../../../../hook/useAuthentication";
import API from "../../../../axios/api";
import axiosInstance from "../../../../axios";
import CircularLoader from "../../../../components/CircularLoader";
import { useNavigate } from "react-router-dom";
import URLS from "../../../../routes/urls";
import { getFormattedDate, getYearObject, sortArrayByKey } from "../../../../utils";
import { usePageTitle } from "../../../../hook/useTitle";
import { useAlert } from "../../../../hook/useAlert";
import TablePagination from "../../../../components/TablePagination";
import moment from "moment";
import Input from "../../../../components/Input";
import ICONS from "../../../../constants/icons";
import HolidayList from "./holidayList";
import { styled } from "@mui/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(0),
    overflowX: 'hidden',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(0),
  },
}));

const AdministratorHolidays = () => {
  const { getCurrentUser } = useAuthentication();
  const { setPageTitle } = usePageTitle();
  const currentUser = getCurrentUser();
  const showAlert = useAlert();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isLoading, setIsLoading] = useState({
    pageLoading: true,
    formSubmitting: false,
    buttonLoading: false,
  });
  const [yearsData, setYearsData] = useState({
    result: [],
    renewal_month: "",
  });
  const [optionalYearsData, setOptionalYearsData] = useState({
    result: [],
    renewal_month: "",
    next_year: "",
    allowed_create_year: false,
  });
  const [editableYear, setEditableYear] = useState({
    id: null,
    year: "",
  });
  const [errors, setErrors] = useState({});

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const open = Boolean(anchorEl);

  const [isHolidayModalOpen, setIsHolidayModalOpen] = useState(false);
  const [holidayModalData, setHolidayModalData] = useState({});

  setPageTitle("Holidays");

  useEffect(() => {
    getAllYearData();
  }, []);

  useEffect(() => {
    getDataAccordingPagination(optionalYearsData);
  }, [page, rowsPerPage]);

  const getDataAccordingPagination = (optionalYearListData) => {
    if (optionalYearListData) {
      let dataToPaginate = [...optionalYearListData?.result];

      if (dataToPaginate && dataToPaginate.length) {
        const startIdx = page * rowsPerPage;
        const endIdx = Math.min(startIdx + rowsPerPage, dataToPaginate?.length);
        const updatedPageData = dataToPaginate.slice(startIdx, endIdx);
        setYearsData({ ...optionalYearListData, result: updatedPageData });
      } else {
        setYearsData({ ...optionalYearListData, result: [] });
      }
    }
  };

  const getAllYearData = async () => {
    try {
      const config = {
        params: {
          renewalMonth: true
        },
      };
      const response = await axiosInstance.get(API.getHolidayYears(currentUser?.organization_id), config);
      if (response.status === 200) {
        let sortedYearsData = sortArrayByKey(response?.data?.result, 'asc', 'year')
        getDataAccordingPagination({
          result: sortedYearsData,
          renewal_month: response?.data?.renewal_month,
          allowed_create_year: response?.data?.allowed_create_year,
          next_year: response?.data?.next_year,
        })
        setOptionalYearsData({
          result: sortedYearsData,
          renewal_month: response?.data?.renewal_month,
          allowed_create_year: response?.data?.allowed_create_year,
          next_year: response?.data?.next_year,
        });
        setIsLoading((prev) => ({ ...prev, pageLoading: false }));
      } else {
        setIsLoading((prev) => ({ ...prev, pageLoading: false }));
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, pageLoading: false }));
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (e) => {
    setEditableYear((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setErrors({ year: "" });
  };

  const handleCreateYear = async (updateData) => {
    try {
      setIsLoading((prev) => ({ ...prev, buttonLoading: true }));
      const response = await axiosInstance.post(API.createOrgYear(currentUser?.organization_id), { start_year: updateData });
      if (response.status === 200) {
        showAlert(response.data.message);
        getAllYearData();
        setIsLoading((prev) => ({ ...prev, buttonLoading: false }));
      } else {
        setIsLoading((prev) => ({ ...prev, buttonLoading: false }));
        showAlert(response.data.message);
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, buttonLoading: true }));
    }
  }

  const getNextYear = () => {
    let orgLastYear = sortArrayByKey(yearsData?.result, 'desc', 'year')?.[0]?.year;
    return moment(`${orgLastYear}`, 'YYYY').add(1, 'year').format("YYYY");
  }

  const handleClickOpenMenu = (event, index) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuIndex(index)
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenMenuIndex(null)
  };

  const handleClickEditYear = (yearId) => {
    navigate(`${URLS.AdministratorHolidaysCreate}?selectedYearId=${yearId}`);
  }

  const handleClickOpenHolidayModal = (yearDetails, key) => {
    setIsHolidayModalOpen(true)
    setHolidayModalData({
      yearData: yearDetails,
      holidayType: key
    })
  }

  const handleHolidayModalClose = (isDelete = false) => {
    setIsHolidayModalOpen(false)
    setHolidayModalData({})
    if (isDelete) getAllYearData();
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - (yearsData?.result?.length || 0)) : 0;

  return isLoading.pageLoading ? (
    <CircularLoader />
  ) : (
    <>
      <Box display="flex" flexDirection="column" sx={{ width: "100%" }} p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} overflow="auto">
        <Grid container mb={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
          <Grid item>
            {yearsData?.result?.length || 0} years
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="medium"
              fontWeight="500"
              sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
              onClick={() => handleCreateYear(getNextYear())}
              isButtonLoading={isLoading?.buttonLoading}
              disabled={!yearsData?.allowed_create_year}>
              Create Year {yearsData?.next_year}
            </Button>
          </Grid>
        </Grid>
        <Paper
          sx={{
            width: "100%",
            mb: 2,
            boxShadow: "none",
            border: "1px solid #E0E0E0",
            borderRadius: "3px",
          }}
        >
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHead>
                <TableRow
                  sx={{
                    th: {
                      color: "rgba(0, 0, 0, 0.87)",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "24px",
                      letterSpacing: "0.17px",
                      padding: "6px 16px",
                      background: "#F7F7F7",
                      verticalAlign: "top",
                    },
                  }}
                >
                  <TableCell>Years</TableCell>
                  <TableCell>Star Date - End Date</TableCell>
                  <TableCell>
                    Office Holidays
                    <Typography color={'primary'} fontSize={14} fontWeight={500} lineHeight={'24px'} letterSpacing={'0.17px'} sx={{ textDecoration: 'underline', textDecorationStyle: 'dotted' }}># Saturday, # Sunday</Typography>
                  </TableCell>
                  <TableCell>
                    Festivals
                    <Typography color={'primary'} fontSize={14} fontWeight={500} lineHeight={'24px'} letterSpacing={'0.17px'} sx={{ textDecoration: 'underline', textDecorationStyle: 'dotted' }}># Days</Typography>
                  </TableCell>
                  <TableCell sx={{ width: 120, maxWidth: 120, minWidth: 120, textAlign: 'center !important' }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {yearsData?.result?.length > 0 ? (
                  yearsData?.result?.map((row, index) => {
                    return (
                      <React.Fragment key={index}>
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          sx={{
                            td: {
                              color: "rgba(0, 0, 0, 0.87)",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "20px",
                              letterSpacing: "0.17px",
                              padding: "6px 16px",
                            },
                          }}
                        >
                          <TableCell align="left">
                            <Box sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              gap: "10px",
                            }}>
                              {row?.id === editableYear?.id ? (
                                <Input
                                  id="year"
                                  name="year"
                                  variant="standard"
                                  type="text"
                                  onChange={(e) => handleChange(e)}
                                  value={editableYear.year}
                                  error={!!errors["year"]}
                                  helperText={errors["year"]}
                                  sx={{ width: "300px" }}
                                />
                              ) : (
                                getYearObject(row)?.yearDashedFormat
                              )}
                              {row?.active ? <Box sx={{
                                width: "8px",
                                height: "8px",
                                borderRadius: "50px",
                                backgroundColor: "primary.main"
                              }}> </Box> : null}
                              <span></span>
                            </Box>
                          </TableCell>
                          <TableCell align="left">
                            {getFormattedDate(row?.setting_start_date)} - {getFormattedDate(row?.setting_end_date)}
                          </TableCell>
                          <TableCell align="left" sx={{ cursor: 'pointer' }} onClick={() => handleClickOpenHolidayModal(row, Object.keys(HOLIDAYS)[0])}>
                            {row?.saturdayHolidays ? row?.saturdayHolidays : 0}{", "}
                            {row?.sundayHolidays ? row?.sundayHolidays : 0}
                          </TableCell>
                          <TableCell align="left" sx={{ cursor: 'pointer' }} onClick={() => handleClickOpenHolidayModal(row, Object.keys(HOLIDAYS)[1])}>
                            {row?.sundayHolidays ? row?.festivalHolidays : 0}
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center !important' }}>
                            <IconButton
                              id="basic-button"
                              aria-controls={open ? 'basic-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? 'true' : undefined}
                              onClick={(e) => handleClickOpenMenu(e, row?.id)}
                              sx={{
                                height: "30px",
                                width: "30px",
                                padding: "0px",
                                borderRadius: "4px",
                              }}
                            >
                              <i
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  display: "flex",
                                }}
                              >
                                {ICONS.DotsHorizontal}
                              </i>
                            </IconButton>
                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={open && openMenuIndex === row?.id}
                              onClose={handleCloseMenu}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button',
                              }}
                            >
                              <MenuItem
                                onClick={() => handleClickEditYear(row?.id)}
                              >
                                Edit {getYearObject(row)?.yearDashedFormat} Holidays
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })
                ) : (
                  emptyRows >= 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} >
                        No years found.
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            count={optionalYearsData?.result?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        <BootstrapDialog
          open={isHolidayModalOpen}
          onClose={() => handleHolidayModalClose()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            alignItems: 'flex-start',
            '.MuiDialog-paper': {
              maxWidth: '100%',
              width: '1200px',
              ' > div': {
                overflow: 'hidden',
                display: 'flex',
                '> div': {
                  display: 'flex',
                }
              },
            },
            '.MuiDialog-container': { alignItems: 'flex-start' }
          }}
        >
          <DialogContent sx={{ width: '100%' }}>
            <Box sx={{ width: '100%' }}>
              <HolidayList
                handleHolidayModalClose={(isDelete) => handleHolidayModalClose(isDelete)}
                years={yearsData?.result}
                currentUser={currentUser}
                holidaySelectYearData={holidayModalData?.yearData}
                holidayKey={holidayModalData?.holidayType} />
            </Box>
          </DialogContent>
        </BootstrapDialog>
      </Box>
    </>
  );
};

export default AdministratorHolidays;