import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import BackgroundLetterAvatars from '../../../../components/BackgroundLetterAvatars';
import { formatTimeAgo } from '../../../../utils';
import EmptyPage from '../../../../components/EmptyPage';

const TaskActivity = ({ activityData, usersList, taskData }) => {
    return (
        <Box>
            {/* <Box mb={2}>
                <Box display={"flex"} gap={1} pb={2}>
                    <Box minWidth={34} width={34} height={34} p={"3px"}>
                        <Box minWidth={28} width={28} height={28}>
                            <Avatar alt="Remy Sharp" src={userImg} sx={{ width: 28, height: 28 }} />
                        </Box>
                    </Box>
                    <Box gap={"5px"} flex={1}>
                        <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"24px"} letterSpacing={"0.17px"}>
                            <b>Devon Lane</b> Changed state <b>Working</b> to <b>Review</b>
                        </Typography>
                        <Typography color={"rgba(0, 0, 0, 0.5)"} fontSize={12} fontWeight={500} lineHeight={"18px"} letterSpacing={"0.17px"}>04:57 PM, 20 December, 2024</Typography>
                    </Box>
                </Box>
                <Divider />
            </Box> */}
            {activityData?.length ? activityData?.map(activityDetail => (
                <Box mb={2}>
                    <Box display={"flex"} gap={1} pb={2}>
                        <Box minWidth={34} width={34} height={34} p={"3px"}>
                            <Box minWidth={28} width={28} height={28}>
                                <BackgroundLetterAvatars alt="Remy Sharp"
                                    user={activityDetail?.User}
                                    src={activityDetail?.User?.profile_img}
                                    sx={{ width: 28, height: 28 }} />
                            </Box>
                        </Box>
                        <Box gap={"5px"} flex={1}>
                            <Typography color={"dark.800"} fontSize={14} fontWeight={400} lineHeight={"24px"} letterSpacing={"0.17px"}
                                dangerouslySetInnerHTML={{ __html: activityDetail?.description }}>
                                {/* <b>Devon Lane</b> Changed state <b>Working</b> to <b>Review</b> */}
                            </Typography>
                            <Typography color={"rgba(0, 0, 0, 0.5)"} fontSize={12} fontWeight={500} lineHeight={"18px"} letterSpacing={"0.17px"} mt={"5px"}>
                                {formatTimeAgo(taskData?.updatedAt, true)}
                            </Typography>
                        </Box>
                    </Box>
                    <Divider />
                </Box>
            )) : <EmptyPage title="No activities exist." isButtonShow={false} />}
        </Box>
    )
}

export default TaskActivity