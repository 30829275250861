/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  Box,
  Collapse,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import SIDEBAR_SETTINGS from "../../constants/side-bar-settings";
import { useLocation, useNavigate } from "react-router-dom";
import ICONS from "../../constants/icons";
import URLS from "../../routes/urls";

const SideBar = ({
  isOpen,
  currentUser,
  setMenuOpen,
  isToggle,
  setIsApply,
  isHideSideBar,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleCheckSelectedPath = () => {
    let isRequestOpen = [URLS.Leaves, URLS.WorkFromHome];
    let isAdministratorOpen = [
      URLS.AdministratorOrganisation,
      URLS.AdministratorHolidays,
      URLS.AdministratorLeaves,
      URLS.AdministratorRoles,
      URLS.AdministratorProjectRoles,
      URLS.AdministratorYears,
      URLS.AdministratorWorkType,
      URLS.AdministratorHolidaysCreate,
      URLS.AdministratorActivity,
      URLS.AdministratorCreateActivity,
      URLS.AdministratorUpdateActivity,
    ];
    let isCampaignsOpen = [
      URLS.CampaignsContactList,
      URLS.EmailTemplates,
      URLS.CreateEmailTemplate,
      URLS.UpdateEmailTemplate,
      URLS.EmailTransactions,
    ]
    if (
      isRequestOpen.some((data) =>
        location.pathname.toLowerCase()?.includes(data?.toLowerCase())
      )
    ) {
      return "Request";
    } else if (
      isAdministratorOpen.some((data) =>
        location.pathname.toLowerCase()?.includes(data?.toLowerCase())
      )
    ) {
      return "Administrator";
    } else if (
      isCampaignsOpen.some((data) =>
        location.pathname.toLowerCase()?.includes(data?.toLowerCase())
      )
    ) {
      return "CRM"
    } else {
      return "";
    }
  };

  const [open, setOpen] = useState(handleCheckSelectedPath() || "");

  const handleClick = (type) => {
    if (type === open) {
      setOpen("");
    } else {
      setOpen(type);
    }
    // setMenuOpen(false);
  };

  let isAdministrator = ["hr", "administrator"].includes(
    currentUser?.user_role?.toLowerCase()
  );

  // let updatedSideBarSettings = isAdministrator
  //   ? (currentUser?.is_admin || currentUser?.user_type === 'admin') ? SIDEBAR_SETTINGS : SIDEBAR_SETTINGS?.filter(
  //     (value) =>
  //       !["Projects", "CRM"].includes(value?.name))
  //   : currentUser?.user_role === "Sales" ? SIDEBAR_SETTINGS?.filter(
  //     (value) =>
  //       !["Settings", "Users", "Reports", "Projects", "Administrator"].includes(value?.name)
  //   ) : SIDEBAR_SETTINGS?.filter(
  //     (value) =>
  //       !["Settings", "Users", "Reports", "Projects", "Administrator", "CRM"].includes(value?.name)
  //   );

  let updatedSideBarSettings = isAdministrator
    ? (currentUser?.is_admin || currentUser?.user_type === 'admin') ? SIDEBAR_SETTINGS : SIDEBAR_SETTINGS?.filter(
      (value) =>
        !["CRM"].includes(value?.name))
    : currentUser?.user_role === "Sales" ? SIDEBAR_SETTINGS?.filter(
      (value) =>
        !["Settings", "Users", "Reports", "Administrator"].includes(value?.name)
    ) : SIDEBAR_SETTINGS?.filter(
      (value) =>
        !["Settings", "Users", "Reports", "Administrator", "CRM"].includes(value?.name)
    );

  const handleClickSideItem = (href) => {
    navigate(href);
    setIsApply(false);
    if (isToggle) {
      setMenuOpen(false);
    }
    setTimeout(() => {
      setIsApply(true);
    }, 2000);
  };

  return (
    <Box
      className={`sidebar ${isHideSideBar ? "sidebar-hide" : ""}`}
      sx={{
        // width: 256,
        // minWidth: 256,
        bgcolor: "background.paper",
        transition: "all 0.25s ease-in-out",
        borderRight: "1px solid",
        borderColor: "border.main",
        overflowY: "auto",
        width: { lg: isOpen ? 256 : 60, xs: isOpen ? 256 : 256 },
        // minWidth: (isOpen ? 256 : 60)
        position: { lg: "absolute" },
        zIndex: { lg: 100 },
        background: "#ffffff",
        height: "100%",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        // overflow: { lg: "hidden" },

        ".sidebar-hide&:hover": {
          width: 256,
          boxShadow: isOpen ? "" : "10px 0px 20px rgba(0,0,0,0.25)",
          ".menu-text": {
            display: "block !important",
          },
          ".submenu-item-box": {
            display: "block !important",
          },
        },
      }}
    >
      {/* width: (isOpen ? 256 : 50) */}
      <nav aria-label="main mailbox folders" style={{ flex: 1, }}>
        <List sx={{ padding: isOpen ? "16px" : "16px 10px" }}>
          {updatedSideBarSettings?.map((listItem, index) =>
            (isAdministrator ? ["Administrator", "Request", "CRM"] : currentUser?.user_role === "Sales" ? ["Request", "CRM"] : ["Request"]).includes(
              listItem.name
            ) ? (
              <React.Fragment key={index}>
                <Grid mb={1}>
                  <ListItemButton
                    onClick={() => handleClick(listItem.name)}
                    className={
                      handleCheckSelectedPath() === listItem.name
                        ? "active"
                        : ""
                    }
                    sx={[
                      handleCheckSelectedPath() === listItem.name
                        ? {
                          bgcolor: "primary.main",
                          color: "white",
                          "&:hover": {
                            bgcolor: "primary.main",
                            color: "white",
                          },
                        }
                        : { color: "secondary.main" },
                      {
                        borderRadius: "4px",
                        padding: isOpen ? "4px 12px 5px" : "4px 10px 5px",
                        transition: "all 0.25s ease-in-out",
                        height: "38px",
                        ".MuiListItemText-primary": {
                          fontSize: "14px",
                          fontWeight: "500",
                        },
                      },
                    ]}
                  >
                    <ListItemIcon
                      sx={{
                        marginRight: "11px",
                        maxWidth: "18px",
                        height: "18px",
                        minWidth: "18px",
                        color: "inherit",
                      }}
                    >
                      {listItem.icon}
                    </ListItemIcon>
                    {/* {isOpen ? <ListItemText primary={listItem.name} /> : null } */}
                    <ListItemText
                      className="menu-text"
                      primary={listItem.name}
                      sx={{
                        display: { lg: isOpen ? "block" : "none" },
                        whiteSpace: "nowrap",
                      }}
                    />
                    <i
                      style={
                        open === listItem.name
                          ? { transform: "rotate(90deg)" }
                          : {}
                      }
                    >
                      {/* {open ? ICONS.DownArrow : ICONS.RightArrow} */}
                      {ICONS.RightArrow}
                    </i>
                  </ListItemButton>
                  <Collapse
                    in={open === listItem.name}
                    timeout="auto"
                    unmountOnExit
                    sx={{
                      padding: "8px 0px 8px 26px",
                      display: { lg: isOpen ? "block" : "none" },
                    }}
                    className="submenu-item-box"
                  >
                    <List
                      component="div"
                      disablePadding
                      sx={{
                        "> div:not(:last-child)": {
                          marginBottom: "3px",
                        },
                      }}
                    >
                      {/* {listItem.subMenu.map((subItem, index) => ( */}
                      {[
                        ...(currentUser?.is_admin || currentUser?.user_type === 'admin' ? listItem?.subMenu : listItem.subMenu?.filter(item => item?.name !== "Project Roles"))
                      ].map((subItem, index) => (
                        <ListItemButton
                          key={index}
                          // onClick={() => {navigate(subItem.href); setMenuOpen(false)}}
                          onClick={() => handleClickSideItem(subItem.href)}
                          className={
                            location?.pathname
                              ?.toLowerCase()
                              .includes(subItem.href?.toLowerCase())
                              ? "active"
                              : ""
                          }
                          sx={[
                            location?.pathname
                              ?.toLowerCase()
                              .includes(subItem.href?.toLowerCase())
                              ? {
                                color: "primary.main",
                                bgcolor: "primary.100",
                                "&:hover": {
                                  color: "primary.main",
                                  bgcolor: "primary.100",
                                },
                              }
                              : {
                                color: "secondary.main",
                                "&:hover": {
                                  color: "primary.main",
                                  bgcolor: "primary.100",
                                },
                              },
                            {
                              borderRadius: "4px",
                              padding: "2px 15px",
                              ".MuiListItemText-primary": {
                                fontSize: "14px",
                                fontWeight: "500",
                              },
                            },
                          ]}
                        >
                          {/* {isOpen ? <ListItemText primary={subItem.name} /> : null} */}
                          <ListItemText
                            className="menu-text"
                            primary={subItem.name}
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                </Grid>
              </React.Fragment>
            ) : (
              <ListItem key={index} disablePadding sx={{ marginBottom: "8px" }}>
                <ListItemButton
                  // onClick={() => {navigate(listItem.href); setMenuOpen(false)}}
                  onClick={() => handleClickSideItem(listItem.href)}
                  className={
                    listItem?.href?.startsWith(
                      location?.pathname?.split("/")[1],
                      1
                    )
                      ? "active"
                      : ""
                  }
                  sx={[
                    listItem?.href?.startsWith(
                      location?.pathname?.split("/")[1],
                      1
                    )
                      ? {
                        bgcolor: "primary.main",
                        color: "white",
                        "&:hover": {
                          bgcolor: "primary.main",
                          color: "white",
                        },
                      }
                      : { color: "secondary.main" },
                    {
                      borderRadius: "4px",
                      padding: isOpen ? "4px 12px 5px" : "4px 10px 5px",
                      height: "38px",
                      transition: "all 0.25s ease-in-out",
                      ".MuiListItemText-primary": {
                        fontSize: "14px",
                        fontWeight: "500",
                      },
                    },
                  ]}
                >
                  <ListItemIcon
                    sx={{
                      marginRight: "11px",
                      maxWidth: "18px",
                      height: "18px",
                      minWidth: "18px",
                      color: "inherit",
                    }}
                  >
                    {listItem.icon}
                  </ListItemIcon>
                  {/* {isOpen ? <ListItemText primary={listItem.name} /> : null} */}
                  <ListItemText
                    className="menu-text"
                    primary={listItem.name}
                    sx={{
                      display: { lg: isOpen ? "block" : "none" },
                      whiteSpace: "nowrap",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )
          )}
        </List>
      </nav>
      <Box padding={"16px 32px"} whiteSpace={"nowrap"} sx={{ display: isOpen ? "block" : "none" }}>
        <Typography color={"rgba(65, 65, 65, 0.7)"} fontSize={12} fontWeight={500} lineHeight={"22px"}>{`Copyright © ${new Date().getFullYear()} TechCompose`}</Typography>
      </Box>
    </Box>
  );
};

export default SideBar;
