
import React, { useState, useEffect, useCallback } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axiosInstance from '../../axios';
import API from '../../axios/api';
import { useAlert } from '../../hook/useAlert';
import { titleCase } from '../../utils';
import { Box } from '@mui/material';

function AutocompleteSuggetionWithAddOption({
    id,
    label,
    variant,
    sx,
    value,
    onChange,
    disabledItems = [],
    setSuggestionTitles,
    suggestionTitles,
}) {
    const showAlert = useAlert();
    const [inputValue, setInputValue] = useState(value?.title || '');

    useEffect(() => {
        if (!value?.title) {
            setInputValue('');
        }
    }, [value]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const body = { "title": inputValue, "isForTag": false };
                const response = await axiosInstance.post(API.findJobProfileOrTag, body);
                if (response.status === 200) {
                    setSuggestionTitles(response?.data?.data || []);
                } else {
                    setSuggestionTitles([]);
                }
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAddOption = async (newValue) => {
        try {
            const body = { "title": titleCase(newValue), "isForTag": false };
            const response = await axiosInstance.post(API.createJobProfileOrTag, body);
            if (response.status === 200) {
                setSuggestionTitles((prev) => [...prev, response?.data?.data]);
                setInputValue(body?.title);
                showAlert("Suggetion added successfully");
                return response?.data?.data;
            } else {
                showAlert('Error adding new option');
                return null
            }
        } catch (error) {
            console.error('Error adding new option', error);
            showAlert('Error adding new option');
            return null
        }
    };

    const handleChange = async (event, newValue) => {
        let updatedValue = newValue;

        if (newValue?.title?.startsWith("Add ") && newValue?.id === -1) {
            const tagTitle = newValue?.title?.trim()?.replace(/Add\s"(.+)"$/, "$1").trim();
            setInputValue(tagTitle);
            const newJobProfileObj = await handleAddOption(titleCase(tagTitle));

            updatedValue = { ...newJobProfileObj };
        } else if (newValue && newValue?.inputValue) {
            updatedValue = { title: newValue.inputValue };
        } else {
            if (newValue?.title?.length) {
                updatedValue = { ...newValue };

            } else {
                updatedValue = { title: null };
            }
        }

        if (updatedValue?.title?.length)
            setInputValue(updatedValue?.title);
        else {
            setInputValue("")
        }
        onChange && onChange(event, updatedValue);
    };

    const filteredOptions = inputValue?.trim()?.length ? suggestionTitles?.filter((option) =>
        option?.title?.trim()?.toLowerCase()?.includes(inputValue?.trim()?.toLowerCase())
    ) ?? [] : [];


    if (inputValue?.length && !suggestionTitles?.some((opt) => opt?.title?.trim()?.toLowerCase() === inputValue?.trim()?.toLowerCase())) {
        filteredOptions.push({ id: -1, title: `Add "${titleCase(inputValue)}"` });
    }

    const getOptionLabel = useCallback((option) => {
        if (typeof option === 'string') {
            return option;
        }
        if (option.inputValue) {
            return titleCase(option?.inputValue);
        }
        return option?.title;
    }, []);

    const renderOption = useCallback((props, option) => {
        const isDisabled = disabledItems?.some(item => titleCase(item?.title) === titleCase(option?.inputValue));
        const { key, ...restProps } = props;

        return (
            <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
                key={`${key}-${option}`}
            >
                <li {...restProps} key={key} style={{ opacity: isDisabled ? 0.5 : 1, pointerEvents: isDisabled ? 'none' : 'auto', width: "100%" }}>
                    <span>
                        {titleCase(option?.title ? option.title : option)}
                    </span>
                </li>
            </Box>
        );
    }, [disabledItems]);

    return (
        <React.Fragment>
            <Autocomplete
                id={id}
                value={value?.id ? value : null}
                inputValue={inputValue}
                onChange={handleChange}
                onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={filteredOptions}
                getOptionLabel={getOptionLabel}
                renderOption={renderOption}
                freeSolo
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputProps={{ ...params.inputProps, maxLength: 30 }}
                        variant={variant || "standard"}
                        label={label || "Search or add option"}
                        sx={sx}
                    />
                )}
            />
        </React.Fragment>
    );
}

export default AutocompleteSuggetionWithAddOption;
