import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, IconButton, MenuItem, Stack, Typography } from '@mui/material'
import React from 'react'
import Button from '../../../../components/Button'
import { convertDateFromFormat, generateUniqueId, getPhaseRemainingHours, getPhaseStatus, remainingHoursColor, roundToTwoDecimals, titleCase } from '../../../../utils'
import EditIcon from '@mui/icons-material/Edit';
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularLoader from '../../../../components/CircularLoader';
import { PROJECT_PHASE_STATUS } from '../../../../constants/default-values';
import Input from '../../../../components/Input';
import axiosInstance from '../../../../axios';
import API from '../../../../axios/api';
import { Delete } from '@mui/icons-material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SingleDatePicker from '../../../../components/SingleDatePicker';
import EmptyPage from '../../../../components/EmptyPage';

const PhaseDetails = ({ projectPhases, setProjectPhases, optionalProjectPhases, handleClosePhaseDrawer, getProjectPhaseHours,
    handleAddEmptyPhaseHours, isEditPhase, isEditPhaseHours, setIsEditPhaseHours, isLoading, showAlert, setIsLoading,
    projectData, params, getProjectPhaseData, setIsEditPhase, initialProjectPhase, isAddPhase,
    setIsAddPhase, editabelPhase, setEditabelPhase }) => {

    const handlePhaseHoursChange = (e, projectId, uniqueId) => {
        let data = projectPhases?.map((phase) => {
            if (phase?.id === projectId) {
                return {
                    ...phase,
                    ProjectPhaseHours: phase?.ProjectPhaseHours?.map((hour) => {
                        if (hour?.uniqueId === uniqueId) {
                            return {
                                ...hour,
                                hours: e.target.value
                            }
                        } else {
                            return hour
                        }
                    })
                }
            } else {
                return phase
            }
        });
        setProjectPhases(data);
    }

    const handleEditPhaseHours = (uniqueId) => {
        setIsEditPhaseHours(uniqueId);
    }

    const handleDeletePhaseHours = async (phaseHoursId, phaseId) => {
        try {
            setIsLoading((prev) => ({ ...prev, deleteProjectPhaseHours: true }));
            let phase = projectPhases?.find((phase) => phase?.id === phaseId);
            let hours = phase?.ProjectPhaseHours?.find((hour) => hour?.id === phaseHoursId);
            let payload = {
                "total_hours": Number.parseFloat(projectData?.total_hours),
                "hours": hours?.hours,
                "project_id": Number.parseInt(params?.projectId),
            };
            let response = await axiosInstance.put(API.deleteProjectPhaseHours(phaseHoursId), payload);
            if (response?.status === 200) {
                showAlert(response?.data?.message);
            } else {
                showAlert(response?.data?.message, "error");
            }
        } catch (error) {
            console.error(error);
        } finally {
            getProjectPhaseData();
            setIsLoading((prev) => ({ ...prev, deleteProjectPhaseHours: false }));
        }
    }

    const handleSavePhaseHours = async (phaseHoursId, phaseId) => {
        try {
            setIsLoading((prev) => ({ ...prev, editProjectPhaseHours: true }));
            let payload = {
                "project_id": Number.parseInt(params?.projectId),
                "total_hours": Number.parseFloat(projectData?.total_hours),
                "old_project_phase_hours": Number.parseFloat(optionalProjectPhases?.find((phase) => phase?.id === phaseId)?.ProjectPhaseHours?.find((hour) => hour?.id === phaseHoursId)?.hours),
                "hours": Number.parseFloat(projectPhases?.find((phase) => phase?.id === phaseId)?.ProjectPhaseHours?.find((hour) => hour?.id === phaseHoursId)?.hours)
            };
            let response = await axiosInstance.put(API.updateProjectPhaseHours(phaseHoursId), payload);
            if (response?.status === 200) {
                showAlert(response?.data?.message);
            } else {
                showAlert(response?.data?.message, "error");
            }
        } catch (error) {
            console.error(error);
        } finally {
            getProjectPhaseData();
            setIsEditPhaseHours(null);
            setIsEditPhase(null);
            setIsLoading((prev) => ({ ...prev, editProjectPhaseHours: false }));
        }
    }

    const handleCancelPhaseHours = (phaseId) => {
        let data = projectPhases?.map((phase) => {
            if (phase?.id === phaseId) {
                return {
                    ...phase,
                    ProjectPhaseHours: optionalProjectPhases?.find((phase) => phase?.id === phaseId)?.ProjectPhaseHours?.map((hour) => ({ ...hour, uniqueId: generateUniqueId() })),
                    uniqueId: generateUniqueId()
                }
            } else {
                return phase;
            }
        });
        setProjectPhases(data);
        setIsEditPhaseHours(null);
        setIsEditPhase(null);
    }

    const handleAddPhaseHours = async (phaseId, uniqueId) => {
        setIsLoading((prev) => ({ ...prev, addProjectPhaseHours: true }));
        let phase = projectPhases?.find((phase) => phase?.id === phaseId);
        let hour = phase?.ProjectPhaseHours?.find((hour) => hour?.uniqueId === uniqueId);
        let payload = {
            "phase_id": phase?.id,
            "hours": Number.parseFloat(hour?.hours) || 0,
            "project_id": Number.parseInt(params?.projectId),
            "old_total_hours": Number.parseFloat(projectData?.total_hours) || 0,
        };
        try {
            let response = await axiosInstance.post(API.addProjectPhaseHours, payload);
            if (response?.status === 200) {
                showAlert(response?.data?.message);
            } else {
                showAlert(response?.data?.message, "error");
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading((prev) => ({ ...prev, addProjectPhaseHours: false }));
            getProjectPhaseData();
            setIsEditPhaseHours(null);
            setIsEditPhase(null);
        }
    }

    const handleAddPhase = () => {
        let _initialProjectPhase = {
            ...initialProjectPhase,
            uniqueId: generateUniqueId(),
            ProjectPhaseHours: [],
            number: projectPhases?.length + 1,
        };
        setIsEditPhase(_initialProjectPhase?.uniqueId);
        setEditabelPhase(_initialProjectPhase)
        setIsAddPhase(true);
    }

    const handleCancelPhase = () => {
        setIsEditPhaseHours(null);
        setIsEditPhase(null);
        setIsAddPhase(false);
        setEditabelPhase({})
    }

    const handleEditPhase = (phase) => {
        setIsEditPhase(phase?.uniqueId);
        setEditabelPhase(phase)
    }

    const handleStatusChange = (e, id) => {
        setEditabelPhase((prev) => ({
            ...prev,
            status: e.target.value
        }));
    };

    const handleDateChange = (field, value, id) => {
        setEditabelPhase((prev) => ({
            ...prev,
            [field]: moment(new Date(value)).format("YYYY-MM-DD")
        }));
    };

    const getPhaseIndex = (uniqueId) => {
        if (isAddPhase) {
            return -1;
        } else {
            return projectPhases?.findIndex(data => data?.uniqueId === uniqueId)
        }
    }

    const handleSavePhase = async (uniqueId, type = "update") => {
        try {
            if (type === "update") {
                setIsLoading((prev) => ({ ...prev, editProjectPhase: true }));
                let phase = projectPhases?.find((phase) => phase?.id === uniqueId);
                let payload = {
                    "start_date": editabelPhase?.start_date,
                    "end_date": editabelPhase?.end_date,
                    "status": editabelPhase?.status
                };
                let response = await axiosInstance.put(API.updateProjectPhase(phase?.id), payload);
                if (response?.status === 200) {
                    showAlert(response?.data?.message);
                } else {
                    showAlert(response?.data?.message, "error");
                }
            } else {
                setIsLoading((prev) => ({ ...prev, saveProjectPhase: true }));
                let payload = {
                    "project_id": Number.parseInt(params?.projectId),
                    "start_date": editabelPhase?.start_date,
                    "end_date": editabelPhase?.end_date,
                    "status": editabelPhase?.status,
                    "project_status": projectData?.status,
                };
                let response = await axiosInstance.post(API.createProjectPhase, payload);
                if (response?.status === 200) {
                    showAlert(response?.data?.message);
                } else {
                    showAlert(response?.data?.message, "error");
                }
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setIsEditPhase(null);
            setIsEditPhaseHours(null);
            getProjectPhaseData();
            setIsLoading((prev) => ({ ...prev, editProjectPhase: false }));
            setIsLoading((prev) => ({ ...prev, saveProjectPhase: false }));

        }
    }

    return (
        <Box width={640} display={"flex"} flexDirection={"column"} height={isLoading?.projectPhase ? "100vh" : "auto"} justifyContent={isLoading?.projectPhase ? "center" : "flex-start"} alignItems={isLoading?.projectPhase ? "center" : "stretch"}>
            {
                isLoading?.projectPhase ? <CircularLoader height={'100%'} minHeight={'500px'} /> :
                    <>
                        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} p={2}>
                            <Box display={"flex"} alignItems={"center"} gap={2}>
                                <Typography variant="body1" color={"dark.800"} fontSize={16} fontWeight={600} lineHeight={"25px"} letterSpacing={"0.15px"}>Phases</Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    sx={{ textTransform: "none", boxShadow: 'none' }}
                                    onClick={() => handleAddPhase()}
                                    disabled={isAddPhase || isEditPhase}
                                >
                                    Add Phase
                                </Button>
                            </Box>
                            <IconButton
                                aria-label="Close"
                                size="small"
                                onClick={() => handleClosePhaseDrawer(false)}
                                sx={{ width: 24, minWidth: 24, height: 24, padding: '2px' }}
                            >
                                <CloseIcon fontSize="medium" />
                            </IconButton>
                        </Box>
                        <Divider />
                        <Box flex={1} overflow={"auto"}>
                            {(isAddPhase || isEditPhase) ?
                                <Box p={3}>
                                    <Typography variant="h5" color={"text"} fontSize={24} fontWeight={600} lineHeight={"36px"} mb={2}>
                                        {editabelPhase?.id ? `Edit ${titleCase(editabelPhase?.title)}` : "Create New Phase"}
                                    </Typography>
                                    <Box display={"flex"} gap={3} mb={4}>
                                        <Box flex={1}>
                                            <Typography color={"dark.600"} fontSize={12} fontWeight={400} lineHeight={"12px"} letterSpacing={"0.15px"}>{`Phase starts ${editabelPhase?.status === PROJECT_PHASE_STATUS['Not started'] ? '(optional)' : ''}`}</Typography>
                                            <Box className="calendar-date-picker"
                                                sx={{
                                                    'input': {
                                                        width: '100%',
                                                        height: 35,
                                                        border: 'none !important',
                                                        boxShadow: 'none !important',
                                                        borderRadius: '0px',
                                                        paddingBottom: '0px',
                                                        paddingLeft: '28px',
                                                        borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
                                                        margin: '0',
                                                        position: 'relative',
                                                        background: 'transparent',
                                                        color: 'dark.800',
                                                        fontSize: 16,
                                                        fontWeight: 400,
                                                        lineHeight: '24px',
                                                        letterSpacing: '0.15px'
                                                    },
                                                    'input:disabled': {
                                                        color: 'rgb(170, 170, 170)',
                                                        borderColor: 'rgba(118, 118, 118, 0.3) !important',
                                                    },
                                                    'input:disabled:hover': {
                                                        color: 'rgb(170, 170, 170)',
                                                        borderColor: 'rgba(118, 118, 118, 0.3) !important',
                                                    },
                                                    'input:hover': {
                                                        borderBottomColor: '#333333 !important',
                                                    },
                                                    'input:focus': {
                                                        borderBottomColor: '#047FE0 !important',
                                                    },
                                                    '.rmdp-container': {
                                                        minWidth: '100px',
                                                    },
                                                }}
                                            >
                                                <div style={{ height: 1 }}>
                                                    <CalendarTodayIcon
                                                        sx={{
                                                            fontSize: 20,
                                                            marginBottom: '-12px',
                                                            marginLeft: '2px',
                                                            color: isEditPhase === null ? "#0000008A" : "rgba(0, 0, 0, 0.54)",
                                                        }}
                                                    />
                                                </div>
                                                <SingleDatePicker
                                                    value={moment(editabelPhase?.start_date, "YYYY-MM-DD").toDate() || ""}
                                                    onChange={(value) => handleDateChange("start_date", value, editabelPhase?.id)}
                                                    isMultiple={false}
                                                    minDate={moment(projectPhases[getPhaseIndex(editabelPhase?.uniqueId) + 1]?.end_date ? projectPhases[getPhaseIndex(editabelPhase?.uniqueId) + 1]?.end_date : projectPhases[getPhaseIndex(editabelPhase?.uniqueId) + 1]?.start_date, "YYYY-MM-DD").set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).toDate()}
                                                    maxDate={moment(projectPhases[getPhaseIndex(editabelPhase?.uniqueId) - 1]?.start_date ? projectPhases[getPhaseIndex(editabelPhase?.uniqueId) - 1]?.start_date : editabelPhase?.end_date, "YYYY-MM-DD").set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).subtract(1, "seconds").toDate()}
                                                />
                                            </Box>
                                        </Box>
                                        <Box flex={1}>
                                            <Typography color={"dark.600"} fontSize={12} fontWeight={400} lineHeight={"12px"} letterSpacing={"0.15px"}>Phase ends (optional) </Typography>
                                            <Box className="calendar-date-picker"
                                                sx={{
                                                    'input': {
                                                        width: '100%',
                                                        height: 35,
                                                        border: 'none !important',
                                                        boxShadow: 'none !important',
                                                        borderRadius: '0px',
                                                        paddingBottom: '0px',
                                                        paddingLeft: '28px',
                                                        borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
                                                        margin: '0',
                                                        position: 'relative',
                                                        background: 'transparent',
                                                        color: 'dark.800',
                                                        fontSize: 16,
                                                        fontWeight: 400,
                                                        lineHeight: '24px',
                                                        letterSpacing: '0.15px'
                                                    },
                                                    'input:disabled': {
                                                        color: 'rgb(170, 170, 170)',
                                                        borderColor: 'rgba(118, 118, 118, 0.3) !important',
                                                    },
                                                    'input:disabled:hover': {
                                                        color: 'rgb(170, 170, 170)',
                                                        borderColor: 'rgba(118, 118, 118, 0.3) !important',
                                                    },
                                                    'input:hover': {
                                                        borderBottomColor: '#333333 !important',
                                                    },
                                                    'input:focus': {
                                                        borderBottomColor: '#047FE0 !important',
                                                    },
                                                    '.rmdp-container': {
                                                        minWidth: '100px',
                                                    },
                                                }}
                                            >
                                                <div style={{ height: 1 }}>
                                                    <CalendarTodayIcon
                                                        sx={{
                                                            fontSize: 20,
                                                            marginBottom: '-12px',
                                                            marginLeft: '2px',
                                                            color: isEditPhase === null ? "#0000008A" : "rgba(0, 0, 0, 0.54)",
                                                        }}
                                                    />
                                                </div>
                                                <SingleDatePicker
                                                    value={moment(editabelPhase?.end_date, "YYYY-MM-DD").toDate() || ""}
                                                    onChange={(value) => handleDateChange("end_date", value, editabelPhase?.id)}
                                                    isMultiple={false}
                                                    minDate={moment(editabelPhase?.start_date ? editabelPhase?.start_date : projectPhases[getPhaseIndex(editabelPhase?.uniqueId) + 1]?.end_date ? projectPhases[getPhaseIndex(editabelPhase?.uniqueId) + 1]?.end_date : projectPhases[getPhaseIndex(editabelPhase?.uniqueId) + 1]?.start_date, "YYYY-MM-DD").set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).toDate()}
                                                    maxDate={moment(projectPhases[getPhaseIndex(editabelPhase?.uniqueId) - 1]?.start_date, "YYYY-MM-DD").set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).subtract(1, "seconds").toDate()}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box mb={4}>
                                        <Input
                                            id="status"
                                            name={"status"}
                                            select
                                            type="select"
                                            label="Status"
                                            // defaultValue="pending"
                                            variant="standard"
                                            sx={{ width: "100%" }}
                                            onChange={(e) => handleStatusChange(e, editabelPhase?.id)}
                                            value={editabelPhase?.status}
                                            required={false}
                                        >
                                            {Object.entries(PROJECT_PHASE_STATUS)?.map((option) => (
                                                <MenuItem key={option[0]} value={option[1]} disabled={['on-hold', 'stopped', 'completed'].includes(projectData?.status) && (option[1] === "in-progress")}>
                                                    {titleCase(option[0])}
                                                </MenuItem>
                                            ))}
                                        </Input>
                                    </Box>
                                    <Box display={"flex"} alignItems={"center"} gap={2.5}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            sx={{ textTransform: "none", boxShadow: 'none' }}
                                            onClick={() => handleSavePhase(editabelPhase.id || editabelPhase.uniqueId, editabelPhase.id ? "update" : "save")}
                                            disabled={(!editabelPhase?.start_date && editabelPhase?.status !== PROJECT_PHASE_STATUS['Not started']) || !editabelPhase?.status}
                                        >
                                            {editabelPhase.id ? "Update" : "Save"}
                                        </Button>
                                        <Button
                                            variant="text"
                                            color="secondary"
                                            size="medium"
                                            sx={{ textTransform: "none", boxShadow: 'none' }}
                                            onClick={() => handleCancelPhase()}
                                            disabled={isLoading?.editProjectPhase || isLoading?.saveProjectPhase}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                </Box> : null}
                            {(!isAddPhase && !isEditPhase) ? <Box>
                                {projectPhases?.length ? projectPhases?.map((phase, index, phaseArray) => (
                                    <Accordion
                                        sx={{
                                            width: "100%",
                                            background: "#ffffff",
                                            borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
                                            boxShadow: "none",
                                            borderRadius: "0px",
                                            margin: "0px !important",
                                            "&:before": {
                                                display: "none",
                                            },
                                            ".MuiAccordionSummary-root": {
                                                minHeight: "inherit",
                                                padding: "0px 24px !important"
                                            },
                                            ".MuiAccordionSummary-root.Mui-expanded": {
                                                minHeight: "inherit",
                                                padding: "0px 24px !important"
                                            },
                                            ".MuiAccordionSummary-content": {
                                                margin: "24px 0px !important"
                                            },
                                            ".MuiAccordionSummary-content.Mui-expanded": {
                                                margin: "24px 0px 16px !important"
                                            },
                                            ".MuiAccordionDetails-root": {
                                                padding: "0px 24px 24px !important"
                                            },
                                            '.MuiAccordionSummary-expandIconWrapper': {
                                                transform: 'rotate(-90deg)',
                                            },
                                            '.MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                                                transform: 'rotate(0deg) !important',
                                            }
                                        }}
                                        key={index}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <Box display={"flex"} alignItems={"center"} gap={2} width={"100%"} pr={2}>
                                                <Box flex={1}>
                                                    <Box display={"flex"} alignItems={"center"} gap={1} mb={"5px"}>
                                                        <Typography variant="h5" color={"text"} fontSize={16} fontWeight={600} lineHeight={"24px"}>{phase?.title}</Typography>
                                                        {/* <Chip
                                                            label="In-Progress"
                                                            color="primary"
                                                            size="small"
                                                            variant="outlined"
                                                        /> */}
                                                        {getPhaseStatus(phase?.status, "chip")}
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="body1" color={"dark.800"} fontSize={12} fontWeight={500} lineHeight={"18px"} letterSpacing={"0.15px"} mr={0.4} display={"inline-block"}>By {!!phase?.updated_by_user ? phase?.updated_by_user?.name : phase?.created_by_user?.name}</Typography>
                                                        <Typography variant="body2" color={"text"} fontSize={10} fontWeight={500} lineHeight={"15px"} letterSpacing={"0.15px"} sx={{ opacity: "0.6" }} display={"inline-block"}>on {!!phase?.updated_by_user ? moment(phase?.updatedAt).format("DD/MM/YYYY") : moment(phase?.createdAt).format("DD/MM/YYYY")}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box display={"flex"} flexDirection={"column"}>
                                                    {
                                                        (!phase?.start_date && !phase?.end_date) ?
                                                            <Typography variant="body2" color={"dark.800"} fontSize={12} fontWeight={500} lineHeight={"18px"} letterSpacing={"0.15px"} sx={{ opacity: '0.6' }}>-</Typography> :
                                                            <>
                                                                <Typography variant="body2" color={"dark.800"} fontSize={14} fontWeight={600} lineHeight={"21px"} letterSpacing={"0.15px"} mb={"5px"}>{phase?.start_date ? convertDateFromFormat(phase?.start_date, "DD/MM/YYYY", "YYYY-MM-DD") : '-'}</Typography>
                                                                <Typography variant="body2" color={"dark.800"} fontSize={12} fontWeight={500} lineHeight={"18px"} letterSpacing={"0.15px"} sx={{ opacity: '0.6' }}>{phase?.end_date ? `to ${convertDateFromFormat(phase?.end_date, "DD/MM/YYYY", "YYYY-MM-DD")}` : 'to -'}</Typography>
                                                            </>
                                                    }
                                                </Box>
                                                <Box display={"flex"} flexDirection={"column"}>
                                                    <Box display={"flex"} gap={"4px"} alignItems={"baseline"} mb={"5px"}>
                                                        <Typography color={"dark.800"} fontSize={14} fontWeight={600} lineHeight={"21px"} letterSpacing={"0.15px"}>{roundToTwoDecimals(phase?.used_hrs ?? 0)} / {getProjectPhaseHours(phase?.id)} </Typography>
                                                        <Typography color={"dark.800"} fontSize={12} fontWeight={500} lineHeight={"18px"} letterSpacing={"0.15px"} mt={"-2px"} sx={{ opacity: '0.6' }}>hrs</Typography>
                                                    </Box>
                                                    <Typography variant="body2" color={"dark.800"} fontSize={12} fontWeight={500} lineHeight={"18px"} letterSpacing={"0.15px"} sx={{ opacity: '0.6' }}>Tracked</Typography>
                                                </Box>
                                                <Box display={"flex"} flexDirection={"column"}>
                                                    <Box display={"flex"} gap={"4px"} alignItems={"baseline"} mb={"5px"}>
                                                        <Typography color={remainingHoursColor((phase?.used_hrs ?? 0), getProjectPhaseHours(phase?.id))} fontSize={14} fontWeight={600} lineHeight={"21px"} letterSpacing={"0.15px"}>{getPhaseRemainingHours((getProjectPhaseHours(phase?.id) - (phase?.used_hrs ?? 0)))} </Typography>
                                                        <Typography color={"dark.800"} fontSize={12} fontWeight={500} lineHeight={"18px"} letterSpacing={"0.15px"} mt={"-2px"} sx={{ opacity: '0.6' }}>hrs</Typography>
                                                    </Box>
                                                    <Typography variant="body2" color={"dark.800"} fontSize={12} fontWeight={500} lineHeight={"18px"} letterSpacing={"0.15px"} sx={{ opacity: '0.6' }}>{(getProjectPhaseHours(phase?.id) - (phase?.used_hrs ?? 0)) > 0 ? 'Remaining' : 'Exceeds'}</Typography>
                                                </Box>
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Box width={"100%"} display={"flex"} gap={"14px"} p={1} bgcolor={"#F9F9F9"} borderRadius={"6px"}>
                                                <Box flex={1}>
                                                    <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
                                                        {phase?.ProjectPhaseHours?.map((phaseHours, index, phaseHoursArray) => {
                                                            return (
                                                                <Stack sx={{
                                                                    "&:hover": {
                                                                        '.edit-button': {
                                                                            opacity: '1',
                                                                        },
                                                                        '.delete-button': {
                                                                            opacity: '1',
                                                                        }
                                                                    },
                                                                }}
                                                                    key={index} display={"flex"} direction="row" gap={"30px"} alignItems={"center"}
                                                                >
                                                                    <Box display={"flex"} flexDirection={"row"} gap={"8px"}>
                                                                        <Box display={"flex"} alignItems={"center"} gap={2} py={0.5}
                                                                            sx={{
                                                                                "&:hover": {
                                                                                    '.edit-button': {
                                                                                        opacity: '1',
                                                                                    },
                                                                                    '.delete-button': {
                                                                                        opacity: '1',
                                                                                    }
                                                                                },
                                                                            }}
                                                                        >
                                                                            {/* <Box width={{ xs: 50, xl: 60 }} maxWidth={{ xs: 50, xl: 60 }} minWidth={{ xs: 50, xl: 60 }} pt={1.5}> */}
                                                                            <Typography color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.15px"} width={20} minWidth={20} align="right">{index + 1}.</Typography>
                                                                            {/* </Box> */}
                                                                            <Box width={100} maxWidth={100}>
                                                                                {((phaseHours?.id && (isEditPhaseHours === phaseHours?.uniqueId)) || !phaseHours?.id) ?
                                                                                    <Input
                                                                                        id="phase-hours"
                                                                                        name="hours"
                                                                                        // label="Hours"
                                                                                        variant="standard"
                                                                                        type="number"
                                                                                        sx={{ width: "100%" }}
                                                                                        onChange={(e) => handlePhaseHoursChange(e, phase?.id, phaseHours?.uniqueId)}
                                                                                        onBlur={(e) => handlePhaseHoursChange(e, phase?.id, phaseHours?.uniqueId)}
                                                                                        value={(phase?.status === PROJECT_PHASE_STATUS['Done'] && !phaseHours?.id) ? null : phaseHours?.hours || null}
                                                                                        disabled={(!phaseHours?.id ? false : isEditPhaseHours === null ? true : isEditPhaseHours === phaseHours?.uniqueId ? false : true) || phase?.status === "done"}
                                                                                    /> : <Typography color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.15px"} width={100} minWidth={100} ><b>{phaseHours?.hours}</b> Hours</Typography>
                                                                                }
                                                                            </Box>
                                                                        </Box>
                                                                        {phaseHours?.id ?
                                                                            (isEditPhaseHours !== phaseHours?.uniqueId) ?
                                                                                <Box display={"flex"} alignItems={"center"}>
                                                                                    <Typography variant="body1" color={"dark.800"} fontSize={12} fontWeight={500} lineHeight={"18px"} letterSpacing={"0.15px"} mr={0.4} display={"inline-block"}>By {!!phaseHours?.updated_by_user_hours ? `${phaseHours?.updated_by_user_hours?.name}` : `${phaseHours?.created_by_user_hours?.name}`}</Typography>
                                                                                    <Typography variant="body2" color={"text"} fontSize={10} fontWeight={500} lineHeight={"15px"} letterSpacing={"0.15px"} sx={{ opacity: "0.6" }} display={"inline-block"}>on {phaseHours?.updated_by_user_hours ? moment(phaseHours?.updatedAt).format("DD/MM/YYYY") : moment(phaseHours?.createdAt).format("DD/MM/YYYY")}</Typography>
                                                                                </Box> : null
                                                                            : null}
                                                                    </Box>
                                                                    <Box sx={{
                                                                        "&:hover": {
                                                                            '.edit-button': {
                                                                                opacity: '1',
                                                                            },
                                                                            '.delete-button': {
                                                                                opacity: '1',
                                                                            }
                                                                        },
                                                                    }}>
                                                                        {phaseHours?.id ?
                                                                            isEditPhaseHours === null && index === phaseHoursArray?.length - 1 ?
                                                                                <Stack direction="row" spacing={0.5}>
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        className="edit-button"
                                                                                        sx={{
                                                                                            opacity: '0', transition: 'all 0.25s ease-in-out',
                                                                                            width: "32px",
                                                                                            height: "32px",
                                                                                        }}
                                                                                        disabled={isEditPhase !== null || isEditPhaseHours !== null}
                                                                                        onClick={() => handleEditPhaseHours(phaseHours?.uniqueId)}
                                                                                    >
                                                                                        <EditIcon sx={{ fontSize: 16, color: "rgba(0, 0, 0, 0.56)" }} />
                                                                                    </IconButton>
                                                                                    <IconButton
                                                                                        size="small"
                                                                                        className="delete-button"
                                                                                        sx={{
                                                                                            width: "32px",
                                                                                            height: "32px",
                                                                                            opacity: '0', transition: 'all 0.25s ease-in-out'
                                                                                        }}
                                                                                        disabled={isEditPhase !== null || isEditPhaseHours !== null}
                                                                                        onClick={() => handleDeletePhaseHours(phaseHours?.id, phase?.id)}
                                                                                    >
                                                                                        <Delete fontSize="small" />
                                                                                    </IconButton>
                                                                                </Stack> :
                                                                                isEditPhaseHours === phaseHours?.uniqueId ?
                                                                                    <Box display={"flex"} flex={1} gap={2}>
                                                                                        <Button
                                                                                            variant="contained"
                                                                                            color="secondary"
                                                                                            size="medium"
                                                                                            sx={{ textTransform: "none", boxShadow: 'none', padding: " 4px 10px", height: '32px' }}
                                                                                            onClick={() => handleSavePhaseHours(phaseHours?.id, phase?.id)}
                                                                                            isButtonLoading={isLoading?.editProjectPhaseHours}
                                                                                            disabled={!phaseHours?.hours}
                                                                                        >
                                                                                            Save
                                                                                        </Button>
                                                                                        <Button
                                                                                            variant="text"
                                                                                            color="secondary"
                                                                                            size="medium"
                                                                                            sx={{ textTransform: "none", boxShadow: 'none', padding: " 4px 10px", height: '32px' }}
                                                                                            onClick={() => handleCancelPhaseHours(phase?.id)}
                                                                                            disabled={isLoading?.editProjectPhaseHours}
                                                                                        >
                                                                                            Cancel
                                                                                        </Button>
                                                                                    </Box> :
                                                                                    index === phaseHoursArray?.length - 1 ?
                                                                                        <Stack direction="row" spacing={1}>
                                                                                            <IconButton
                                                                                                size="small"
                                                                                                sx={{
                                                                                                    width: "40px",
                                                                                                    height: "40px",
                                                                                                }}
                                                                                                disabled={isEditPhase !== null || isEditPhaseHours !== null}
                                                                                                onClick={() => handleEditPhaseHours(phaseHours?.uniqueId)}
                                                                                            >
                                                                                                <EditIcon fontSize="small" />
                                                                                            </IconButton>
                                                                                            <IconButton
                                                                                                size="small"
                                                                                                sx={{
                                                                                                    width: "40px",
                                                                                                    height: "40px",
                                                                                                }}
                                                                                                disabled={isEditPhase !== null || isEditPhaseHours !== null}
                                                                                                onClick={() => handleDeletePhaseHours(phaseHours?.id, phase?.id)}
                                                                                            >
                                                                                                <Delete fontSize="small" />
                                                                                            </IconButton>
                                                                                        </Stack> : null :
                                                                            <Stack direction={"row"} gap={"10px"}>
                                                                                <Button
                                                                                    size="medium"
                                                                                    variant="contained"
                                                                                    color="secondary"
                                                                                    isButtonLoading={isLoading?.addProjectPhaseHours}
                                                                                    disabled={!phaseHours?.hours || phase?.status === PROJECT_PHASE_STATUS['Done']}
                                                                                    onClick={() => handleAddPhaseHours(phase?.id, phaseHours?.uniqueId)}
                                                                                    sx={{ textTransform: "none", boxShadow: 'none', padding: " 4px 10px", height: '32px' }}
                                                                                >
                                                                                    Save
                                                                                </Button>
                                                                                {phaseHoursArray?.length > 1 &&
                                                                                    <Button
                                                                                        disabled={isLoading?.addProjectPhaseHours}
                                                                                        size="medium"
                                                                                        color='secondary'
                                                                                        onClick={() => handleCancelPhaseHours(phase?.id)}
                                                                                        variant='text'
                                                                                        sx={{ textTransform: 'none', boxShadow: 'none !important' }}
                                                                                    >
                                                                                        Cancel
                                                                                    </Button>}
                                                                            </Stack>
                                                                        }
                                                                    </Box>
                                                                </Stack>
                                                            )
                                                        })}
                                                    </Box>
                                                    {!!phase?.id ?
                                                        <Stack direction="row" justifyContent="space-between" alignItems={"center"} mt={1} pl={"36px"}>
                                                            <Button
                                                                color='secondary'
                                                                disabled={(phase?.ProjectPhaseHours[phase?.ProjectPhaseHours?.length - 1]?.id ? false : true) ||
                                                                    (isEditPhase !== null) ||
                                                                    (isEditPhaseHours !== null) || phase?.status === "done"}
                                                                onClick={() => handleAddEmptyPhaseHours(phase?.id)}
                                                                sx={{ textTransform: 'none', boxShadow: 'none !important' }}
                                                            >
                                                                Add Hours
                                                            </Button>
                                                        </Stack> : null}
                                                </Box>

                                                <Box whiteSpace={"nowrap"}>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        size="small"
                                                        sx={{ textTransform: "none", boxShadow: 'none' }}
                                                        onClick={() => handleEditPhase(phase)}
                                                    >
                                                        Edit Phase
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                ))
                                    : <EmptyPage title="No Phase created." isButtonShow={false} />}
                            </Box> : null}
                        </Box>
                    </>
            }
        </Box>
    )
}

export default PhaseDetails