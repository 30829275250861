import { Box, Grid, IconButton, Skeleton, Stack, Typography } from '@mui/material'
import React from 'react'
import ICONS from '../../constants/icons'
import { titleCase } from '../../utils/validation'

const Breadcrumb = ({
    isBack = false,
    isPathShow = true,
    pageTitle,
    title,
    subTitle = pageTitle,
    isLoading = false,
    onBackClick = () => { },
    isBottomBorder = true
}) => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`${isBottomBorder ? '1px solid #E2E4EC' : '0px'}`}
            py={1.5}
            pl={2}
            pr={4}
        >
            <Grid

                display="flex"
                alignItems="center"
            >
                {!!isBack ?
                    <IconButton
                        aria-label="back"
                        variant="outlined"
                        color="secondary.100"
                        size="small"
                        sx={{
                            borderRadius: "4px",
                            marginRight: "2px",
                            padding: '0px',
                            'svg': {
                                height: '100%',
                                width: '100%'
                            }
                        }}
                        onClick={onBackClick}
                    >
                        <i
                            style={{
                                height: "18px",
                                width: "18px",
                                transform: "rotate(90deg)",
                            }}
                        >
                            {ICONS.ChevronLineSmall}
                        </i>
                    </IconButton>
                    : null}
                <Stack
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={0.5}
                    direction="row"
                >
                    <Typography
                        variant="body1"
                        color="secondary"
                        fontSize={13}
                        lineHeight="16px"
                        letterSpacing="0.15px"
                        fontWeight={600}
                        sx={{ textTransform: "capitalize", cursor: "pointer" }}
                        onClick={onBackClick}
                    >
                        {titleCase(title)}
                    </Typography>
                    {isLoading ?
                        <Box width={50} height={27}>
                            <Skeleton animation="wave" width={"100%"} height={"100%"} />
                        </Box>
                        : <React.Fragment>
                            <i
                                aria-label="back"
                                variant="outlined"
                                color="secondary.100"
                                size="small"
                                style={{
                                    height: "22px",
                                    width: "22px",
                                    transform: "rotate(90deg)",
                                    borderRadius: "4px",
                                    marginRight: "4px",
                                    padding: '0px',
                                }}
                            >
                                {ICONS.ChevronLineSmall}
                            </i>
                            <Typography
                                variant="body1"
                                color="secondary"
                                fontSize={18}
                                fontWeight={600}
                                lineHeight="27px"
                                letterSpacing="0.15px"
                                sx={{ textTransform: "capitalize" }}
                            >
                                {titleCase(subTitle)}
                            </Typography>
                        </React.Fragment>}
                </Stack>
            </Grid>
        </Grid>
    )
}

export default Breadcrumb