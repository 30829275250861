import React, { useState, useEffect } from "react";
import { Box, TextField, Typography } from "@mui/material";

const TimeInput = ({ value = "", onChange }) => {
    const [timeInput, setTimeInput] = useState(value);
    const [inputError, setInputError] = useState(false);

    const validTimePattern = /^(\d+h)?(\s*\d+m)?$/i;

    const isValidTimeInput = (input) => validTimePattern.test(input.trim());

    const parseTimeInput = (input) => {
        const timePattern = /(\d+)\s*h(?:\s*(\d+)\s*m)?/i;
        const match = timePattern.exec(input);
        const hours = match?.[1] ? parseInt(match[1], 10) : 0;
        const minutes = match?.[2] ? parseInt(match[2], 10) : 0;
        return { hours, minutes };
    };

    const normalizeTime = ({ hours, minutes }) => {
        const extraHours = Math.floor(minutes / 60);
        const normalizedMinutes = minutes % 60;
        return {
            hours: hours + extraHours,
            minutes: normalizedMinutes,
        };
    };

    const handleTimeChange = (e) => {
        const input = e.target.value;

        setTimeInput(input);

        if (isValidTimeInput(input)) {
            setInputError(false);

            const parsedTime = parseTimeInput(input);
            const normalized = normalizeTime(parsedTime);

            if (onChange) {
                onChange(`${normalized.hours ? `${normalized.hours}h` : ""} ${normalized.minutes ? `${normalized.minutes}m` : ""}`.trim());
            }
        } else {
            setInputError(true);
        }
    };

    useEffect(() => {
        setTimeInput(value);
    }, [value]);

    return (
        <Box sx={{ position: 'relative', marginBottom: inputError ? '30px' : '15px', cursor: 'pointer' }}>
            <TextField
                variant="standard"
                size="medium"
                fullWidth
                value={timeInput}
                onChange={handleTimeChange}
                placeholder="0h"
                InputProps={{
                    disableUnderline: true,
                    sx: {
                        padding: '4px 4px 3px 4px',
                        borderRadius: '3px',
                        cursor: 'pointer',
                        // border: '1px solid #ccc',
                        borderBottom: '2px solid transparent',
                        bgcolor: '#fff',
                        '&:hover': {
                            bgcolor: '#F4F4F4',
                        },
                        '&:focus-within': {
                            bgcolor: '#fff',
                            borderBottom: '2px solid #1976d2',
                            borderRadius: '0px',
                            fontWeight: '400'
                        },
                        fontSize: '14px',
                        fontWeight: '600'
                    },
                }}
                sx={{
                    height: 'auto',
                    paddingLeft: '6px',
                }}
                error={inputError}
            />
            {inputError ? (
                <Box
                    sx={{
                        color: 'red',
                        fontSize: '12px',
                        position: 'absolute',
                        top: '100%',
                        marginTop: '4px',
                        paddingLeft: '6px',
                    }}
                    className="error-box"
                >
                    <Typography fontSize={14} color={"red.main"}>
                        Invalid format! Use '1h', '1h 15m', or '5m'.
                    </Typography>
                </Box>
            ) : null}
        </Box>
    );
};

export default TimeInput;








