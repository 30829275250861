import React, { useEffect, useState } from "react";
import { TextField, Autocomplete, Chip, InputAdornment, IconButton } from "@mui/material";
import axiosInstance from "../../axios";
import API from "../../axios/api";
import { useAlert } from "../../hook/useAlert";
import { Close } from "@mui/icons-material";
import { titleCase } from "../../utils";

const AutoCompleteTagSelection = ({ tagOptions, setTagOptions, selectedTags, setSelectedTags }) => {
    const [inputValue, setInputValue] = useState("");
    const showAlert = useAlert();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const body = {
                    "title": inputValue,
                    "isForTag": true
                };
                const response = await axiosInstance.post(API.findJobProfileOrTag, body);
                if (response?.status === 200) {
                    setTagOptions(response?.data?.data ?? []);
                } else {
                    setTagOptions([]);
                }
            } catch (error) {
                console.error('Error fetching data', error);
                // showAlert('Error fetching data');
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAddNewTag = async (newTag) => {
        try {
            const body = {
                title: newTag,
                isForTag: true
            };
            const response = await axiosInstance.post(API.createJobProfileOrTag, body);
            if (response.status === 200) {
                const newTagObj = response?.data?.data;
                setTagOptions((prevOptions) => [...prevOptions, newTagObj]);
                showAlert("Tag added successfully");
                return newTagObj;
            } else {
                showAlert("Error adding new tag", "error");
                return null;
            }
        } catch (error) {
            console.error("Error adding new option", error);
            showAlert("Error adding new option");
            return null;
        }
    };

    const handleChange = async (event, newValue) => {
        const lastItem = newValue[newValue?.length - 1];

        if (lastItem && lastItem.title.startsWith("Add ")) {
            const tagTitle = lastItem?.title?.trim()?.replace(/Add\s"(.+)"$/, "$1").trim();
            const newTagObj = await handleAddNewTag(titleCase(tagTitle));

            if (newTagObj) {
                setSelectedTags((prev) => [...prev, newTagObj]);
            }
        } else {
            setSelectedTags(newValue);
        }

        if (newValue?.length < selectedTags?.length) handleClearInput();
    };

    const filteredOptions = inputValue?.trim()?.length ? tagOptions?.filter((option) =>
        option?.title?.trim()?.toLowerCase()?.includes(inputValue?.trim()?.toLowerCase())
    ) ?? [] : [];

    if (inputValue && !tagOptions?.some((opt) => opt?.title?.trim()?.toLowerCase() === inputValue?.trim()?.toLowerCase())) {
        filteredOptions.push({ id: -1, title: `Add "${titleCase(inputValue)}"` });
    }

    const handleClearInput = () => setInputValue("");

    return (
        <Autocomplete
            multiple
            freeSolo
            options={filteredOptions}
            getOptionLabel={(option) => option.title}
            value={selectedTags}
            onChange={handleChange}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
            disableClearable={true}
            getOptionDisabled={(option) => selectedTags?.some(tag => tag?.id === option?.id)}

            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Tag"
                    variant="standard"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {inputValue && (
                                    <InputAdornment position="end">
                                        <IconButton size="small" onClick={handleClearInput}>
                                            <Close fontSize="small" />
                                        </IconButton>
                                    </InputAdornment>
                                )}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip key={option.id} label={option.title} {...getTagProps({ index })} />
                ))
            }
        />
    );
};

export default AutoCompleteTagSelection;