import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../../axios';
import API from '../../../../axios/api';
import { Box, Divider, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { getApplicationNumber, getFormattedDate, getLeaveStatus, sortArrayByKey, titleCase } from '../../../../utils';
import LaunchIcon from '@mui/icons-material/Launch';
import BackgroundLetterAvatars from '../../../../components/BackgroundLetterAvatars';
import CloseIcon from "@mui/icons-material/Close";
import CircularLoader from '../../../../components/CircularLoader';
import { useNavigate } from 'react-router-dom';
import URLS from '../../../../routes/urls';

const LastThreeMonthReport = ({ handleReportModalClose, leaveData, userId }) => {
    const navigate = useNavigate();
    const [threeMonthsData, setThreeMonthsData] = useState([]);
    const [isLoading, setIsLoading] = useState({
        leaveReportLoading: false,
    });
    useEffect(() => {
        getLastThreeMonthLeaveData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getLastThreeMonths() {
        const now = new Date();
        let months = [];

        for (let i = 0; i < 3; i++) {
            let month = new Date(now);
            month.setMonth(now.getMonth() - i);
            months.push(month.toLocaleString('default', { month: 'long', year: 'numeric' }));
        }

        return months;
    }

    function groupByMonthYear(data) {
        const lastThreeMonths = getLastThreeMonths();
        const result = [];

        lastThreeMonths.forEach(month => {
            result.push({ [month]: [] });
        });

        data.forEach(item => {
            item.leave_days.forEach(leave => {
                const leaveDate = new Date(leave.leave_date);
                const monthYear = leaveDate.toLocaleString('default', { month: 'long', year: 'numeric' });

                if (lastThreeMonths.includes(monthYear)) {
                    const monthObj = result.find(obj => Object.keys(obj)[0] === monthYear);
                    if (monthObj) {
                        monthObj[monthYear].push({
                            ...leave,
                            ...leave,
                            processed_by: item?.processed_by,
                            process_date: item?.process_date,
                            status: item?.status === "sys-generated" ? "pending" : item?.status,
                            application_num: item?.application_num,
                            type: titleCase(item?.UserLeavesTypeSetting?.org_leave_type?.LeaveType?.name?.split(" ")?.[0])
                        });
                    }
                }
            });
        });

        // Remove months with no data
        return result.filter(monthObj => Object.values(monthObj)[0].length > 0);
    }

    const getLastThreeMonthLeaveData = async () => {
        try {
            setIsLoading(prev => ({ ...prev, leaveReportLoading: true }))
            let response = await axiosInstance.get(API.getLast3MonthReportOfUserById(leaveData?.id));
            if (response?.status === 200) {
                let generatedResponse = groupByMonthYear(response?.data?.data);
                setThreeMonthsData(generatedResponse);
            } else {
                setThreeMonthsData({});
            }
            setIsLoading(prev => ({ ...prev, leaveReportLoading: false }));
        } catch (error) {
            console.error(error);
            setIsLoading(prev => ({ ...prev, leaveReportLoading: false }));
        }
    }

    const getLeaveCount = (reqDayData) => {
        if (reqDayData?.leave_duration === "full") {
            return 1
        } else {
            return (<>
                0.5 (
                {reqDayData?.leave_duration === "first_half" ? (
                    <>
                        1
                        <Typography fontSize={"70%"} display={"inline-block"} sx={{ verticalAlign: "top" }}>st</Typography>{" "}
                        half
                    </>
                ) : reqDayData?.leave_duration === "second_half" ? (
                    <>
                        2
                        <Typography fontSize={"70%"} display={"inline-block"} sx={{ verticalAlign: "top" }}>nd</Typography>{" "}
                        half
                    </>
                ) : null}
                )
            </>)
        }
    }

    const getLeaveSetOff = (reqDayData) => {
        if (reqDayData?.isLeave) return "Fully Paid"
        else {
            if (reqDayData?.leave_duration === "full") {
                return reqDayData?.is_paid === 1 ? "Fully Paid" : reqDayData?.is_paid === 0.5 ? "Half Paid / Half Unpaid" : "Fully Unpaid"
            } else {
                return reqDayData?.is_paid === 0.5 ? "Fully Paid" : "Fully Unpaid"
            }
        }
    }

    const handleLeaveClick = (leave) => {
        navigate(`${URLS.OtherLeaveDetails}/${userId}/${leave?.leave_application_id}`);
    };

    return (
        isLoading?.leaveReportLoading ?
            <CircularLoader height="500px" /> :
            <>
                <Box px={3} py={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography variant="h3" color={"dark.800"} fontSize={20} lineHeight={"32px"} fontWeight={500} letterSpacing={"0.15px"}>Last 3 Months Leaves</Typography>
                    <IconButton
                        aria-label="delete"
                        size="small"
                        className="edit-btn"
                        sx={{ p: 0, height: 24, width: 24, maxWidth: 24 }}
                        onClick={() => handleReportModalClose()}
                    >
                        <CloseIcon fontSize="medium" />
                    </IconButton>
                </Box>
                <Divider />
                <Box p={3} display={"flex"} flexDirection={"column"} gap={4} overflow={"auto"}>
                    {
                        threeMonthsData?.length ? threeMonthsData?.map((monthData, index) => (
                            <Box display={"flex"} flexDirection={"column"} gap={2}>
                                <React.Fragment key={index}>
                                    <Typography variant="h4" color={"dark.800"} fontSize={16} fontWeight={600} lineHeight={"26px"} letterSpacing={"0.15px"}>{Object.keys(monthData)[0]}</Typography>
                                    <TableContainer sx={{ border: '1px solid #ddd', borderRadius: '3px', overflow: 'overlay' }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow fontSize={14} fontWeight={500} lineHeight={'24px'} letterSpacing={'0.17px'} alignitems={'center'} sx={{
                                                    backgroundColor: '#F5F8FA', textAlign: 'center', height: '36px', maxHeight: '36px',
                                                    "th": { padding: '6px 16px 6px 16px' },
                                                }}>
                                                    <TableCell sx={{ minWidth: 130 }}>Date</TableCell>
                                                    <TableCell sx={{ minWidth: 120, maxWidth: 120, width: 120 }}>Leave</TableCell>
                                                    <TableCell sx={{ minWidth: 90, maxWidth: 190, }}>Type</TableCell>
                                                    <TableCell sx={{ minWidth: 120, maxWidth: 120, width: 120 }}>Full / Half</TableCell>
                                                    <TableCell sx={{ minWidth: 195 }}>Set Off</TableCell>
                                                    <TableCell sx={{ minWidth: 82, maxWidth: 82, width: 82 }}>Credits</TableCell>
                                                    <TableCell sx={{ minWidth: 120, maxWidth: 120, width: 120 }}>Status</TableCell>
                                                    <TableCell sx={{ minWidth: 224 }}>By</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    Object.values(monthData)[0]?.length ?
                                                        sortArrayByKey(Object.values(monthData)[0], "asc", "leave_date")?.map((leaveData, index) => (
                                                            <TableRow sx={{
                                                                "td": {
                                                                    fontSize: 14,
                                                                    fontWeight: 400,
                                                                    color: "dark.800",
                                                                    lineHeight: "20px",
                                                                    letterSpacing: "0.17px",
                                                                    padding: "10px 16px",
                                                                    // '&:last-child': {
                                                                    //     border: 'none',
                                                                    // },
                                                                },
                                                                "&:hover": {
                                                                    background: "#F7F7F7",
                                                                    '.launchIcon': {
                                                                        display: 'inline-flex',
                                                                    },
                                                                    '.leaveId': {
                                                                        color: '#047FE0',
                                                                        textDecoration: 'underline',
                                                                    },
                                                                    '.leave-details': {
                                                                        opacity: 1,
                                                                        display: 'flex',
                                                                    },
                                                                },
                                                            }}
                                                                key={index}
                                                            >
                                                                <TableCell>{getFormattedDate(leaveData?.leave_date, "DD/MM/YYYY")}</TableCell>
                                                                <TableCell>
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            color: 'black',
                                                                            cursor: 'pointer',
                                                                            '&:hover .launchIcon': {
                                                                                display: 'inline-flex',
                                                                            },
                                                                            '&:hover .leaveId': {
                                                                                color: '#047FE0',
                                                                                textDecoration: 'underline',
                                                                            },
                                                                        }}
                                                                        onClick={() => handleLeaveClick(leaveData)}
                                                                    >
                                                                        <Typography
                                                                            fontSize={14}
                                                                            fontWeight={500}
                                                                            lineHeight={"14px"}
                                                                            letterSpacing={"0.17px"}
                                                                            className="leaveId"
                                                                            sx={{
                                                                                textDecoration: 'none',
                                                                            }}
                                                                        >
                                                                            {getApplicationNumber(leaveData?.application_num)}
                                                                        </Typography>
                                                                        <LaunchIcon
                                                                            className="launchIcon"
                                                                            sx={{
                                                                                fontSize: 16,
                                                                                marginLeft: '4px',
                                                                                display: 'none',
                                                                                color: '#047FE0',
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell>{titleCase(leaveData?.type)}</TableCell>
                                                                <TableCell>{getLeaveCount(leaveData)}</TableCell>
                                                                <TableCell>{getLeaveSetOff(leaveData)}</TableCell>
                                                                <TableCell>{leaveData?.credit_used ?? 0}</TableCell>
                                                                <TableCell>
                                                                    {getLeaveStatus(leaveData?.status, "chip")}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {!!leaveData?.processed_by ? <Box display={"flex"} alignItems={"center"} gap={"6px"} whiteSpace={"nowrap"}>
                                                                        <BackgroundLetterAvatars sx={{ width: 24, height: 24, minWidth: 24, fontSize: "13px", fontWeight: '500' }}
                                                                            user={leaveData?.processed_by?.[0]}
                                                                        />
                                                                        <Typography fontSize={14} fontWeight={400} color={"dark.800"} lineHeight={"20px"} letterSpacing={"0.17px"}>{titleCase(leaveData?.processed_by?.[0]?.name)}</Typography>
                                                                        <Typography fontSize={12} fontWeight={400} color={"dark.800"} lineHeight={"17px"} letterSpacing={"0.17px"}>at {getFormattedDate(leaveData?.process_date, "DD/MM/YYYY")}</Typography>
                                                                    </Box> : <Box>-</Box>}
                                                                </TableCell>
                                                            </TableRow>
                                                        )) :
                                                        <TableRow>
                                                            <TableCell colSpan={8}>
                                                                <Typography p={3} fontSize={14} fontWeight={400} lineHeight={"14px"} letterSpacing={"0.24px"} sx={{ textAlign: 'center' }}>
                                                                    No data found
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </React.Fragment>
                            </Box>
                        )) :
                            <Box display={"flex"} textAlign={'center'} alignItems={'center'} justifyContent={'center'}>
                                <Typography p={3} fontSize={14} fontWeight={400} lineHeight={"14px"} letterSpacing={"0.24px"} sx={{ textAlign: 'center' }}>
                                    No data found
                                </Typography>
                            </Box>
                    }
                </Box>
            </>
    )
}

export default LastThreeMonthReport