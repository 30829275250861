import React from "react";
import { TextField } from "@mui/material";
import { LocalizationProvider, TimeField } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const CustomTimeInput = ({ label, time, onTimeChange, handleBlur, ...rest }) => {
    const handleTimeChange = (newValue) => {
        if (newValue && newValue.isValid()) {
            onTimeChange(newValue);
        } else {
            let time = dayjs().set("hour", 0).set("minute", 0);
            onTimeChange(time);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimeField
                label={label}
                value={time}
                onChange={handleTimeChange}
                onBlur={() => handleBlur(time)}
                format="HH:mm"
                defaultValue={dayjs('2022-04-17T15:30')}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        fullWidth
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: null,
                            inputMode: "numeric",
                            style: {
                                textAlign: "center",
                                fontSize: "1.2rem",
                            },
                        }}
                        sx={{
                            "& .MuiInputBase-root": {
                                padding: "0",
                            },
                            "& .MuiInputBase-input": {
                                fontWeight: "bold",
                                padding: "0",
                            },
                            "& .MuiInput-underline:before": {
                                borderBottom: "none",
                            },
                            "& .MuiInput-underline:after": {
                                borderBottom: "none",
                            },
                        }}
                    />
                )}
                {...rest}
            />
        </LocalizationProvider>
    );
};

export default CustomTimeInput;