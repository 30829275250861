import React, { useState } from "react";
import Box from "@mui/material/Box";
import SideBar from "../../../components/SideBar";
import AppBar from "../../../components/AppBar";
import useAuthentication from "../../../hook/useAuthentication";
import { Navigate } from "react-router-dom";
import URLS from "../../../routes/urls";
import Breadcrumb from "../../../components/Breadcrumb";

const ClientWrapper = ({ children, sx, isShowSideBar = true }) => {
  let initialState = {
    isOpen: true,
    isHideSideBar: false,
  };
  const { getCurrentUser } = useAuthentication();
  const [isOpen, setIsOpen] = useState(initialState?.isOpen);
  const [isHideSideBar, setIsApply] = useState(initialState?.isHideSideBar);

  const handleDrawerToggle = () => {
    setIsOpen(!isOpen);
    setIsApply(!isHideSideBar);
  };

  const currentUser = getCurrentUser();
  if (["super_admin"].includes(currentUser.user_type)) {
    return <Navigate to={URLS.AdminDashboard} />;
  }

  return (
    <Box
      sx={{ bgcolor: "bgColor", height: "100vh", paddingTop: "62px" }}
      overflow="hidden"
      display="flex"
    >
      <AppBar
        appBarType="administrator"
        isOpen={isOpen}
        handleDrawerToggle={handleDrawerToggle}
        isHideSideBar={isHideSideBar}
        setIsApply={setIsApply}
        isShowSideBar={isShowSideBar}
      />
      <Box display="flex" width="100%" bgcolor="white" position="relative">
        {isShowSideBar ? <Box sx={{ display: { lg: "flex", xs: "none" }, height: "100%" }}>
          <SideBar
            isHideSideBar={isHideSideBar}
            setIsApply={setIsApply}
            currentUser={currentUser}
            isOpen={isOpen}
            setMenuOpen={setIsOpen}
            isToggle={false}
          />
        </Box> : null}
        <Box
          sx={{
            flex: "1",
            maxWidth: "100%",
            overflow: "hidden",
            marginLeft: { lg: isShowSideBar ? isOpen ? "256px" : "60px" : "0px" },
            transition: "all 0.25s ease-in-out",
          }}
          display="flex"
        >
          <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
            {children?.props?.title && children?.props?.header ? (
              <Breadcrumb pageTitle={children?.props?.title} title={children?.props?.header.slice(1).split("/")[0]}
                subTitle={children?.props?.header.slice(1).split("/")[1]} />
            ) : null}
            <Box sx={[{ flex: "1", overflowY: "hidden", display: 'flex' }, sx]}>{children}</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ClientWrapper;
