/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Grid,
    IconButton,
    Paper,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Tabs,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { HOLIDAYS } from "../../../../constants/default-values";
import useAuthentication from "../../../../hook/useAuthentication";
import API from "../../../../axios/api";
import axiosInstance from "../../../../axios";
import CircularLoader from "../../../../components/CircularLoader";
import { getDayAndMonth, getFormattedDate, handleSortFestivalHolidays, handleSortOfficeHoliday, titleCase } from "../../../../utils";
import { usePageTitle } from "../../../../hook/useTitle";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import { useAlert } from "../../../../hook/useAlert";
import TablePagination from "../../../../components/TablePagination";
import CloseIcon from "@mui/icons-material/Close";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const HolidayList = ({ handleHolidayModalClose, years, holidaySelectYearData, holidayKey }) => {
    const { getCurrentUser } = useAuthentication();
    const { setPageTitle } = usePageTitle();
    const currentUser = getCurrentUser();
    const showAlert = useAlert();
    // eslint-disable-next-line no-unused-vars
    const [selectedYear, setSelectedYear] = useState(holidaySelectYearData ?? "");
    const [sortType, setSortType] = useState("asc");
    const [tabValue, setTabValue] = useState(holidayKey || Object.keys(HOLIDAYS)[0]);
    const [festivalHolidays, setFestivalHolidays] = useState([]);
    const [optionalFestivalHolidays, setOptionalFestivalHolidays] = useState([]);
    const [officeHolidays, setOfficeHolidays] = useState([]);
    const [optionalOfficeHolidays, setOptionalOfficeHolidays] = useState([]);
    const [loading, setLoading] = useState({
        dateLoading: false,
        tableDataLoading: true,
    });
    const [isOpen, setIsOpen] = useState(false);
    const [deletableHoliday, setDeletableHoliday] = useState({});
    setPageTitle("Holidays Settings");

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const [page, setPage] = useState(0);
    const [rowPerPage, setRowPerPage] = useState(25);
    const [isDeletData, setIsDeleteData] = useState(false);

    useEffect(() => {
        setSortType("asc");
        setPage(0);
        setRowPerPage(25);
        getDataAccordingPagination();
    }, [tabValue]);

    useEffect(() => {
        if (currentUser?.organization_id && years?.length && !!selectedYear?.id)
            fetchHolidaysData();
    }, [selectedYear]);

    useEffect(() => {
        getDataAccordingPagination();
    }, [page, rowPerPage]);

    const getDataAccordingPagination = (officeHolidaysData = optionalOfficeHolidays, festivalHolidaysData = optionalFestivalHolidays) => {
        let dataToPaginate;
        let setDataFunction;

        if (tabValue === Object.keys(HOLIDAYS)[0]) {
            dataToPaginate = officeHolidaysData;
            setDataFunction = setOfficeHolidays;
        } else {
            dataToPaginate = festivalHolidaysData;
            setDataFunction = setFestivalHolidays;
        }

        if (dataToPaginate && dataToPaginate.length) {
            const startIdx = page * rowPerPage;
            const endIdx = Math.min(startIdx + rowPerPage, dataToPaginate.length);
            const updatedPageData = dataToPaginate.slice(startIdx, endIdx);
            updatedPageData.sort(function (a, b) {
                if (sortType === "asc")
                    return new Date(a.date) - new Date(b.date);
                else return new Date(b.date) - new Date(a.date);
            });
            setDataFunction(updatedPageData);
        } else {
            // Handle case when there is no data to paginate
            setDataFunction([]);
        }
    };

    const fetchHolidaysData = async () => {
        try {
            setLoading((prev) => ({ ...prev, tableDataLoading: true }));
            const response = await axiosInstance.get(
                API.getAllUserHolidays(currentUser.organization_id, selectedYear?.id)
            );
            if (response.status === 200) {
                let responseData = response?.data?.data;
                let sortedOfficeHoliday = handleSortOfficeHoliday(responseData);
                let sortedFestivalHoliday = handleSortFestivalHolidays(responseData?.festival_holidays);
                setFestivalHolidays(sortedFestivalHoliday);
                setOptionalFestivalHolidays(sortedFestivalHoliday);
                setOfficeHolidays(sortedOfficeHoliday?.slice(0, rowPerPage));
                setOptionalOfficeHolidays(sortedOfficeHoliday);
            } else {
                setFestivalHolidays([]);
                setOptionalFestivalHolidays([]);
                setOfficeHolidays([]);
                setOptionalOfficeHolidays([]);
            }
            setLoading((prev) => ({ ...prev, tableDataLoading: false }));
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading((prev) => ({ ...prev, tableDataLoading: false }));
        }
    };

    const deleteHolidayData = async (data) => {
        try {
            setLoading((prev) => ({ ...prev, dateLoading: true }));
            const response = await axiosInstance.delete(
                API.deleteHoliday(data?.is_festival ? "festival" : "custom", data?.id)
            );
            if (response.status === 200) {
                showAlert(response?.data?.message, "success");
                if (tabValue === Object.keys(HOLIDAYS)[0]) {
                    setOptionalOfficeHolidays((prev) =>
                        prev?.filter((holidayData) => holidayData?.id !== data?.id)
                    );
                    getDataAccordingPagination(optionalOfficeHolidays?.filter((holidayData) => holidayData?.id !== data?.id), optionalFestivalHolidays)
                } else {
                    setOptionalFestivalHolidays((prev) =>
                        prev?.filter((holidayData) => holidayData?.id !== data?.id)
                    );
                    getDataAccordingPagination(optionalOfficeHolidays, optionalFestivalHolidays?.filter((holidayData) => holidayData?.id !== data?.id))
                }
                setIsDeleteData(true)
            } else {
                showAlert(response?.data?.message, "error");
            }
            setLoading((prev) => ({ ...prev, dateLoading: false }));
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading((prev) => ({ ...prev, dateLoading: false }));
        }
        setIsOpen(false);
    };
    const handleCloseDelete = () => {
        setIsOpen(false);
        setDeletableHoliday({});
    };

    const handleDeleteHoliday = (holidayData) => {
        setIsOpen(true);
        setDeletableHoliday(holidayData);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSortClick = (sortTypeString) => {
        setSortType((prev) => (prev === "asc" ? "desc" : "asc"));
        if (tabValue === Object.keys(HOLIDAYS)[0]) {
            if (officeHolidays?.length) {
                let holidays = officeHolidays.slice();
                holidays.sort(function (a, b) {
                    if (sortTypeString === "asc")
                        return new Date(b.date) - new Date(a.date);
                    else return new Date(a.date) - new Date(b.date);
                });
                setOfficeHolidays(holidays);
            }
        } else {
            if (festivalHolidays?.length) {
                let holidays = festivalHolidays.slice();
                holidays.sort(function (a, b) {
                    if (sortTypeString === "asc")
                        return new Date(b.date) - new Date(a.date);
                    else return new Date(a.date) - new Date(b.date);
                });
                setFestivalHolidays(holidays);
            }
        }
    };

    const getTabArray = (isOptional = false) => {
        if (isOptional)
            return tabValue === Object.keys(HOLIDAYS)[1]
                ? optionalFestivalHolidays
                : optionalOfficeHolidays;
        return tabValue === Object.keys(HOLIDAYS)[1]
            ? festivalHolidays
            : officeHolidays;
    };

    return loading.dateLoading ? (
        <CircularLoader />
    ) : (
        <Box display="flex" flexDirection="column" sx={{ width: "100%" }} overflow="auto">
            <Box py={2} px={{ xs: 2, lg: 3, xl: 4 }} borderBottom={"1px solid #E2E4EC"} bgcolor={"white"} position={"sticky"} top={0} zIndex={10} display={'flex'} justifyContent={'space-between'}>
                <Typography variant='h4' color={"dark.800"} fontSize={20} fontWeight={500} lineHeight={"32px"} letterSpacing={"0.15px"} display={"block"}>Holidays</Typography>
                <Grid item>
                    <IconButton
                        aria-label="delete"
                        size="small"
                        className="edit-btn"
                        onClick={() => handleHolidayModalClose(isDeletData)}
                    >
                        <CloseIcon fontSize="medium" />
                    </IconButton>
                </Grid>
            </Box>
            <Stack spacing={3} p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }}>
                <Grid item>
                    <Box sx={{ width: "100%" }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2,bgcolor: 'white', position: "sticky", top: 67, zIndex: 10, }}>
                            <Tabs
                                value={tabValue}
                                onChange={handleTabChange}
                                aria-label="basic tabs example"
                            >
                                {Object.entries(HOLIDAYS)?.map((holiday, index) => (
                                    <Tab label={holiday[1]} value={holiday[0]} key={index} />
                                ))}
                            </Tabs>
                        </Box>
                        <Box
                            bgcolor="secondary"
                            sx={{
                                ".tabs-detail > .MuiBox-root": {
                                    padding: "0px",
                                },
                            }}
                        >
                            {Object.keys(HOLIDAYS)?.map((holidayType, index) => (
                                <CustomTabPanel
                                    value={tabValue}
                                    index={holidayType}
                                    className="tabs-detail"
                                    key={index}
                                >
                                    <Box>
                                        <Typography
                                            mb={1}
                                            color="dark.800"
                                            fontWeight={500}
                                            fontSize={16}
                                            sx={{ lineHeight: "24px", letterSpacing: "0.17px" }}
                                        >
                                            {`${getTabArray(true)?.length || 0} ${HOLIDAYS[holidayType]
                                                }s`}
                                        </Typography>
                                        <Paper
                                            sx={{
                                                border: "1px solid #E0E0E0",
                                                borderRadius: "3px",
                                                boxShadow: 'none',
                                            }}
                                        >
                                            <TableContainer>
                                                <Table>
                                                    <TableHead
                                                        sx={{
                                                            th: {
                                                                color: "dark.800",
                                                                fontWeight: 500,
                                                                fontSize: 14,
                                                                lineHeight: "24px",
                                                                letterSpacing: "0.17px",
                                                                padding: "3px 16px",
                                                                textAlign: "left",
                                                                background: "rgba(245, 248, 250, 1)",
                                                            },
                                                        }}
                                                    >
                                                        <TableRow>
                                                            <TableCell>
                                                                <TableSortLabel
                                                                    active={true}
                                                                    direction={sortType}
                                                                    onClick={() => handleSortClick(sortType)}
                                                                >
                                                                    Date
                                                                </TableSortLabel>
                                                            </TableCell>
                                                            {holidayType === Object.keys(HOLIDAYS)[1] ? (
                                                                <TableCell>Day</TableCell>
                                                            ) : null}
                                                            <TableCell>Name</TableCell>
                                                            <TableCell
                                                                width={90}
                                                                maxwidth={90}
                                                                minwidth={90}
                                                                align="center"
                                                            >
                                                                Remove
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody
                                                        sx={{
                                                            "tr:hover": {
                                                                background: "rgba(247, 247, 247, 1)",
                                                            },
                                                            td: {
                                                                color: "dark.800",
                                                                fontWeight: 400,
                                                                fontSize: 14,
                                                                lineHeight: "20px",
                                                                letterSpacing: "0.17px",
                                                                padding: "7px 16px",
                                                                textAlign: "left",
                                                            },
                                                        }}
                                                    >
                                                        {loading.tableDataLoading ? (
                                                            <CircularLoader
                                                                variant="table"
                                                                rows={rowPerPage}
                                                                cols={
                                                                    holidayType === Object.keys(HOLIDAYS)[1]
                                                                        ? 4
                                                                        : 3
                                                                }
                                                            />
                                                        ) : getTabArray()?.length ? (
                                                            getTabArray()?.map((holidayData, subIndex) => (
                                                                <TableRow key={subIndex}>
                                                                    <TableCell>
                                                                        {getFormattedDate(holidayData?.date)}
                                                                    </TableCell>
                                                                    {holidayType ===
                                                                        Object.keys(HOLIDAYS)[1] ? (
                                                                        <TableCell>
                                                                            {getDayAndMonth(
                                                                                holidayData?.date,
                                                                                holidayData?.name,
                                                                                true,
                                                                            )}
                                                                        </TableCell>
                                                                    ) : null}
                                                                    {holidayType ===
                                                                        Object.keys(HOLIDAYS)[1] ? (
                                                                        <TableCell>{titleCase(holidayData?.name)}</TableCell>
                                                                    ) : (
                                                                        <TableCell>
                                                                            {getDayAndMonth(holidayData?.date, holidayData?.name)}
                                                                        </TableCell>
                                                                    )}
                                                                    <TableCell
                                                                        sx={{ textAlign: "center !important" }}
                                                                    >
                                                                        <IconButton
                                                                            aria-label="delete"
                                                                            size="small"
                                                                            color="secondary"
                                                                            onClick={() =>
                                                                                handleDeleteHoliday(holidayData)
                                                                            }
                                                                            disabled={selectedYear?.isDisabled}
                                                                        >
                                                                            <DeleteIcon fontSize="small" />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        ) : (
                                                            <TableRow
                                                                sx={{
                                                                    "&:hover": {
                                                                        background: "transparent !important",
                                                                    },
                                                                }}
                                                            >
                                                                <TableCell
                                                                    colSpan={
                                                                        holidayType === Object.keys(HOLIDAYS)[1]
                                                                            ? 4
                                                                            : 3
                                                                    }
                                                                    align="center"
                                                                    sx={{ padding: "12px 16px;", border: 'none !important' }}
                                                                >
                                                                    <Typography
                                                                        color={"dark.800"}
                                                                        display={"flex"}
                                                                        alignItems={"center"}
                                                                        justifyContent={"center"}
                                                                        fontSize={14}
                                                                        sx={{
                                                                            opacity: "1",
                                                                            height: "200px",
                                                                            color: "dark.800",
                                                                        }}
                                                                    >
                                                                        No records found.
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                count={getTabArray(true)?.length}
                                                rowsPerPage={rowPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </Paper>
                                        <ConfirmationModal
                                            isOpen={isOpen}
                                            title={'Holiday'}
                                            deletableDataName={getFormattedDate(deletableHoliday?.date)}
                                            dataContentName={'Holiday'}
                                            handleClose={() => handleCloseDelete()}
                                            onConfirm={() => deleteHolidayData(deletableHoliday)}
                                            onCancel={() => handleCloseDelete()}
                                            isButtonLoading={loading?.dateLoading}
                                            type="holiday"
                                        />
                                    </Box>
                                </CustomTabPanel>
                            ))}
                        </Box>
                    </Box>
                </Grid>
            </Stack>
        </Box>
    );
};

export default HolidayList;