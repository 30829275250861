/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  Routes,
  Route,
  Outlet,
  Navigate,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import useAuthentication from "./hook/useAuthentication";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import Dashboard from "./pages/Client/Dashboard";
import URLS from "./routes/urls";
import AddOrganisation from "./pages/Admin/AddOrganisation";
import OrganisationWrapper from "./pages/Admin/OrganisationWrapper";
import OnBoardingStep from "./pages/Client/OnBoardingStep";
import OnBoardingWrapper from "./pages/Client/OnBoardingStep/OnBoardingWrapper";
import EditOrganisation from "./pages/Admin/EditOrganisation";
import ClientWrapper from "./pages/Client/ClientWrapper";
import Users from "./pages/Client/Users";
import Holidays from "./pages/Client/Holidays/Holidays";
import Projects from "./pages/Client/Projects";
import Leaves from "./pages/Client/Request/Leaves";
import AdministratorOrganisation from "./pages/Client/Administrator/Organisation";
import AdministratorHolidays from "./pages/Client/Administrator/Holidays";
// import AdministratorLeaves from "./pages/Client/Administrator/Leaves";
import AdministratorRoles from "./pages/Client/Administrator/Roles";
import Profile from "./pages/Client/Profile";
import AdminProfile from "./pages/Admin/Profile";
import WorkFromHome from "./pages/Client/Request/WorkFromHome";
import Inventory from "./pages/Client/Request/Inventory";
import CreateHoliday from "./pages/Client/Administrator/Holidays/CreateHoliday";
import InCompleteOnBoardingProcess from "./pages/Client/OnBoardingStep/NoBoardingProcess";
import CreateUser from "./pages/Client/Users/CreateUser";
import ViewMyLeaveApplication from "./pages/Client/Request/Leaves/ViewMyLeaveApplication";
import EditMyLeaveApplication from "./pages/Client/Request/Leaves/EditMyLeaveApplication";
import ViewWfhApplication from "./pages/Client/Request/WorkFromHome/ViewWfhApplication";
import EditWfhApplication from "./pages/Client/Request/WorkFromHome/EditWfhApplication";
import OthersWfhApplicationDetails from "./pages/Client/Request/WorkFromHome/OthersWfhApplicationDetails";
import OthersLeaveDetails from "./pages/Client/Request/Leaves/OthersLeaveDetails";
import ApplyLeavePage from "./pages/Client/Request/Leaves/ApplyLeavePage";
import ApplyWFHRequest from "./pages/Client/Request/WorkFromHome/ApplyWFHRequestPage";
import { ON_BOARDING_STATUS, ON_BOARDING_STEPS } from "./constants/default-values";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CAPrivacyNotice from "./pages/CAPrivacyNotice";
import UserDetails from "./pages/Client/Users/UserDetails";
import AdministratorActivity from "./pages/Client/Administrator/Activity";
import CreateActivity from "./pages/Client/Administrator/Activity/CreateActivity";
import UpdateActivity from "./pages/Client/Administrator/Activity/UpdateActivity";
import Reports from "./pages/Client/Reports";
import NotificationsPage from "./pages/NotificationsPage";
import InOutReport from "./pages/Client/Reports/InOutReport";
import ChangePassword from "./pages/Client/ChangePassword";
import AdministratorProjectRoles from "./pages/Client/Administrator/ProjectRoles";
import Repotees from "./pages/Repotees";
import axiosInstance from "./axios";
import API from "./axios/api";
import secureLocalStorage from "react-secure-storage";
import CreateProjects from "./pages/Client/Projects/CreateProject";
import UpdateProject from "./pages/Client/Projects/UpdateProject";
import AdministratorLeaveSettings from "./pages/Client/Administrator/Leaves/AdministratorLeaveSettings";
import PageNotFound from "./pages/PageNotFound";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { setNightModeDetails } from "./redux/slices/nightModeSlice";
import { setUserDetails } from "./redux/slices/userSlice";
import { getCookie, setIsReportee, sortArrayByKey } from "./utils";
import { getAllHolidayData } from "./redux/slices/leaveDataSlice";
import EditOthersLeaveApplication from "./pages/Client/Request/Leaves/EditOthersLeaveApplication";
import EditOthersWFHApplication from "./pages/Client/Request/WorkFromHome/EditOthersWFHApplication";
import { setIsApiReponseFailed, setIsRepoteeData } from "./redux/slices/repoteeDataSlice";
import { setActivityLoaderData, setAllActivityData, setIsActionData } from "./redux/slices/activityActionDataSlice";
import { DateObject } from "react-multi-date-picker";
import AdministratorWorkType from "./pages/Client/Administrator/WorkType";
import LeaveReport from "./pages/Client/Reports/LeaveReport";
import EmailTemplates from "./pages/Client/Campaigns/EmailTemplates";
import CreateTemplate from "./pages/Client/Campaigns/EmailTemplates/CreateTemplate";
import UpdateTemplate from "./pages/Client/Campaigns/EmailTemplates/UpdateTemplate";
import CampaignsContactList from "./pages/Client/Campaigns/CampaignsContactList";
import ProjectTask from "./pages/Client/Projects/ProjectTask";
import EmailTransactions from "./pages/Client/Campaigns/EmailTransactions";
import EditModule from "./pages/Admin/EditModule";
import AddModule from "./pages/Admin/AddModule";
import AdminDashboardModule from "./pages/Admin/AdminDashboardModule";
import ProjectAllDetails from "./pages/Client/Projects/ProjectTaskDetail";

const App = () => {
  const { isAuthenticated, defaultRoute, getCurrentUser, isHaveAccess } = useAuthentication();
  const DefaultPath = defaultRoute();
  const currentUser = getCurrentUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Effect to check session restoration on component mount
  useEffect(() => {
    restoreSessionIfCleared();
  }, []);

  useEffect(() => {
    if (isAuthenticated() && currentUser?.user_type !== "super_admin" && currentUser?.organization?.onboarding_status === ON_BOARDING_STATUS.completed) {
      getUsersRepoteesData();
      getNightModeDetails();
      getAllActivityData();
      getSummaryReport();
      dispatch(getAllHolidayData({
        organization_id: currentUser?.organization_id,
        year_Id: currentUser?.organization?.work_type_settings?.[0]?.org_year_id,
        user_id: currentUser?.id
      }));
      if (secureLocalStorage.getItem('isLastDayTaskRemain') && currentUser?.eod) {
        navigate(URLS.ProjectTask)
      }
    }
  }, []);

  const getUsersRepoteesData = async () => {
    try {
      const response = await axiosInstance.get(API.getRepoteesListByUserId)
      if (response?.status === 200) {
        if (response.data?.userData) {
          secureLocalStorage.setItem("isReportee", response.data?.userData?.length > 0)
        }
        dispatch(setIsRepoteeData(response.data?.userData));
        dispatch(setIsApiReponseFailed(false));
      }
    } catch (error) {
      console.error(error);
      dispatch(setIsApiReponseFailed(true));
    }
  }

  const getNightModeDetails = async () => {
    try {
      const response = await axiosInstance.get(API.getNightMode);
      if (response.status === 200) {
        let data = {
          maintain_session: response?.data?.data?.maintain_session || false,
          id: response?.data?.data?.id,
        }
        dispatch(setNightModeDetails(data));
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getSummaryReport = async () => {
    try {
      let body = {
        userData: {
          id: currentUser?.id
        },
        startDate: new DateObject().toFirstOfMonth(),
        endDate: new DateObject().toDate()
      };
      const response = await axiosInstance.post(API.getUserSummary, body);
      if (response.status === 200) {
        dispatch(setIsActionData(response?.data?.activity || []));
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getAllActivityData = async () => {
    try {
      dispatch(setActivityLoaderData(true))
      const response = await axiosInstance.get(
        API.getAllActivityByWorkType(currentUser?.org_work_type_setting_id)
      );
      if (response.status === 200) {
        if (response?.data?.data?.length > 0) {
          let sortedActivity = sortArrayByKey(response?.data?.data, 'asc', 'title')
          dispatch(setAllActivityData(sortedActivity || []));
          dispatch(setActivityLoaderData(false))
        } else {
          dispatch(setActivityLoaderData(false))
        }
      } else {
        dispatch(setActivityLoaderData(false))
      }
    } catch (error) {
      console.error(error);
      dispatch(setActivityLoaderData(false))
    }
  };

  const PrivateRoute = ({ element }) => {
    let hasAccess = isHaveAccess(window.location);
    const currentUser = isAuthenticated() && getCurrentUser();
    return isAuthenticated() ? (hasAccess ? element : <Navigate to={currentUser?.user_type === "super_admin" ? URLS.AdminDashboard : URLS.Dashboard} />) : <Navigate to={URLS.Login} />;
  };

  const PublicRoute = ({ element }) => {
    return !isAuthenticated() ? element : <Navigate to={defaultRoute()} />;
  };

  const redirectPaths = [URLS.InCompleteOnBoarding, `${URLS.onBoarding}/${ON_BOARDING_STATUS[currentUser?.onboarding_status || currentUser?.organization?.onboarding_status]}`]
  if (redirectPaths.includes(DefaultPath) && currentUser?.organization?.onboarding_status !== "completed") {
    window.history.replaceState({}, "", DefaultPath);
    // window.location.href = DefaultPath;
  }

  // Check user can access onboarding current step or not
  const checkOnboardingAnotherPath = () => {
    let currentOnboardingStep = ON_BOARDING_STATUS[currentUser?.onboarding_status || currentUser?.organization?.onboarding_status];
    const onboardingStepsArray = Object.values(ON_BOARDING_STEPS);
    const currentStepIndex = onboardingStepsArray.indexOf(currentOnboardingStep);
    const allowedSteps = onboardingStepsArray.slice(0, currentStepIndex + 1);
    return !allowedSteps?.filter(step => step !== ON_BOARDING_STEPS.completed)?.some(step => window?.location?.pathname?.split("/")?.length === 3 && window?.location?.pathname?.split("/")?.includes(step))
  }

  if ((currentUser?.organization?.onboarding_status !== "completed" && currentUser?.user_type !== "super_admin" && currentUser?.is_primary) &&
    ((!window?.location?.pathname?.toLowerCase()?.includes(URLS.onBoarding)) ||
      (window?.location?.pathname?.includes(URLS.onBoarding) && checkOnboardingAnotherPath()))) {
    window.location.href = `${URLS.onBoarding}/${ON_BOARDING_STATUS[currentUser?.onboarding_status || currentUser?.organization?.onboarding_status]}`;
  }

  // --- Restore user session from cookies when update the browser

  // Function to fetch user data from the API using the session token
  const fetchUserDataFromApi = async (backupToken) => {
    try {
      const response = await axiosInstance.get(API.getUserDataViaToken);
      if (response.status === 200) {
        const _user = response.data.user;
        dispatch(setUserDetails({
          user: { ..._user, organization: response?.data?.organization },
          token: backupToken,
          organization: response?.data?.organization
        }));
        setIsReportee(_user);

        // Additional checks based on user type and onboarding status
        if (_user?.user_type !== "super_admin" && response?.data?.organization?.onboarding_status === ON_BOARDING_STATUS.completed && window.location.pathname !== URLS.AccessDenied) {
          getUsersRepoteesData();
          getNightModeDetails();
          getAllActivityData();
          getSummaryReport();
        }
        navigate(DefaultPath);
      } else {
        console.warn("Session restoration failed, navigating to login.");
        navigate(URLS.Login);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      navigate(URLS.Login);
    }
  };

  const restoreSessionIfCleared = async () => {
    try {
      const token = secureLocalStorage.getItem('token');
      if (!token) {
        const backupToken = getCookie('sessionBackupToken');
        if (backupToken) {
          await fetchUserDataFromApi(backupToken);
        }
      }
    } catch (error) {
      console.error("Error in session restoration:", error);
      navigate(URLS.Login);
    }
  };

  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        {/* public routes */}
        <Route
          path={URLS.Login}
          element={<PublicRoute element={<Login />} />}
        />
        <Route
          path={URLS.ForgotPassword}
          element={<PublicRoute element={<ForgotPassword />} />}
        />
        <Route
          path={URLS.ResetPassword}
          element={<PublicRoute element={<ResetPassword />} />}
        />
        <Route
          path={URLS.TermsAndConditions}
          element={<PublicRoute element={<TermsAndConditions />} />}
        />
        <Route
          path={URLS.PrivacyPolicy}
          element={<PublicRoute element={<PrivacyPolicy />} />}
        />
        <Route
          path={URLS.CAPrivacyNotice}
          element={<PublicRoute element={<CAPrivacyNotice />} />}
        />

        {/* private routes  - Admin pages*/}
        <Route
          path={URLS.AdminProfile}
          element={
            <PrivateRoute
              element={
                <OrganisationWrapper>
                  <AdminProfile />
                </OrganisationWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.AdminDashboard}
          element={
            <PrivateRoute
              element={
                <OrganisationWrapper>
                  <AdminDashboard />
                </OrganisationWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.AddOrganisation}
          element={
            <PrivateRoute
              element={
                <OrganisationWrapper>
                  <AddOrganisation />
                </OrganisationWrapper>
              }
            />
          }
        />

        <Route
          path={`${URLS.EditOrganisation}/:orgId`}
          element={
            <PrivateRoute
              element={
                <OrganisationWrapper>
                  <EditOrganisation />
                </OrganisationWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.AdminDashboardModule}
          element={
            <PrivateRoute
              element={
                <OrganisationWrapper>
                  <AdminDashboardModule />
                </OrganisationWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.AddModule}
          element={
            <PrivateRoute
              element={
                <OrganisationWrapper>
                  <AddModule />
                </OrganisationWrapper>
              }
            />
          }
        />

        <Route
          path={`${URLS.EditModule}/:moduleId/:isParent`}
          element={
            <PrivateRoute
              element={
                <OrganisationWrapper>
                  <EditModule />
                </OrganisationWrapper>
              }
            />
          }
        />

        <Route
          path={`${URLS.onBoarding}/:step`}
          element={
            <PrivateRoute
              element={
                <OnBoardingWrapper>
                  <OnBoardingStep />
                </OnBoardingWrapper>
              }
            />
          }
        />

        <Route
          path={`${URLS.InCompleteOnBoarding}`}
          element={
            <PrivateRoute
              element={
                <OnBoardingWrapper>
                  <InCompleteOnBoardingProcess />
                </OnBoardingWrapper>
              }
            />
          }
        />

        {/* private routes  - Client pages*/}

        <Route
          path={URLS.AccessDenied}
          element={
            <PrivateRoute
              element={<PageNotFound />}
            />
          }
        />

        <Route
          path={URLS.Profile}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <Profile />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.ChangePassword}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <ChangePassword />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.Dashboard}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <Dashboard />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.Projects}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <Projects />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={`${URLS.ProjectDetails}/:projectId`}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <ProjectAllDetails />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.CreateProject}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <CreateProjects />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={`${URLS.UpdateProject}/:projectId`}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <UpdateProject />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={`${URLS.ProjectTask}`}
          element={
            <PrivateRoute
              element={
                <ClientWrapper isShowSideBar={false}>
                  <ProjectTask />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.Leaves}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <Leaves />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.ApplyLeave}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <ApplyLeavePage />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={`${URLS.OtherLeaveDetails}/:userId/:appId`}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <OthersLeaveDetails />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={`${URLS.OtherWfhApplicationDetails}/:userId/:appId`}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <OthersWfhApplicationDetails />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={`${URLS.ViewMyLeaveApplication}/:userId/:appId`}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <ViewMyLeaveApplication />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={`${URLS.EditMyLeaveApplication}/:userId/:appId`}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <EditMyLeaveApplication />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={`${URLS.EditOthersLeaveApplication}/:userId/:appId`}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <EditOthersLeaveApplication />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={`${URLS.EditOthersWfhApplication}/:userId/:appId`}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <EditOthersWFHApplication />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={`${URLS.ViewMyWfhApplication}/:userId/:appId`}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <ViewWfhApplication />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={`${URLS.EditMyWfhApplication}/:userId/:appId`}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <EditWfhApplication />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.WorkFromHome}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <WorkFromHome />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.ApplyWorkFromHome}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <ApplyWFHRequest />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.Inventory}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <Inventory />
                </ClientWrapper>
              }
            />
          }
        />
        <Route
          path={URLS.Users}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <Users />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.Reports}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <Reports />
                </ClientWrapper>
              }
            />
          }
        />
        <Route
          path={URLS.InOutReport}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <InOutReport />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.LeaveReport}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <LeaveReport />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={`${URLS.UserDetails}/:userId`}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <UserDetails />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.CreateUser}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <CreateUser />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.Holidays}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <Holidays />
                </ClientWrapper>
              }
            />
          }
        />
        <Route
          path={URLS.AdministratorOrganisation}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <AdministratorOrganisation header={URLS.AdministratorOrganisation} title={'Organisation'} />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.AdministratorHolidays}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <AdministratorHolidays header={URLS.AdministratorHolidays} title={'Holidays Settings'} />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.AdministratorHolidaysCreate}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <CreateHoliday header={URLS.AdministratorHolidaysCreate} />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.AdministratorLeaves}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <AdministratorLeaveSettings header={URLS.AdministratorLeaves} title={'Leaves Settings'} />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.AdministratorRoles}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <AdministratorRoles header={URLS.AdministratorRoles} title={'Roles'} />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.AdministratorProjectRoles}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <AdministratorProjectRoles header={URLS.AdministratorProjectRoles} title={'Project Roles'} />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.AdministratorActivity}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <AdministratorActivity header={URLS.AdministratorActivity} title={'Activity'} />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.AdministratorCreateActivity}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <CreateActivity header={URLS.AdministratorCreateActivity} />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={`${URLS.AdministratorUpdateActivity}/:activityId`}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <UpdateActivity header={URLS.AdministratorUpdateActivity} />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.AdministratorWorkType}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <AdministratorWorkType header={URLS.AdministratorWorkType} title={'Years'} />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.AllNotifications}
          element={
            <PrivateRoute
              element={
                <ClientWrapper sx={{ overflow: "hidden" }}>
                  <NotificationsPage header={URLS.AllNotifications} />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.Repotees}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <Repotees />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={`${URLS.UpdateEmailTemplate}/:templateId`}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <UpdateTemplate />
                </ClientWrapper>
              }
            />
          }
        />
        <Route
          path={URLS.CampaignsContactList}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <CampaignsContactList header={URLS.CampaignsContactList} title={'Contacts'} />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.EmailTransactions}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <EmailTransactions header={URLS.EmailTransactions} title={'Transactions'} />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.EmailTemplates}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <EmailTemplates header={URLS.EmailTemplates} title={'Templates'} />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={URLS.CreateEmailTemplate}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <CreateTemplate />
                </ClientWrapper>
              }
            />
          }
        />

        <Route
          path={`${URLS.UpdateEmailTemplate}/:templateId`}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <UpdateTemplate />
                </ClientWrapper>
              }
            />
          }
        />
        <Route
          path={URLS.CampaignsContactList}
          element={
            <PrivateRoute
              element={
                <ClientWrapper>
                  <CampaignsContactList header={URLS.CampaignsContactList} title={'Contacts'} />
                </ClientWrapper>
              }
            />
          }
        />
        {/* Check redirection using auth value */}
        <Route path="/" element={<Navigate to={DefaultPath} />} />
        <Route path="/*" element={
          <Box sx={{ height: '100vh' }}>
            <PageNotFound />
          </Box>
        }
        />
      </Route>
    </Routes>
  );
};

export default App;