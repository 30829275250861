import { GENERAL_USER_PAGES, ON_BOARDING_STATUS, SUPER_ADMIN_PAGE_LIST, USER_TYPES } from "../constants/default-values";
import URLS from "../routes/urls";
import secureLocalStorage from "react-secure-storage";

const useAuthentication = () => {
  const getCurrentUser = () => {
    try {
      const currentUserRaw = secureLocalStorage.getItem("currentUser");
      const organizationRaw = secureLocalStorage.getItem("organization");

      const currentUser = currentUserRaw ? JSON.parse(currentUserRaw) : null;
      const organization = organizationRaw ? JSON.parse(organizationRaw) : null;

      if (currentUser && Object.keys(currentUser).length) {
        if (organization && Object.keys(organization).length) {
          return { ...currentUser, organization: { ...organization } };
        }
        return currentUser;
      }
    } catch (error) {
      console.error("Error retrieving current user:", error);
    }
    return null;
  };

  const isAuthenticated = () => !!getCurrentUser();

  const userRoles = {
    DEVELOPER: 'developer',
    SALES: 'sales',
    HR: 'hr',
    ADMINISTRATOR: 'administrator',
    SUPER_ADMIN: "super_admin",
  };

  function isHaveAccess(location) {
    const url = location?.pathname?.toLowerCase();
    const currentUser = getCurrentUser();
    const userRole = currentUser?.user_role?.toLowerCase();
    const userType = currentUser?.user_type?.toLowerCase();

    if (userType === 'super_admin' && (url?.includes(URLS.onBoarding) || url?.includes(URLS.InCompleteOnBoarding))) {
      return false;
    }
    // if ((userType !== 'admin' && !currentUser?.is_admin) && url?.split("/").includes("projects")) {
    //   return false;
    // }
    if (userRole === "sales") {
      return true;
    }
    else if (userType === userRoles.SUPER_ADMIN) {
      if (SUPER_ADMIN_PAGE_LIST?.includes(url) || url.split(("/").includes("admin"))) {
        return true;
      }
      else {
        return false;
      }
    }
    if (url?.includes(URLS.ProjectTask) && !currentUser?.eod) {
      return false
    }
    // else if (userRole !== userRoles.ADMINISTRATOR && url?.split("/").includes("activity")) {
    //   return false;
    // }
    else {
      if ((userRole === userRoles.HR || userRole === userRoles.ADMINISTRATOR) && !SUPER_ADMIN_PAGE_LIST?.includes(url)) {
        return true;
      }
      let isReportee = secureLocalStorage.getItem("isReportee");
      if (isReportee) {
        GENERAL_USER_PAGES.push(URLS.Repotees);
      }

      let isAccess = GENERAL_USER_PAGES.includes(url) || url?.split("/").includes("request") || url?.split("/").includes("projects");
      return isAccess;
    }
  }

  const defaultRoute = () => {
    const currentUser = getCurrentUser();
    let viewPages = [
      URLS.ViewMyLeaveApplication,
      URLS.ViewMyWfhApplication,
      URLS.OtherLeaveDetails,
      URLS.OtherWfhApplicationDetails
    ];
    let isViewPageInclude = viewPages.some(data => window.location.pathname.toLowerCase()?.includes(data))
    let isIncompleteOnboarding = currentUser ? currentUser?.organization?.onboarding_status !== ON_BOARDING_STATUS.completed : false;
    if (window.location.pathname !== URLS.Login && !isIncompleteOnboarding && window.location.pathname?.length > 2 && isViewPageInclude) {
      secureLocalStorage.setItem("redirectPath", window.location.pathname)
    }
    let path;

    if (!currentUser) {
      return URLS.Login;
    }

    const handleGetPathForAdmin = (userData) => {
      let isIncompleteOnboarding = userData?.organization?.onboarding_status !== ON_BOARDING_STATUS.completed;
      if (isIncompleteOnboarding && userData?.is_primary) {
        const isCurrentOnBoardingPath = window.location.pathname.split("/").includes("on-borading");
        if (!isCurrentOnBoardingPath) {
          return `${URLS.onBoarding}/${ON_BOARDING_STATUS[userData?.organization?.onboarding_status]}`;
        }
      } else if (userData?.organization?.onboarding_status === "completed") {
        let redirectPath = secureLocalStorage.getItem("redirectPath");
        return redirectPath ? redirectPath : URLS.Dashboard;
      } else if (isIncompleteOnboarding && !userData?.is_primary) {
        return URLS.InCompleteOnBoarding;
      }
    };

    switch (currentUser.user_type) {
      case USER_TYPES.super_admin:
        path = URLS.AdminDashboard;
        break;
      case USER_TYPES.admin:
        path = handleGetPathForAdmin(currentUser);
        break;
      case USER_TYPES.manager:
        path = handleGetPathForAdmin(currentUser);
        break;
      case USER_TYPES.general:
        path = handleGetPathForAdmin(currentUser);
        break;
      default:
        path = window.location.pathname;
        break;
    }
    return path;
  };

  return { isAuthenticated, getCurrentUser, defaultRoute, isHaveAccess };
};

export default useAuthentication;