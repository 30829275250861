/* eslint-disable react-hooks/exhaustive-deps */
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Chip, Dialog, DialogContent, Divider, FormControl, FormHelperText, Grid, IconButton, MenuItem, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Input from '../../../../components/Input'
import { clearAllCookies, durationToWord, generateUniqueId, getApplyDateTime, getDayJsToSecond, getInputId, secondToDayJsHours, sortArrayByKey, timestampToTime, titleCase } from '../../../../utils'
import DeleteIcon from "@mui/icons-material/Delete";
import { ClassNames } from '@emotion/react';
import AutocompleteSelect from '../../../../components/AutocompleteSelect';
import { keysWithActualValues, PROJECT_PHASE_STATUS, PROJECT_TASK_PRIORITY, TASK_STATUS_LIST, TASK_TYPE_LIST } from '../../../../constants/default-values';
import moment from 'moment';
import ICONS from '../../../../constants/icons';
import Button from '../../../../components/Button';
import useAuthentication from '../../../../hook/useAuthentication';
import axiosInstance from '../../../../axios';
import API from '../../../../axios/api';
import CustomTimeInput from '../../../../components/CustomTimeInput';
import { makeStyles, styled } from '@mui/styles';
import TasksModal from './taskModal';
import useDebounce from '../../../../hook/useDebounce';
import dayjs from 'dayjs';
import { secondsToTime } from '../../../../utils';
import { toast } from 'react-toastify';
import CircularLoader from '../../../../components/CircularLoader';
import { usePageTitle } from '../../../../hook/useTitle';
import { createDefaultObject, validateLogoutModelData } from '../../../../components/LogoutModal/utils-functions';
import LogoutModal from '../../../../components/LogoutModal';
import { clearStorage } from '../../../../redux/slices/userSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import URLS from '../../../../routes/urls';
import secureLocalStorage from 'react-secure-storage';
import { clearActionData } from '../../../../redux/slices/activityActionDataSlice';
import { useAlert } from '../../../../hook/useAlert';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(0),
    overflowX: 'hidden',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(0),
  },
}));

const initialProjectTaskData = {
  id: null,
  projects: { id: 'nill', title: 'Select', "short_code": "SL", },
  task: "",
  type: "nill",
  phase: "",
  status: "nill",
  duration: dayjs().set("hour", 0).set("minute", 0),
  description: "",
  unique_id: generateUniqueId(),
  isExist: false
};

const initialError = {
  projects: "",
  task: "",
  type: "",
  phase: "",
  status: "",
  duration: "",
  description: "",
  unique_id: generateUniqueId(),
  task_id: null
}

const ProjectTask = () => {
  const classes = useStyles();
  const { getCurrentUser } = useAuthentication();
  const { setPageTitle } = usePageTitle();
  const currentUser = getCurrentUser();
  const showAlert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [projectTaskList, setProjectTaskList] = useState([initialProjectTaskData]);
  const [errors, setErrors] = useState([{
    ...initialError,
    unique_id: initialProjectTaskData?.unique_id,
  }])
  const [logoutErrors, setLogoutErrors] = useState({});
  const [projectList, setProjectList] = useState([]);
  const [isLoading, setIsLoading] = useState({
    pageLoading: true,
    allProjectsLoading: true,
    userProjectLoading: false,
    projectTaskLoading: false,
    formSubmitting: false,
  });
  const [modalDetails, setModalDetails] = useState({
    isOpen: false,
    project_id: null,
    search: "",
    user_id: currentUser?.id,
    projectDetail: {},
    unique_id: null
  })
  const [searchString, setSearchString] = useState("");
  const debouncedSearchTerm = useDebounce(searchString);
  const [pageBeenRendered, setPageBeenRendered] = useState(false);
  const [todayTrackerHours, setTodayTrackerHours] = useState({});
  const [selectedProjectTask, setSelectedProjectTask] = useState([]);
  const [totalWorkingSeconds, setTotalWorkingSeconds] = useState(0);
  const [availableWorkingSeconds, setAvailableWorkingSeconds] = useState(0);
  const [totalActivityHours, setTotalActivityHours] = useState(0);
  const [isDisable, setIsDisable] = useState(false);
  const [workingActivity, setWorkingActivity] = useState([]);
  const [optionalWorkingActivity, setOptionalWorkingActivity] = useState([]);
  const [allProjectPhases, setAllProjectPhases] = useState([]);
  let initialModalValues = {
    isShow: false,
    isButtonLoading: false,
    date: null,
    msg: null,
    time: null,
    email: null,
  }
  const [logoutModelDetails, setLogoutModelDetails] = useState(initialModalValues);
  const [openAccordianList, setOpenAccordianList] = useState([initialProjectTaskData?.unique_id]);
  setPageTitle("Project Task");

  let LastDayLogoutData = !!secureLocalStorage.getItem('lastDayLogutData') ? JSON.parse(secureLocalStorage.getItem('lastDayLogutData')) : [];
  let isLastDayTaskRemaining = !!secureLocalStorage.getItem('isLastDayTaskRemain');

  useEffect(() => {
    if (currentUser?.id) {
      getProjectWithPhasedata(currentUser?.id);
    }
  }, [currentUser?.id]);

  useEffect(() => {
    if (pageBeenRendered) {
      setModalDetails((prev) => ({
        ...prev,
        search: debouncedSearchTerm,
      }));
    }
    setPageBeenRendered(true)
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (modalDetails?.isOpen)
      getTaskOfProject(modalDetails?.search)
  }, [modalDetails?.search, modalDetails?.isOpen]);

  const findErrorByUniqueId = (uniqueId) => {
    return errors?.find((data) => data?.unique_id === uniqueId)
  }

  const getProjectWithPhasedata = async (userId) => {
    try {
      setIsLoading(prev => ({ ...prev, pageLoading: true }))
      let response = await axiosInstance.get(API.getProjectsWithPhaseByUserId(userId));
      let sortedData = sortArrayByKey(response?.data?.data, "desc", "projects?.title")?.map((projectData => projectData?.projects));
      if (sortedData?.length) setProjectList([{ id: 'nill', title: 'Select', "short_code": "SL", }, ...sortedData]);
      else setProjectList([]);

      let projectWithPhaseData = response?.data?.data?.map((projectData) => {
        return projectData?.projects?.ProjectPhases?.filter((data => [PROJECT_PHASE_STATUS["In-Progress"], PROJECT_PHASE_STATUS["Not started"]]?.includes(data?.status)));
      });
      setAllProjectPhases([...projectWithPhaseData?.flat(1)]);
      getTodaysWorkingHours(sortedData);
      setIsLoading(prev => ({ ...prev, pageLoading: false }))
    } catch (error) {
      console.error(error);
      setIsLoading(prev => ({ ...prev, pageLoading: false }))
    }
  }

  const getTodaysWorkingHours = async (allProjectList = projectList, updatedLastDayLogoutData = LastDayLogoutData) => {
    try {
      setIsLoading(prev => ({ ...prev, pageLoading: true }))
      let date = moment(new Date()).format('YYYY-MM-DD');
      if (updatedLastDayLogoutData?.length && updatedLastDayLogoutData?.[0]?.date) {
        date = moment(new Date(LastDayLogoutData?.[0]?.date)).format('YYYY-MM-DD');
      };
      let response = await axiosInstance.get(API.getAllActivityOfEod(currentUser?.id, date));
      if (response?.status === 200) {
        let userTrackingInfo = response?.data?.userTrackingInfo;
        let totalCreatedTaskDuration = 0;
        let remainingWorkingHours = Math.abs((isLastDayTaskRemaining ? (updatedLastDayLogoutData[0]?.duration ?? 0) : (userTrackingInfo?.working_hrs ? userTrackingInfo?.working_hrs : 0)) - (userTrackingInfo?.working_activity_duration ?? 0) ?? 0);

        if (userTrackingInfo?.tasks?.length) {
          let updatedProjectData = userTrackingInfo?.tasks?.map((taskData) => {
            totalCreatedTaskDuration = totalCreatedTaskDuration + taskData?.duration;
            let projectData = allProjectList?.find((data => data?.id === taskData?.Task?.project_id))
            return {
              ...taskData,
              "id": taskData?.Task?.id || (taskData?.task_id ?? null),
              "projects": projectData,
              "task": `${projectData?.short_code}-${taskData?.Task?.task_no} - ${taskData?.Task?.title}`,
              "type": taskData?.Task?.type,
              "phase": taskData?.Task?.project_phase_id,
              "status": taskData?.Task?.status,
              "duration": secondToDayJsHours(taskData?.duration),
              "description": taskData?.description ?? "",
              "unique_id": generateUniqueId(),
              "task_id": `${projectData?.short_code}-${taskData?.Task?.task_no}`,
              "title": taskData?.Task?.title,
              "spent": taskData?.Task?.spent ?? 0,
              "isExist": true,
            }
          })
          let totalEmptyTaskDuration = (remainingWorkingHours ?? 0) - (totalCreatedTaskDuration ?? 0)
          if (totalEmptyTaskDuration > 0) {
            setProjectTaskList(prev => [...updatedProjectData, ...prev?.map((data, index) => {
              if (index === (projectTaskList?.length - 1)) {
                return { ...data, duration: secondToDayJsHours(totalEmptyTaskDuration) }
              }
              return data;
            })])
          } else {
            setProjectTaskList([...updatedProjectData])
          }
        } else {
          if ((remainingWorkingHours ?? 0) > 0) {
            setProjectTaskList(prev => prev?.map((data, index) => {
              if (index === (projectTaskList?.length - 1)) {
                return { ...data, duration: secondToDayJsHours(remainingWorkingHours) }
              }
              return data;
            }))
          }
        }
        setTodayTrackerHours(userTrackingInfo);
        setTotalWorkingSeconds(remainingWorkingHours ?? 0);
        setTotalActivityHours(userTrackingInfo?.working_activity_duration ?? 0);
        setWorkingActivity(userTrackingInfo?.user_activities?.filter(activityData => activityData?.Activity?.types === "working"))
        setOptionalWorkingActivity(userTrackingInfo?.user_activities);
      } else {
        setTodayTrackerHours({});
      }
      setIsLoading(prev => ({ ...prev, pageLoading: false }))
    } catch (error) {
      console.error(error);
      setTodayTrackerHours({});
      setIsLoading(prev => ({ ...prev, pageLoading: false }))
    }
  }

  const getTaskOfProject = async (searchValue) => {
    try {
      setIsLoading((prev) => ({ ...prev, projectTaskLoading: true }));
      let body = {
        "project_id": modalDetails?.project_id,
        "search": searchValue,
        "user_id": currentUser?.id
      }
      let response = await axiosInstance.post(API.getAllTaskOfProject, body);
      if (response?.status === 200) {
        setSelectedProjectTask(response?.data?.task)
      } else {
        setSelectedProjectTask([])
      }
      setIsLoading((prev) => ({ ...prev, projectTaskLoading: false }));
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, projectTaskLoading: false }));
    }
  }

  const getTaskDate = (isBracket = false, isBold = true) => {
    return <span style={{ fontWeight: isBold ? "bold" : "inherit" }}>{isLastDayTaskRemaining ? "" : "- "}{getApplyDateTime(isLastDayTaskRemaining ? LastDayLogoutData?.[0]?.date : new Date(), isBracket ? "DD/MM/YYYY(dddd)" : "DD/MM/YYYY, dddd")}</span>
  }

  const getCurrentTaskDate = () => {
    return `On ${getApplyDateTime(isLastDayTaskRemaining ? LastDayLogoutData?.[0]?.date : new Date(), "DD/MM/YYYY (dddd)")}`
  }

  const getAvailableTaskHours = (todayHoursData) => {
    return <span style={{ fontWeight: "700", fontSize: '25px', color: '#00000 !important' }}> {secondsToTime(LastDayLogoutData?.length ? LastDayLogoutData[0]?.duration : todayHoursData?.working_hrs)} </span>
  }

  const getTotalTaskHours = (todayHoursData) => {
    let totalHours = (LastDayLogoutData?.length ? LastDayLogoutData[0]?.duration : todayHoursData?.working_hrs) - todayHoursData?.working_activity_duration
    return <span style={{ fontWeight: "700", fontSize: '25px' }}> {secondsToTime(totalHours)} </span>
  }

  const getOtherTaskHours = (seconds) => {
    return <span style={{ fontWeight: "600", fontSize: '18px' }}> {secondsToTime(seconds)} </span>
  }

  const handleChangeFunction = (unique_id, event, index) => {
    const { name, value } = event.target;
    let updatedProjectData = projectTaskList?.map((taskDetails, taskIndex) => {
      if (taskDetails?.unique_id === unique_id) {
        if (name === "projects" && value?.id !== "nill") {
          if (value?.id) {
            let currentProjectPhases = getFilteredPhase(value?.id);
            if (currentProjectPhases?.length > 1) {
              let sortArrayByPhaseStartDate = sortArrayByKey(currentProjectPhases, "start_date")
              return {
                ...taskDetails,
                task: value?.id === taskDetails?.projects?.id ? taskDetails?.task : "",
                task_id: value?.id === taskDetails?.projects?.id ? taskDetails?.task_id : null,
                [name]: value,
                'phase': sortArrayByPhaseStartDate[0]?.id,
              };
            } else {
              return {
                ...taskDetails,
                task: value?.id === taskDetails?.projects?.id ? taskDetails?.task : "",
                task_id: value?.id === taskDetails?.projects?.id ? taskDetails?.task_id : null,
                [name]: value,
                'phase': currentProjectPhases[0]?.id
              };
            }
          } else {
            return {
              ...taskDetails,
              [name]: value,
              phase: "",
              task: "",
              task_id: null,
              id: null
            };
          }
        } else {
          return {
            ...taskDetails,
            [name]: value,
            // is_changed: true
          };
        }
      } else {
        return taskDetails;
      }
    });

    setProjectTaskList(updatedProjectData);
    setErrors(prev => prev?.map(data => {
      if (data?.unique_id === unique_id) {
        return {
          ...data,
          [name]: (((name === "task" || name === "status") && value !== "nill") || (name === "projects" && value?.id !== "nill") || (name === "phase" && !!value) || (name === "description" && value?.trim()?.length)) ? "" : data?.name,
          "phase": updatedProjectData?.find((taskData) => taskData?.unique_id === data?.unique_id)?.phase?.toString()?.length ? "" : data?.phase
        }
      }
      return data;
    }))
  }

  const handleBlurFunction = (event, index) => {

  }

  const handleRemoveTask = (taskData, index, uniqueId) => {
    setAvailableWorkingSeconds(prev => prev + getDayJsToSecond(taskData?.duration))
    setProjectTaskList(prev => prev?.filter(data => data?.unique_id !== uniqueId))
    setErrors(prev => prev?.filter(data => data?.unique_id !== uniqueId))
    setOpenAccordianList(prev => prev?.filter(data => data?.unique_id !== uniqueId))
  }

  const handleAddTask = () => {
    let uniqueId = generateUniqueId();
    setProjectTaskList(prev => ([...prev, { ...initialProjectTaskData, unique_id: uniqueId, duration: availableWorkingSeconds > 59 ? secondToDayJsHours(availableWorkingSeconds) : initialProjectTaskData?.duration }]));
    setErrors(prev => ([...prev, { ...initialError, unique_id: uniqueId }]))
    if (availableWorkingSeconds > 59) {
      setAvailableWorkingSeconds(0);
    }
    setOpenAccordianList((prev => [...prev, uniqueId]))
  }

  const handleTimeChange = (value, uniqueId) => {
    setProjectTaskList(prev => prev?.map((taskDetails) => {
      if (taskDetails?.unique_id === uniqueId) {
        return { ...taskDetails, duration: value }
      }
      return taskDetails
    }))
  };

  const handleTimeBlur = (value, uniqueId) => {
    const findHours = projectTaskList?.find((data) => data?.unique_id === uniqueId)?.duration;
    let currentTaskUsedSeconds = getDayJsToSecond(findHours);

    const totalOtherSeconds = projectTaskList?.reduce((total, currentData) => {
      if (currentData?.unique_id !== uniqueId) {
        total.second += getDayJsToSecond(currentData?.duration);
      }
      return total;
    }, { second: 0 });

    let totalUsedSeconds = totalOtherSeconds?.second + currentTaskUsedSeconds;
    let availableSeconds = totalWorkingSeconds - totalUsedSeconds;
    if (availableSeconds < 0) {
      setIsDisable(true)
      toast.error("You can't add task hours more then work hours")
    } else {
      setIsDisable(false)
    }
    setAvailableWorkingSeconds(availableSeconds)
  }

  const handleOpenPreviousTask = (taskDetails) => {
    setModalDetails({
      isOpen: true,
      project_id: taskDetails?.projects?.id,
      search: "",
      user_id: currentUser?.id,
      projectDetail: taskDetails?.projects,
      project_phase_id: taskDetails?.phase,
      unique_id: taskDetails?.unique_id
    })
  }

  const handleTaskModalClose = () => {
    setModalDetails({
      isOpen: false,
      project_id: null,
      search: "",
      user_id: currentUser?.id,
      projectDetail: {},
      unique_id: null
    })
    setSelectedProjectTask([])
  }

  const handleSearch = (value) => {
    setSearchString(value)
  };

  const handleAddNewTask = async () => {
    try {
      if (searchString?.trim()?.length) {
        let taskCreateDate = moment(new Date()).format('YYYY-MM-DD');
        if (LastDayLogoutData?.length && LastDayLogoutData?.[0]?.date) {
          taskCreateDate = moment(new Date(LastDayLogoutData?.[0]?.date)).format('YYYY-MM-DD');
        }
        let body = {
          project_id: modalDetails?.project_id,
          title: searchString?.trim(),
          priority: PROJECT_TASK_PRIORITY["High"],
          project_phase_id: modalDetails?.project_phase_id,
          status: TASK_STATUS_LIST["Working"],
          type: Object.keys(TASK_TYPE_LIST)[0],
          is_eod: true,
          date: taskCreateDate,
          user_id: currentUser?.id,
        }
        let response = await axiosInstance.post(API.createnewTaskOfProject, body);
        if (response?.status === 200) {
          setProjectTaskList(prev => prev?.map((taskDetails) => {
            if (taskDetails?.unique_id === modalDetails?.unique_id) {
              return {
                ...taskDetails,
                ...response?.data?.task,
                task: `${modalDetails?.projectDetail?.short_code?.toUpperCase()}-${response?.data?.task?.task_no} - ${response?.data?.task?.title}`,
                task_id: `${modalDetails?.projectDetail?.short_code?.toUpperCase()}-${response?.data?.task?.task_no}`,
                title: response?.data?.task?.title
              }
            }
            return taskDetails
          }))
          setErrors((prev) => prev?.map((errorData) => {
            if (errorData?.unique_id === modalDetails?.unique_id) {
              return {
                ...errorData,
                task: ""
              }
            }
            return errorData;
          }))
          handleTaskModalClose();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleAddExistTask = (task) => {
    setProjectTaskList(prev => prev?.map((taskDetails) => {
      if (taskDetails?.unique_id === modalDetails?.unique_id) {
        return {
          ...taskDetails,
          ...task,
          id: task?.id,
          spent: task?.spent ?? 0,
          task: `${modalDetails?.projectDetail?.short_code?.toUpperCase()}-${task?.task_no} - ${task?.title}`,
          task_id: `${modalDetails?.projectDetail?.short_code?.toUpperCase()}-${task?.task_no}`,
          title: task?.title,
          phase: task?.ProjectPhase?.id ?? taskDetails?.phase ?? null,
          isNullTaskSelected: (!task?.ProjectPhase?.id && !taskDetails?.isExist),
          description: taskDetails?.description,
        }
      }
      return taskDetails
    }))
    setErrors((prev) => prev?.map((errorData) => {
      if (errorData?.unique_id === modalDetails?.unique_id) {
        return {
          ...errorData,
          task: "",
          type: "",
          status: "",
          phase: ""
        }
      }
      return errorData;
    }))
    handleTaskModalClose();
  }

  const isValidateData = () => {
    let validationMessage = "Required";
    let isValid = true;
    let newErrors = [];

    projectTaskList?.forEach((taskData, index, arrayData) => {
      let error = {};
      error['unique_id'] = taskData?.unique_id;
      if (taskData?.projects?.id === "nill") {
        error["projects"] = validationMessage;
        isValid = false;
      } else {
        error["projects"] = "";
      }

      if (taskData?.task === "") {
        error["task"] = validationMessage;
        isValid = false;
      } else {
        error["task"] = "";
      }

      // if (taskData?.phase === "") {
      //   error["phase"] = validationMessage;
      //   isValid = false;
      // } else {
      //   error["phase"] = "";
      // }

      if (taskData?.type === "nill") {
        error["type"] = validationMessage;
        isValid = false;
      } else {
        error["type"] = "";
      }

      if (taskData?.status === "nill") {
        error["status"] = validationMessage;
        isValid = false;
      } else {
        error["status"] = "";
      }

      if (!taskData?.description?.trim()?.length) {
        error["description"] = validationMessage;
        isValid = false;
      } else {
        error["description"] = "";
      }
      newErrors.push(error)
    })
    setErrors(newErrors);
    return isValid;
  }

  const handleOnApprove = async () => {
    try {
      const response = await axiosInstance.post(API.logout(currentUser?.id));
      if (response.status === 200) {
        let ask_login_details = response.data?.ask_login_details;
        if (ask_login_details?.hasOwnProperty("last_day") || ask_login_details?.hasOwnProperty("checkout") || ask_login_details?.hasOwnProperty("current_day")) {
          const defaultObject = {
            ...createDefaultObject(response.data?.ask_login_details, keysWithActualValues),
            date: response.data?.ask_login_details?.date,
            time: moment(response.data?.ask_login_details?.date).format("hh:mm a"),
          };
          setLogoutModelDetails({
            isShow: true,
            ...defaultObject,
            email: currentUser?.email,
            last_activity: defaultObject?.msg?.toString()?.split("from")[1]?.replace("b", "")?.replace("<>", "") || null
          });
        } else {
          showAlert(response?.data?.message);
          dispatch(clearStorage());
          clearAllCookies();
          dispatch(clearActionData())
          navigate(URLS.Login, {
            replace: true,
          });
        }
      } else {
        showAlert(response?.data?.message);
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleOnSubmit = async () => {
    try {
      setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: true }))
      let payload = {
        email: logoutModelDetails?.email,
        ask_login_details: { ...logoutModelDetails },
      };
      delete payload.ask_login_details.email;
      delete payload.ask_login_details.msg;
      delete payload.ask_login_details.isShow;
      delete payload.ask_login_details.isButtonLoading;
      delete payload.ask_login_details.time;

      let errorsOfLogout = validateLogoutModelData(payload);
      setLogoutErrors(errorsOfLogout?.ask_login_details);
      if (!Object.values(errorsOfLogout)?.filter((value) => !!value)?.length > 0) {
        const response = await axiosInstance.post(API.addOverTime, payload);
        if (response.status === 200) {
          secureLocalStorage.clear();
          clearAllCookies();
          navigate(URLS.Login, {
            replace: true,
          });
          showAlert(response?.data?.message);
          setLogoutModelDetails(prev => ({
            ...prev,
            isShow: false,
            isButtonLoading: false
          }));
          dispatch(clearActionData())
        } else {
          secureLocalStorage.clear();
          clearAllCookies();
          navigate(URLS.Login, {
            replace: true,
          });
          dispatch(clearActionData())
        }
        setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: false }))
      } else {
        setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: false }))
      }
    }
    catch (error) {
      console.error(error)
      setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: false }))
    }
  }

  const setClearLastDayTaskData = () => {
    setProjectTaskList([initialProjectTaskData]);
    setErrors([{
      ...initialError,
      unique_id: initialProjectTaskData?.unique_id,
    }])
    setLogoutErrors({})
    setIsLoading(prev => ({
      pageLoading: false,
      allProjectsLoading: false,
      userProjectLoading: false,
      projectTaskLoading: false

    }))
    setModalDetails({
      isOpen: false,
      project_id: null,
      search: "",
      user_id: currentUser?.id,
      projectDetail: {},
      unique_id: null
    })
    // setSearchString("")
    // setPageBeenRendered(false)
    setTodayTrackerHours({})
    setSelectedProjectTask([]);
    setTotalWorkingSeconds(0);
    setAvailableWorkingSeconds(0);
    setTotalActivityHours(0);
    setIsDisable(false);
    setWorkingActivity([]);
    setOptionalWorkingActivity([]);
    setLogoutModelDetails(initialModalValues)
    setOpenAccordianList([initialProjectTaskData?.unique_id])
  }

  const handleSubmit = async () => {
    try {
      if (isValidateData()) {
        setIsLoading(prev => ({ ...prev, formSubmitting: true }))
        let body = projectTaskList?.filter(data => !data?.isExist)?.map((taskData) => {
          let isChanged = !taskData?.isExist && taskData?.ProjectPhase?.id !== taskData?.phase;
          if (taskData?.isNullTaskSelected && taskData?.phase && taskData?.ProjectPhase?.id !== taskData?.phase) {
            isChanged = true
          }
          return {
            ...taskData,
            "id": taskData?.id ? taskData?.id : null,
            "project_id": taskData?.projects?.id,
            "project_phase_id": taskData?.phase?.toString()?.length ? taskData?.phase : null,
            "type": taskData?.type,
            "status": taskData?.status,
            "priority": taskData?.id ? taskData?.priority : PROJECT_TASK_PRIORITY["Low"],
            "estimation": 0,
            "user_id": currentUser?.id,
            "title": taskData?.title,
            "work_log_description": taskData?.description,
            "duration": getDayJsToSecond(taskData?.duration),
            "spent": taskData?.spent ?? 0,
            "date": (LastDayLogoutData?.length && LastDayLogoutData?.[0]?.date) ? LastDayLogoutData?.[0]?.date : moment(new Date())?.format('YYYY-MM-DD'),
            "is_phase_changed": isChanged,
          }
        })
        let response = await axiosInstance.post(API.createAllTaskOfEOD, {
          date: (LastDayLogoutData?.length && LastDayLogoutData?.[0]?.date) ? LastDayLogoutData?.[0]?.date : moment(new Date())?.format('YYYY-MM-DD'),
          tasks: body,
          unknownSettledTask: (LastDayLogoutData?.length && LastDayLogoutData?.[0]?.date) ? true : false
        });
        if (response?.status === 200) {
          showAlert(response?.data?.message);
          if (!isLastDayTaskRemaining) {
            handleOnApprove();
            setIsLoading(prev => ({ ...prev, formSubmitting: false }))
          } else {
            if (LastDayLogoutData?.length > 1) {
              setIsLoading(prev => ({ ...prev, formSubmitting: false }))
              setClearLastDayTaskData()
              let updatedMissingEodData = [...LastDayLogoutData];
              updatedMissingEodData?.splice(0, 1);
              LastDayLogoutData = updatedMissingEodData;
              secureLocalStorage.setItem('lastDayLogutData', JSON.stringify(updatedMissingEodData));
              getTodaysWorkingHours(projectList, updatedMissingEodData);
            } else {
              secureLocalStorage.removeItem('isLastDayTaskRemain');
              secureLocalStorage.removeItem('lastDayLogutData');
              navigate(URLS.Dashboard)
            }
          }
        }
        setIsLoading(prev => ({ ...prev, formSubmitting: false }))
      }
    } catch (error) {
      setIsLoading(prev => ({ ...prev, formSubmitting: false }))
      console.error(error);
    }
  }

  const getFilteredPhase = (projectId) => {
    return allProjectPhases?.filter((phasedata) => phasedata?.project_id === projectId);
  }

  return (
    isLoading?.pageLoading ? <CircularLoader /> :
      <Box
        p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }}
        sx={{ overflowY: 'auto', minWidth: '100%' }}
      >
        {isLastDayTaskRemaining ? <Box mb={2}>
          <Typography color={"dark.800"} fontSize={{ xs: 14, xl: 16 }} fontWeight={400} lineHeight={"24px"} letterSpacing={'0.15px'}>Please enter {getTaskDate(true)} missed tasks into the system today. Completing this step is mandatory to continue.</Typography>
        </Box> : null}
        <Box mb={2} display={"flex"} justifyContent={"space-between"} gap={1} flexWrap={"wrap"}>
          <Box display={"flex"} alignItems={"center"} gap={1}>
            <Typography color={"dark.800"} fontSize={{ xs: 19, xl: 22 }} fontWeight={700} lineHeight={"33px"}>{!isLastDayTaskRemaining ? "Today’s Tasks" : ""} {getTaskDate()}</Typography>
            {isLastDayTaskRemaining ? <Chip
              label="Last Day’s Tasks"
              color="error"
              size="small"
              variant="filled"
            /> : null}
          </Box>
          <Box bgcolor={"greentc.light"} px={2} py={"14px"} display={"flex"} alignItems={"center"} gap={1.5} borderRadius={1}>
            <TimerOutlinedIcon sx={{ fontSize: 22, color: "#2E7D32" }} />
            <Typography color={"#1E4620"} fontSize={{ xs: 14, xl: 16 }} fontWeight={"500"} lineHeight={"24px"} letterSpacing={"0.15px"}>{!isLastDayTaskRemaining ? 'Today' : ''}<b>{isLastDayTaskRemaining ? getCurrentTaskDate() : ''}</b>, you have tracked <b>{secondsToTime(LastDayLogoutData?.length ? LastDayLogoutData[0]?.duration : todayTrackerHours?.working_hrs)} hours</b></Typography>
          </Box>
        </Box>
        <Box
          display={"flex"}
          width={"100%"}
          pb={2}
        >
          <Stack spacing={2} sx={{ minWidth: '100%' }}>
            {workingActivity?.length ? optionalWorkingActivity?.map((workinActivitydata, index) => {
              if (workinActivitydata?.Activity?.types !== "working") {
                return null;
              }
              const filteredIndex = workingActivity.findIndex((filteredData) => filteredData?.id === workinActivitydata?.id);

              return (
                <Box display={'flex'} key={index} sx={{ minWidth: '100%' }}>
                  <Box width={50} minWidth={50} maxWidth={50}>
                    <Typography
                      textAlign={"right"}
                      fontSize={14}
                      pt={2}
                      width={30}
                      minWidth={30}
                    >
                      {filteredIndex + 1}.
                    </Typography>
                  </Box>
                  <Card key={index} sx={{ boxShadow: "none", display: "flex", width: '100%' }}>
                    <Card
                      sx={{
                        padding: "16px",
                        bgcolor: "offWhite",
                        boxShadow: "none",
                        flex: "1",
                        overflow: 'visible',
                        width: '100%'
                      }}
                    >
                      <Typography color={'dark.800'} fontSize={14} fontWeight={500} lineHeight={'24px'} letterSpacing={'0.15px'}>
                        Activity of <b>{titleCase(workinActivitydata?.msg)} {workinActivitydata?.activity}</b> tracked for <b>{durationToWord(moment.utc(workinActivitydata?.duration * 1000).format("HH:mm:ss"))}</b> {durationToWord(moment.utc(workinActivitydata?.duration * 1000).format("HH:mm:ss"))?.includes('Seconds') ? '' : 'hours'} ({timestampToTime(workinActivitydata?.createdAt)} - {timestampToTime(optionalWorkingActivity[index + 1]?.createdAt)})
                      </Typography>
                    </Card>
                  </Card>
                </Box>
              )
            }
            ) : null}
            {projectTaskList?.map((taskData, index, array) =>
            (
              <Box display={'flex'} key={index} sx={{ minWidth: '100%' }}>
                <Box width={50} minWidth={50} maxWidth={50} pt={1}>
                  <Typography
                    textAlign={"right"}
                    fontSize={14}
                    width={30}
                    minWidth={30}
                  >
                    {isNaN(workingActivity?.length + index + 1) ? 1 : workingActivity?.length + index + 1}.
                  </Typography>
                </Box>
                <Accordion
                  key={index}
                  expanded={openAccordianList?.includes(taskData?.unique_id)}
                  sx={{
                    width: "100%",
                    background: "#F8F8F8",
                    padding: "24px 16px 24px 24px",
                    // border: "1px solid rgba(0, 0, 0, 0.08)",
                    boxShadow: "none",
                    borderRadius: "5px",
                    margin: "0px !important",
                    "&:first-of-type": {
                      margin: "0px !important",
                    },
                    "&:before": {
                      display: "none",
                    },
                    // ".MuiAccordionSummary-root": {
                    //   minHeight: "42px",
                    //   flexDirection: "row",
                    // },
                    ".MuiAccordionSummary-root": {
                      // minHeight: "42px !important",
                      padding: "0px !important"
                    },
                    ".MuiAccordionSummary-content": {
                      // marginBottom: "13px",
                      // marginLeft: "8px",
                      margin: "0px"
                    },
                    ".MuiAccordionSummary-content.Mui-expanded": {
                      // marginLeft: '8px',
                      // marginBottom: "13px",
                      // marginTop: "12px",
                      margin: "0px",
                    },
                    ".MuiAccordionDetails-root": {
                      paddingTop: "0px",
                    },
                    ".MuiAccordion-region > div": {
                      padding: '0px 48px 0px 0px'
                    },
                    '& .MuiAccordionSummary-expandIconWrapper div i': {
                      transform: 'rotate(0deg) !important',
                      transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    },
                    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                      transform: 'rotate(0deg) !important',
                    },
                    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded div i': {
                      transform: 'rotate(180deg) !important',
                      transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
                    },
                    '.Mui-focusVisible': {
                      background: "transparent !important",
                    }
                  }}
                  elevation={1}
                >
                  <AccordionSummary
                    aria-controls="panel2-content"
                    id="panel2-header"
                    sx={{
                      bgcolor: "offWhite",
                      '.Mui-expanded .accordianIcon': {
                        transform: 'rotate(180deg) !important',
                      }
                    }}
                  >
                    {/* <Card key={index} sx={{ boxShadow: "none", display: "flex", overflow: "visible",background: "transparent",width: "100%" }}> */}
                    <Box width={"100%"} display={"flex"} >
                      <Box pb={3} display={"flex"} width={"100%"} flex={1} mr={1} alignItems={"flex-start"}>
                        <Grid container
                          bgcolor={"offWhite"}
                          boxShadow={"none"}
                          flex={1}
                          overflow={"visible"}
                          width={"100%"}
                          rowGap={"24px"}
                        >
                          {/* Project */}
                          <Grid
                            item
                            pr={3}
                            width={200}
                            maxWidth={200}
                            minWidth={200}
                            sx={{
                              '.MuiAutocomplete-popper': {
                                minWidth: '300px'
                              }
                            }}
                          >
                            <FormControl
                              className={classes.margin}
                              variant="standard"
                              sx={{ margin: "0px !important", width: "100%" }}
                            >
                              <AutocompleteSelect
                                label="Project"
                                name="projects"
                                id={getInputId("projects")}
                                onBlur={(e) => handleBlurFunction(e, index)}
                                onChange={(event) =>
                                  handleChangeFunction(
                                    taskData?.unique_id,
                                    event,
                                    index
                                  )
                                }
                                options={projectList}
                                disabledOptions={projectList?.filter((data) => data?.id === "nill")}
                                isOptionEqualToValue={(option, value) => projectList?.find(user => user.id === value?.id) || {}}
                                value={projectList?.find(project => project?.id === taskData?.projects?.id) || { id: 'nill', title: 'Select', "short_code": "SL", }}
                                isProject={true}
                                disableClearable={taskData?.projects?.id === 'nill' || !taskData?.projects?.id}
                                disabled={!!taskData?.isExist}
                              />
                              {!!findErrorByUniqueId(taskData?.unique_id)?.projects?.length ? (
                                <FormHelperText sx={{ color: "red.main" }}>
                                  {errors?.[index]?.projects}
                                </FormHelperText>
                              ) : null}
                            </FormControl>
                          </Grid>

                          <Grid Item display={"flex"}
                            width={"32%"}
                            minWidth={300}
                            flex={1}
                          >
                            {/* Task */}
                            <Grid
                              item
                              pr={3}
                              flex={1}
                            >
                              <FormControl
                                className={ClassNames.margin}
                                variant="standard"
                                sx={{ margin: "0px !important", width: "100%" }}
                              >
                                <Input
                                  id={getInputId("task", index)}
                                  label="Task"
                                  variant="standard"
                                  placeholder="Type and search tasks"
                                  type="text"
                                  name={"task"}
                                  fullWidth
                                  required={false}
                                  onChange={() => { }}
                                  onBlur={() => { }}
                                  value={taskData?.task}
                                  error={(!!findErrorByUniqueId(taskData?.unique_id)?.task)}
                                  helperText={findErrorByUniqueId(taskData?.unique_id)?.task}
                                  onClick={() => (taskData?.projects?.id === 'nill' || !taskData?.projects?.id) ? {} : handleOpenPreviousTask(taskData)}
                                  disabled={taskData?.projects?.id === 'nill' || !taskData?.projects?.id || !!taskData?.isExist}
                                />
                              </FormControl>
                            </Grid>

                            {/* Phase */}
                            {(taskData?.projects?.id !== "nill" && taskData?.projects?.id) ?
                              <Grid
                                item
                                pr={3}
                                width={130}
                                maxWidth={130}
                                minWidth={130}
                              >
                                <Input
                                  id={getInputId("phase", index)}
                                  name={"phase"}
                                  select
                                  type="select"
                                  label="Phase"
                                  variant="standard"
                                  sx={{ width: "100%" }}
                                  onChange={(event) =>
                                    handleChangeFunction(
                                      taskData?.unique_id,
                                      event,
                                      index
                                    )
                                  }
                                  onBlur={(e) => handleBlurFunction(e, index)}
                                  value={taskData?.phase || ""}
                                  // error={!!findErrorByUniqueId(taskData?.unique_id)?.phase?.length}
                                  // helperText={
                                  //   findErrorByUniqueId(taskData?.unique_id)?.phase
                                  // }
                                  required={false}
                                  disabled={!!taskData?.isExist}
                                >
                                  {getFilteredPhase(taskData?.projects?.id)?.length < 1 ? <MenuItem value="nill" disabled>
                                    No Options
                                  </MenuItem> : null}
                                  {(getFilteredPhase(taskData?.projects?.id)?.length > 0 && (!taskData?.phase?.toString()?.length)) ? <MenuItem value="nill" disabled>
                                    Select
                                  </MenuItem> : null}
                                  {
                                    getFilteredPhase(taskData?.projects?.id)?.map((option, index) => (
                                      <MenuItem key={index} value={option?.id}>
                                        {titleCase(option?.title)}
                                      </MenuItem>
                                    ))
                                  }
                                </Input>
                              </Grid> : null}
                          </Grid>

                          <Grid Item display={"flex"} flex={{ xs: 1, xl: "inherit" }}>
                            {/* Type */}
                            <Grid
                              pr={3}
                              flex={1}
                              minWidth={174}
                            >
                              <Input
                                id={getInputId("type", index)}
                                name={"type"}
                                select
                                type="select"
                                label="Type"
                                defaultValue={"nill"}
                                variant="standard"
                                sx={{ width: "100%" }}
                                onChange={(event) =>
                                  handleChangeFunction(
                                    taskData?.unique_id,
                                    event,
                                    index
                                  )
                                }
                                onBlur={(e) => handleBlurFunction(e, index)}
                                value={taskData?.type || "nill"}
                                error={!!findErrorByUniqueId(taskData?.unique_id)?.type?.length}
                                helperText={
                                  findErrorByUniqueId(taskData?.unique_id)?.type
                                }
                                required={false}
                                disabled={!!taskData?.isExist}
                              >
                                {taskData?.type === "nill" ? <MenuItem value="nill" disabled>
                                  Select
                                </MenuItem> : null}
                                {Object.entries(TASK_TYPE_LIST)?.map((option, index) => (
                                  <MenuItem key={index} value={option[0]}>
                                    {option[1]}
                                  </MenuItem>
                                ))}
                              </Input>
                            </Grid>

                            {/* Status */}
                            <Grid
                              pr={3}
                              flex={1}
                              minWidth={174}
                            >
                              <Input
                                id={getInputId("status", index)}
                                name={"status"}
                                select
                                type="select"
                                label="Status"
                                defaultValue={"nill"}
                                variant="standard"
                                sx={{ width: "100%" }}
                                onChange={(event) =>
                                  handleChangeFunction(
                                    taskData?.unique_id,
                                    event,
                                    index
                                  )
                                }
                                onBlur={(e) => handleBlurFunction(e, index)}
                                value={taskData?.status || "nill"}
                                error={!!findErrorByUniqueId(taskData?.unique_id)?.status?.length}
                                helperText={
                                  findErrorByUniqueId(taskData?.unique_id)?.status
                                }
                                required={false}
                                disabled={!!taskData?.isExist}
                              >
                                {taskData?.status === "nill" ? <MenuItem value="nill" disabled>
                                  Select
                                </MenuItem> : null}
                                {Object.entries(TASK_STATUS_LIST)?.map((option, index) => (
                                  <MenuItem key={index} value={option[0]}>
                                    {option[1]}
                                  </MenuItem>
                                ))}
                              </Input>
                            </Grid>

                            {/* Hours */}
                            <Grid
                              pr={3}
                              flex={1}
                              minWidth={88}
                              maxWidth={88}
                              sx={{
                                ".MuiInputBase-formControl": {
                                  padding: '0px',
                                  marginTop: "13px",
                                },
                                "input": {
                                  padding: '6px 0px',
                                },
                                ".MuiInputLabel-formControl": {
                                  transform: 'translate(0px, -3px) scale(0.75)',
                                },
                                "fieldset": {
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                  borderRadius: '0px'
                                }
                              }}
                            >
                              <CustomTimeInput
                                label="Hours"
                                time={taskData?.duration}
                                onTimeChange={(value) => handleTimeChange(value, taskData?.unique_id)}
                                handleBlur={(value) => handleTimeBlur(value, taskData?.unique_id)}
                                disabled={!!taskData?.isExist}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Box display={"flex"} justifyContent={"space-between"} mt={"3px"}>
                          <Box
                            display={'flex'}
                            alignItems={"center"}
                            p={1}
                            sx={{ 'svg *': { fill: "#047FE0" } }}
                            onClick={() => setOpenAccordianList((prev) => prev?.includes(taskData?.unique_id) ? prev?.filter(list => list !== taskData?.unique_id) : [...prev, taskData?.unique_id])}
                          >
                            <Typography className='worklog' color={'primary.main'} fontSize={14} fontWeight={500} lineHeight={'24px'} letterSpacing={'0.4px'}>Worklog</Typography>
                            <i
                              style={{
                                height: "20px",
                                width: "20px",
                                color: "#047FE0",
                                transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                              }}
                              className='accordianIcon'
                            >
                              {ICONS.ChevronLineSmall}
                            </i>
                          </Box>
                        </Box>
                      </Box>
                      {/* Delete */}
                      <Box width={40} minWidth={40} maxWidth={40} mt={0.5}>
                        {!taskData?.isExist ?
                          <Box width={40} minWidth={40} maxWidth={40}>
                            {index !== 0 ? (
                              <IconButton
                                size="small"
                                sx={{ width: "40px", height: "40px" }}
                                onClick={() =>
                                  handleRemoveTask(taskData, index, taskData?.unique_id)
                                }
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            ) : null}
                          </Box> : null}
                      </Box>
                    </Box>
                    {/* </Card> */}
                  </AccordionSummary>
                  <AccordionDetails sx={{ bgcolor: "offWhite" }}>
                    <Grid item width={"100%"} maxWidth={"100%"}>
                      <Input
                        id={getInputId("description", 0)}
                        name="description"
                        label={`Worklog ${taskData?.task_id ? `for ${taskData?.task_id}` : ''}`}
                        variant="standard"
                        placeholder="Add log to explain your efforts/approach for this task. Use dash sign(-) for step-by-step approach."
                        type="text"
                        fullWidth
                        required={false}
                        onChange={(e) => handleChangeFunction(taskData?.unique_id, e, index, "description")}
                        // onBlur={handleBlur}
                        multiline={false}
                        value={taskData?.description}
                        error={!!findErrorByUniqueId(taskData?.unique_id)?.description?.length}
                        helperText={
                          findErrorByUniqueId(taskData?.unique_id)?.description
                        }
                        InputProps={{
                          id: getInputId("description", 0),
                          name: "description",
                        }}
                        InputLabelProps={{
                          htmlFor: getInputId("description", 0),
                        }}
                      />
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )
            )}
            {availableWorkingSeconds > 59 ? <Box sx={{ paddingLeft: '50px', marginBottom: '20px' }}>
              <Button
                color='secondary'
                onClick={() => handleAddTask()}
                sx={{ textTransform: 'none', boxShadow: 'none !important' }}
              >
                Add Task
              </Button>
            </Box> : null}
          </Stack>
        </Box>
        <Divider />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', alignContent: 'center', marginTop: '20px' }}>
          <Box display={"flex"} pr={"6px"}>
            <Typography display={"flex"} alignItems={"center"} color={"secondary.main"} fontSize={{ xs: 16, xl: 18 }} fontWeight={500} lineHeight={"27px"} letterSpacing={"0.15px"} mr={{ xs: 1.5, xl: 2 }} sx={{ "span": { marginLeft: '4px' }, }}>Availability {isLastDayTaskRemaining ? "for" : ""} {isLastDayTaskRemaining ? getTaskDate(false, false) : 'today'}</Typography>
            <Typography color={"dark.800"} fontSize={{ xs: 22, xl: 25 }} fontWeight={700} lineHeight={"35px"} letterSpacing={"0.15px"} mr={0.4}>
              {getAvailableTaskHours(todayTrackerHours)}
            </Typography>
            <Typography color={"dark.800"} fontSize={{ xs: 13, xl: 14 }} fontWeight={500} lineHeight={"21px"} letterSpacing={"0.15px"} sx={{ opacity: '0.7' }} pt={1.5}>hrs</Typography>
          </Box>
          <Typography color={"dark.800"} fontSize={{ xs: 16, xl: 18 }} fontWeight={600} lineHeight={"27px"} letterSpacing={"0.15px"} px={{ xs: 1.5, xl: 2 }}>=</Typography>
          <Box py={"5px"} px={1} bgcolor={"#EEEEEE"} borderRadius={1} display={"inline-flex"} alignItems={"center"} gap={2} mr={1}>
            <Typography color={"secondary.main"} fontSize={{ xs: 16, xl: 18 }} fontWeight={500} lineHeight={"27px"} letterSpacing={"0.15px"}>Total tasks hours </Typography>
            <Typography color={"dark.800"} fontSize={{ xs: 22, xl: 25 }} fontWeight={700} lineHeight={"37px"} letterSpacing={"0.15px"}>{getTotalTaskHours(todayTrackerHours)}</Typography>
          </Box>
          <Box display={"inline-flex"} alignItems={"center"}>
            <Typography color={"dark.main"} fontSize={{ xs: 16, xl: 18 }} fontWeight={600} lineHeight={"27px"} letterSpacing={"0.15px"} mr={1}>+</Typography>
            <Typography color={"dark.main"} fontSize={{ xs: 16, xl: 18 }} fontWeight={600} lineHeight={"27px"} letterSpacing={"0.15px"} mr={0.5}>{" "}{getOtherTaskHours(totalActivityHours)}</Typography>
            <Typography color={"dark.800"} fontSize={{ xs: 13, xl: 14 }} fontWeight={500} lineHeight={"21px"} letterSpacing={"0.15px"} sx={{ opacity: '0.7' }} pt={"5px"}>other hrs</Typography>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexBasis="100%"
          gap={1}
          marginTop={'20px'}>
          <Button
            variant="contained"
            size="large"
            // type="submit"
            sx={{
              boxShadow: "0",
              fontSize: "15px",
              textTransform: "none",
              color: "white",
              "&:hover": { boxShadow: "0" },
            }}
            disabled={isDisable || availableWorkingSeconds > 59}
            isButtonLoading={isLoading.formSubmitting}
            onClick={handleSubmit}
          >
            {isLastDayTaskRemaining ? "Submit" : 'Submit & Logout'}
          </Button>
          {!isLastDayTaskRemaining ? <Button
            variant="text"
            color="secondary"
            size="large"
            sx={{
              boxShadow: "0",
              fontSize: "15px",
              textTransform: "none",
              "&:hover": { boxShadow: "0" },
            }}
            disabled={isLoading.formSubmitting}
            onClick={() => navigate(URLS.Dashboard)}
          >
            Cancel
          </Button> : null}
        </Box>

        {/* Task popup */}
        <BootstrapDialog
          open={modalDetails?.isOpen}
          onClose={() => handleTaskModalClose()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            alignItems: 'flex-start',
            '.MuiDialog-paper': {
              maxWidth: '100%',
              width: '900px',
              ' > div': {
                overflow: 'hidden',
                display: 'flex',
                '> div': {
                  display: 'flex',
                }
              },
            },
            '.MuiDialog-container': { alignItems: 'flex-start' }
          }}
        >
          <DialogContent sx={{ width: '100%' }}>
            <Box sx={{ width: '100%' }}>
              <TasksModal modalDetails={modalDetails} handleTaskModalClose={handleTaskModalClose} currentUser={currentUser}
                searchString={searchString} handleSearch={handleSearch} selectedProjectTask={selectedProjectTask}
                handleAddNewTask={handleAddNewTask} isLoading={isLoading} handleAddExistTask={(task) => handleAddExistTask(task)}
                allProjectPhases={allProjectPhases} LastDayLogoutData={LastDayLogoutData} isLastDayTaskRemaining={isLastDayTaskRemaining}
                getTaskDate={getTaskDate}
              />
            </Box>
          </DialogContent>
        </BootstrapDialog>

        <LogoutModal
          actionType={"ask_login_details"}
          onApprove={handleOnSubmit}
          isShow={logoutModelDetails.isShow}
          isButtonLoading={logoutModelDetails.isButtonLoading}
          title={`Logout time for ${moment(logoutModelDetails?.date).format("DD/MM/YYYY (dddd)")}`}
          isShowCancelButton={false}
          errors={logoutErrors}
          setErrors={setLogoutErrors}
          setLogoutModelDetails={setLogoutModelDetails}
          logoutModelDetails={logoutModelDetails}
        />
      </Box>
  )
}

export default ProjectTask